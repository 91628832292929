@import url('../../../css/VARIABLES.css');

.form {
    display: grid;
    grid-gap: 0 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.modalFooter > button {
    margin-left: 8px;
}

.uploadProgress {
    border: 1px solid var(--Color__Neutral3);
    padding: 12px 12px 12px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    width: 100%
}

.staticDropZone {
    pointer-events: none;
    opacity: 0.8;
}

.file {
    width: 27px;
    height: 48px;
    fill: var(--IconColor);
    margin: 0 8px 0 0;
}

.fileName {
    margin: auto 0;
    padding-right: 24px;
}

.progressWrapper {
    margin: 0 8px;
    width: 100%;
}

.progress {
    width: 100%;
    height: .45rem;
    background-color: #D8D8D8;
}

.progressBar, .progress-bar {
    background-color: var(--IconColor);
    width: 25%;
    border-radius: .25rem;
}

.progressBarWrapper {
    margin-left: .50rem;
    width: 75%;
}

.uploadProgressWrapper {
    display: flex;
    align-items: center;
}

.closeButton {
    background-color: transparent;
    border: none;
    background: url('../../../assets/images/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    margin: 0 0 0 8px;
    align-self: center;
}

.uploadPercentage {
    font-size: 12px;
    color: #8AA1B9;
}

    .uploadPercentage span {
        color: var(--PrimaryBrandColor);
    }

    @media (max-width: 767px) {
        .submitBtn {
            width: 102px;
        }
    
        .fileSize {
            margin-top: 0;
            margin-bottom: 1rem;
        }
    
        .dzMessage {
            margin: 1rem 0 0 0;
        }
    
        .mobileUpload {
            border: 1px solid var(--Color__Neutral3);
            padding: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 24px;
        }
    
        .dropzone {
            display: none;
        }
    
        .badgeAlert {
            margin-left: 0rem;
        }
    
        .uploadProgress {
            max-width: 100%;
        }
    }

.deleteCardHeader h4 {
    margin-bottom: 0px;
}

.deleteCardHeader {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Color__Neutral2);
    justify-content: space-between;
}
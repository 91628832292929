@import url('../../css/VARIABLES.css');

.getStartedContentContainer {
    background-color: #FFFFFF;
    padding-top: 70px;
    padding-bottom: 70px;
}

.colorBar {
    width: 78px;
    margin-bottom: 8px;
    border: 3px solid var(--SecondaryBrandColor);
    border-radius: 8px;
}

.getStartedHeader {
    font-size: 38px;
}

.getStartedContainer {
    padding: 0px 165px 16px;
}

.getStartedContainer p {
    font-size: var(--H5__Size);
    line-height: 30px;
    margin-bottom: 16px;
}

.getStartedIconContainer {
    padding: 64px 48px;
    border: 1px solid var(--Color__Neutral3);
}

.iconICP {
    margin-bottom: 30px;
}

.iconICP svg {
    height: 70px;
    width: 60px;
}

.paragraphICP {
    font-size: var(--H6__Size);
}

.enrollToday {
    color: #FFFFFF;
    background-color: var(--PrimaryBrandColor);
}

.enrollToday h3 {
    color: #FFFFFF;
}

.headingUnderline {
    color: var(--Heading__Color);
    text-decoration: underline;
}

.textUnderline {
    color: #FFFFFF;
    text-decoration: underline !important;
}

@media screen and (max-width:767.98px) {
    .getStartedContentContainer {
        padding: 50px 16px;
    }

    .getStartedHeader {
        font-size: var(--H2__Size);
        margin-left: 16px;
        margin-right: 16px;
    }

    .getStartedContainer {
        padding: 0;
    }

    .getStartedContainer p {
        margin-bottom: 24px;
    }

    .getStartedIconContainer {
        padding: 24px;
    }

    .iconICP {
        display: none;
    }
}

@media (min-width:768px) and (max-width:991.98px) {
    .getStartedContentContainer {
        padding-left: 4px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .getStartedHeader {
        font-size: 32px;
    }

    .getStartedContainer {
        padding: 0;
    }

    .getStartedIconContainer {
        padding: 24px;
        display: flex;
    }

    .iconICP {
        margin-bottom: 0;
        margin-right: 12px;
    }
}

@media (min-width:992px) and (max-width:1199.98px) {
    .getStartedContentContainer {
        margin-left: -8px;
    }
}
@import url('../../../css/VARIABLES.css');

.calendar-input {
    display: flex;
}

.dateInput {
    /* max-width: 165px; */
    border-right: 0 !important;
    border-radius: 3px 0 0 3px !important;
}

.datePicker {
    min-width: 28px;
    border: 1px solid var(--Input__Border);
    border-left: 0;
    padding: 6px;
    height: 35px;
    border-radius: 0 3px 3px 0 !important;
    fill: var(--IconColor)
    /* border-top-right-radius: 3px; */
    /* border-bottom-right-radius: 3px; */
}
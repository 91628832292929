@import url('../../css/VARIABLES.css');

@media screen and (max-width: 767px) {
    .threeYrPlan {
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .threeYrPlan {
        margin-bottom: 24px !important;
    }
}

/*
* MOVED TO GLOBAL STYLES: RADIOSANDCHECKS.CSS
*/
/* .squareRadioBtn input[type="radio"]:checked+label,
.squareRadioBtn label.selected,
.squareRadioBtn input[type="radio"]+label:hover,
.squareRadioBtn input[type="radio"]+label:focus,
.squareRadioBtn input[type="radio"]+label:active {
    color: #fff !important;
    background-color: #1352DE !important;
    border-color: #1352DE !important;
}

.squareRadioBtn input[type="radio"]+label {
    padding: 5px 12px 5px 12px;
    border: 1px solid var(--Color__Neutral3);
    background-color: #fff;
    color: #001871;
    text-align: center;
}

.squareRadioBtn input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
} */
.threeYrPlanHD {
    background-color: var(--Color__Accent1) !important;
}

.threeYrPlan {
    background-color: var(--AdvantageAccentColor);
    color: var(--Advantage__TextColor);
    font-weight: var(--Heading__FontWeight);
    border-radius: 6px;
    width: 214px;
    font-size: 16px;
    padding: 0.2rem 0.5rem;
    text-wrap: nowrap;
}

.threeYrPlan p {
    margin-bottom: 0rem;
}

.threeYrPlan p:first-of-type {
    font-size: 11px;
    width: 37px;
}
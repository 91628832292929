@import url('./VARIABLES.css');
@import url('./buttons.css');
@import url('./typography.css');
@import url('./inputs.css');
@import url('./dropdown.css');
@import url('./radiosAndChecks.css');
@import url('./tables.css');


/* =============================================================================
  Global
============================================================================= */

:root {
    --info: #1352de;
    --tooltipGray:#dee9f6;
}

body {
    margin: 0;
    font-family: var(--TextFont);
    font-size: var(--FontSize);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: var(--LetterSpacing);
    color: var(--PrimaryTextColor);
    background: #f0f2f5;
    min-width: 320px;
    overflow-x: hidden;
}

p {
    color: var(--PrimaryTextColor)
}

.contentWrapper {
    padding-top: 32px;
    padding-bottom: 50px;
}


/* ===============================================
MAKE FOOTER STICKY
=============================================== */

#root {
    /* border: 5px solid salmon; */
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
}

#root > main {
    flex: 1 0 auto;
    /* padding-bottom: 24px; */
}

.flexContainer {
    padding-bottom: 0px !important;
    display: flex;
}

/* =============================================================================
  Typography
============================================================================= */


/*.subHead {
    position: relative;
    padding-bottom: 10px;
    display: inline-block;
}

    .subHead::after {
        display: block;
        content: "";
        width: 60%;
        height: 3px;
        background: var(--SecondaryBrandColor);
        position: absolute;
        margin-top: 5px;
        bottom: 0;
        left: 0;
        border-radius: 25px;
    }
    */


/* =============================================================================
  Miscellaneous
============================================================================= */

.edit {
    width: 15px;
    margin-left: 8px;
    position: absolute;
    fill: var(--SecondaryBrandColor);
}

.card-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: #fff;
    border-top: none;
    padding: 0 24px 24px;
}

.btnWrapper {
    display: flex;
    justify-content: end;
}

.callLink {
    text-decoration: none;
    cursor: text;
    color: #343741;
    font-weight: normal;
}

.callLink:hover,
.callLink:focus {
    text-decoration: none;
    color: #343741;
    font-weight: normal;
}

a.callLink,
.validation-summary a.callLink {
    text-decoration: none;
    cursor: text;
}

.card-footer .btn-primary,
.btnWrapper .btn-primary {
    margin-left: 8px;
}

@media screen and (max-width: 767px) {
    .callLink,
    .callLink:hover {
        cursor: pointer;
        color: var(--LinkColor);
        font-weight: 600;
    }
}

.maxLength {
    font-size: 0.875rem;
    margin-bottom: 0;
    text-align: right;
}


/* =============================================================================
  Card and Modal
============================================================================= */

.card {
    border: 1px solid var(--Color__Neutral2);
    -webkit-box-shadow: 0 12px 24px 5px rgba(0, 87, 184, 0.06);
    box-shadow: 0 12px 24px 5px rgba(0, 87, 184, 0.06);
    padding: 0;
    margin-bottom: 16px;
}

.card-body,
.modal-body {
    padding: 24px;
}

.card-header,
.modal-header,
.modal-footer {
    background-color: #fff;
    padding: 24px;
}

/*Hide thead default close button for modal*/
.modal-header .close{
    display:none;
}

    .modal-header.btnAdjust {
        display: block;
    }

.card-header {
    background: none;
    font-size: 18px;
    line-height: 1.05;
    font-weight: var(--Heading__FontWeight);
    color: var(--Heading__Color);
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    vertical-align: middle !important;
}

.card-header .h4 {
    margin-bottom: 0;
    display: inline;
}

.card-header h2,
.modal-header h5 {
    /* TODO: hard coded for both h2 AND h5? */
    font-size: 28px;
    line-height: 1.05;
    font-weight: 600;
    color: var(--Heading__Color);
    margin-bottom: 0;
}

.modal-body,
.modal-header,
.modal-footer {
    padding: 16px;
}

.modal-footer .btnWrapper button {
    margin-left: 8px;
}

.modal-md {
    max-width: 560px;
}

.modal-body p:last-child {
    margin-bottom: 0;
}


/*Medium Down*/

@media screen and (max-width: 991.99px) {
    .card-body,
    .modal-body,
    .card-header {
        padding: 20px;
    }
}


/* =============================================================================
  Script Box
============================================================================= */

.scriptBox {
    box-sizing: border-box;
    min-height: 68px;
    height: fit-content;
    border: 1px solid var(--ScriptBox__Border);
    border-radius: 7px;
    background-color: var(--ScriptBox__Bg);
    margin-top: 22px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 24px;
}

.scriptBox .msg {
    color: var(--Heading__Color);
    font-size: 16px;
}

.scriptBox label {
    color: #001871;
    font-size: 16px;
}

.scriptBox .h3 {
    font-weight: normal;
    margin-bottom: 0;
}

.scriptBox .chatIcon {
    max-height: 3.8rem;
    max-width: 4rem;
    margin-right: 24px;
}


/* =============================================================================
  Error Handling
============================================================================= */

.form-error .form-label {
    color: var(--ErrorColor);
}

.form-error p {
    color: var(--ErrorColor);
}

.form-error div {
    color: var(--ErrorColor);
}

.form-error .datePickerField {
    border-color: var(--ErrorColor);
}
    .errorMsg {
        color: var(--ErrorColor);
        display: block;
    }

.form-error .form-control,
.form-error .radioButton input[type="radio"] + label,
.form-error .datePicker,
.form-error .dropbtn,
.form-error .frmRadioError,
.form-error .squareRadioBtn input[type="radio"] + label {
    border-color: var(--ErrorColor);
    background-color: white;
    /* background-color: var(--InputError__Bg); */
    color: var(--ErrorColor) !important;
}

.validationSummary,
.validationSummary a {
    color: var(--ErrorColor);
    font-weight: 600;
    text-align: left;
    margin-bottom: 24px;
}

.validationSummary a {
    text-decoration: underline !important;
}


/* =============================================================================
  Utilities
============================================================================= */

.va-align-parent {
    position: relative;
}

.va-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.centeredXY {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cardSectionHR {
    margin: 32px auto
}


/* .pageLoading {
    height: 600px;
    position: relative;
}

.pageLoading .spinner-border {
    width: 3rem;
    height: 3rem;
    border: .25em solid;
    border-right: .25em solid transparent;
    color: var(--PrimaryBrandColor) !important;
    animation: spinner-border .75s linear infinite;
    margin-bottom: .5rem;
} */

.hasTooltip,
.btnLink.hastooltip {
    /* TODO: Check color values */
    position: relative;
    font-weight: 600;
    border-bottom: 1px dashed var(--LinkColor);
    letter-spacing: var(--LetterSpacing);
    border-radius: 0;
    color: var(--LinkColor);
}

.tooltiptext {
    visibility: hidden;
    height: auto;
    width: 200px;
    background-color: #fff;
    color: var(--PrimaryTextColor);
    padding: 24px;
    border-radius: 3px;
    /* TODO: check box shadow colors*/
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 12%;
    top: -16%;
    border-left: 1px solid var(--Color__Neutral2);
    border-top: 1px solid var(--Color__Neutral2);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.hasTooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.address,
.noBullets {
    list-style-type: none;
    padding-left: 0;
}

.address li {
    padding-bottom: .25rem;
}

.address li:first-child {
    font-weight: 600;
    color: var(--Heading__Color);
}

.fiveColWrapper,
.twoColWrapper,
.multiColWrapper {
    margin: 0 -.5rem;
}

.errorMsgPhone {
    margin-top: -20px;
    color: var(--ErrorColor);
    display: block;
}

.fiveColWrapper div[class^="col"],
.twoColWrapper div[class^="col"],
.multiColWrapper div[class^="col"],
.queueHeaderWrapper div[class^="col"] {
    padding: 0 .5rem !important;
}

.queueHeaderWrapper div[class^="col"]:last-child {
    padding-right: 0 !important;
}

.queueHeaderWrapper div[class^="col"]:first-child {
    padding-left: 0 !important;
}

.queuereport-dropmenu .date-range-picker.input-group {
    padding-right: 0 !important;
    width: 100%;
}

.queueHeaderWrapper {
    margin: 0;
}

@media (min-width: 992px) {
    .col-5ths {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-5ths:nth-child(n+6) {
        margin-top: 1rem;
    }
}

.twoColWrapper {
    margin-bottom: 24px;
}


/* @media (max-width: 575px) {
    .twoColWrapper div[class^="col"]:first-child {
        margin-bottom: 24px;
    }
}

.headerTabs {
    padding-bottom: 0;
}

.headerTabs .h4 {
    padding-bottom: 1.5rem;
}

hr {
    border-top: 1px solid var(--Color__Neutral2);
    margin: 2rem 0;
}

hr.small {
    margin: 1rem 0;
}

/* =============================================================================
  Badges
============================================================================= */

.badge {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
    margin-left: 10px;
    font-weight: 600;
}

.badge-success,
.success {
    background-color: #13AA41;
}

.badge-warning,
.warning {
    background-color: #FFBF3F;
}

.badge-danger,
.danger {
    background-color: #E03C31;
}

.badge-info {
    background-color: #001871;
}

.warning {
    color: #212529;
}

.squareBadge {
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 0rem 0.35rem;
    text-align: center;
    width: 24px;
}

.circleBadge {
    height: 11px;
    width: 11px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
}


/* =============================================================================
  Accordion
============================================================================= */

.accordionGroup {
    border: 1px solid var(--Color__Neutral2);
    border-top: 0;
}

.accordion {
    width: 100%;
    padding: 12px 24px;
    text-align: left;
    color: var(--PrimaryBrandColor);
    font-weight: 600;
    position: relative;
    cursor: pointer;
    display: inline-block;
    position: relative;
    background-color: #f2f7fc;
    border-radius: 0;
    border-top: 1px solid var(--Color__Neutral2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.accordion.collapsed {
    background-color: #fff;
}

.accordion:after {
    display: inline-block;
    content: ' ';
    background-image: url(../assets/images/arrow-cheveron-down.svg);
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 24px;
    margin: auto;
    top: 0;
    bottom: 0;
}

.accordion.collapsed:after {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
}

.accordionContent {
    padding: 24px;
    border-top: 1px solid var(--Color__Neutral2);
}

/*Small Down*/

/* =============================================================================
  Tabs
============================================================================= */

.navTabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    right: 0;
}

.navLink {
    display: block;
    padding: .5rem 1rem;
}

.navTabs .navLink {
    color: var(--PrimaryTextColor);
    font-size: 14px;
    padding: 0.5rem .5rem;
    margin: 0 .75rem;
    border-radius: 0rem;
    cursor: pointer;
}

.navTabs .navLink:hover {
    text-decoration: none;
}

.navTabs .navLink.active,
.navTabs .navLink.focus,
.navTabs .navLink.hover,
.navTabs .show>.navLink {
    color: var(--SecondaryBrandColor);
    border-bottom: 4px solid var(--SecondaryBrandColor);
    background-color: white;
}


/* =============================================================================
External Date Picker Styles
============================================================================= */

.calendar-input {
    display: flex;
}

.react-date-picker__inputGroup {
    color: var(--PrimaryTextColor);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--TextFont);
    width: 160px;
}

.react-calendar__navigation__arrow {
    color: var(--Input__Icon) !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__label {
    color: var(--Input__Icon) !important;
    border: 1px solid var(--Input__Icon) !important;
    border-radius: 3px;
    margin-top: 6px !important;
    margin-left: 13px;
    margin-right: 13px;
    font-weight: 600;
}

.react-calendar__navigation button {
    background-color: #fff !important;
    height: 34px;
    font-family: var(--TextFont);
}

.react-calendar__month-view__days__day {
    color: var(--PrimaryBrandColor);
}

.react-calendar {
    background: #FFFFFF;
    border: 1px solid var(--Color__Neutral2) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 87, 184, 0.06) !important;
    border-radius: 3px;
    width: 300px !important;
    max-width: 300px !important;
    min-height: 296px;
    padding: 16px;
    position: absolute;
    /*top: 80px;*/
    z-index: 99;
    padding: 16px;
}

.react-calendar__month-view__weekdays {
    color: var(--PrimaryTextColor) !important;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    text-decoration: none;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: none;
    border-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    color: var(--PrimaryTextColor) !important;
    font-family: var(--TextFont);
    /* opacity: 100; */
    letter-spacing: var(--LetterSpacing);
}

.react-calendar__month-view__days {
    font-family: var(--TextFont);
    font-size: 12px;
    color: var(--PrimaryBrandColor) !important;
    letter-spacing: var(--LetterSpacing);
    text-align: center;
    line-height: 24px;
    background: #fff;
    border: none;
    min-width: 40px;
}

.react-calendar__tile {
    font-family: var(--TextFont);
    font-size: 12px;
    letter-spacing: var(--LetterSpacing);
    text-align: center;
}

.react-calendar__month-view__days__day {
    font-family: var(--TextFont);
    font-size: 12px;
    letter-spacing: var(--LetterSpacing);
    text-align: center;
    font-weight: normal;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--PrimaryBrandColor) !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__month-view__days__day:enabled:hover,
.react-calendar__month-view__days__day--weekend:enabled:hover .react-calendar__tile:enabled:focus,
.react-calendar__month-view__days__day:enabled:focus,
.react-calendar__month-view__days__day--weekend:enabled:focus {
    color: #fff;
}

.react-calendar__tile--active {
    border-radius: 3px;
    color: #fff;
}

.react-date-picker__clear-button__icon {
    display: none !important;
}

.react-date-picker__calendar-button:after {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/calendar.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.react-date-picker__calendar-button__icon.react-date-picker__button__icon {
    display: none;
}

.react-calendar__tile--hasActive {
    background-color: var(--SecondaryBrandColor) !important;
    border-radius: 3px;
    color: #fff;
    -webkit-appearance: button;
    line-height: 1;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: var(--ActiveTab) !important;
    border-radius: 3px;
    color: #fff !important;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile-now:enabled:focus {
    background-color: var(--SecondaryBrandColor) !important;
    color: white;
    border-radius: 3px;
    justify-content: center;
    line-height: 1;
    margin: 0;
    padding: 8px;
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-calendar__year-view__months>.react-calendar__tile,
.react-calendar__decade-view__years__year>.react-calendar__tile,
.react-calendar__year-view__months>.react-calendar__decade-view .react-calendar__tile,
.react-calendar__year-view__months>.react-calendar__century-view .react-calendar__tile {
    padding: 5px !important;
    margin-top: 19px;
    margin-bottom: 2px;
    font-size: 13px !important;
    font-weight: normal
}

.react-calendar__year-view__months>.react-calendar__tile--now,
.react-calendar__year-view__months>.react-calendar__tile:enabled:hover,
.react-calendar__year-view__months>.react-calendar__tile--now:enabled:hover,
.react-calendar__year-view__months>.react-calendar__tile-now:enabled:focus,
.react-calendar__year-view__months>.react-calendar__tile:enabled:focus {
    padding: 5px !important;
    margin-top: 19px !important;
    margin-bottom: 2px !important;
    font-size: 13px !important;
    font-weight: normal
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    font-size: 24px;
    padding-top: 12px;
}

.react-date-picker__inputGroup {
    height: 22px;
    color: var(--PrimaryTextColor);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--TextFont);
    width: 160px;
}

abbr[title],
abbr[data-original-title] {
    font-size: 12px;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0;
}

.react-calendar__decade-view .react-calendar__tile,
.react-calendar__tile-now:enabled:focus,
.react-calendar__century-view .react-calendar__tile,
.react-calendar__tile--now:enabled.react-calendar__decade-view__years__year:hover,
.react-calendar__decade-view__years__year {
    padding: 10px 0.5em;
    margin-top: 15px;
}

.react-calendar__tile:disabled {
    background: none;
    color: var(--Color__Neutral3) !important;
}


/* =============================================================================
External Range Date Picker Styles
============================================================================= */

.DateInput {
    width: 115px !important;
}

.DateInput:first-child {
    width: 85px !important;
}

.DateInput input {
    border-radius: 0 !important;
}

.CalendarDay__default,
table.CalendarMonth_table tr,
table.CalendarMonth_table,
.DayPickerNavigation_button__default {
    border: none !important;
    color: var(--PrimaryBrandColor) !important;
    font-size: 12px !important;
}

.CalendarDay__default:hover,
.CalendarDay__selected {
    background-color: var(--SecondaryBrandColor) !important;
    color: #fff !important;
}

.CalendarDay__selected_span {
    border-color: var(--SecondaryBrandColor) !important;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    color: var(--Color__Neutral3) !important;
    background-color: #fff !important;
}

.CalendarMonth_caption {
    color: var(--SecondaryBrandColor) !important;
}


/* =============================================================================
Search Field
============================================================================= */

.search-input {
    position: relative;
}

.search-input label {
    margin-bottom: 0;
    width: 100%;
}

.search-input input {
    width: 100%;
    min-width: 100%;
    padding-right: 68px !important;
    /*width: 165px;*/
}

.search-input img {
    position: absolute;
    right: 6px;
    width: 20px;
    top: 7px;
}

@media screen and (max-width: 1200px) {
    .search-input input {
        width: 100%;
    }
    .search-input img {
        right: 20px;
        width: 17px;
        top: 8px;
    }
}


/* =============================================================================
  Footer
============================================================================= */

footer {
    background: #4a4a4a;
    padding: 16px 0;
    font-size: 14px;
    color: #fff;
    position: relative;
}

footer p {
    margin-bottom: 0;
}

footer a {
    padding: 0 .5rem;
    text-decoration: none;
    color: #fff;
}

footer a:first-child {
    padding-left: 0;
}

footer a:hover {
    text-decoration: underline;
    color: #fff;
}

footer a:focus {
    outline-color: Highlight;
}

.contractFooter {
    margin-bottom: -24px !important;
}

@media (max-width: 992px) {
    footer {
        padding: 24px 0;
    }
}


/* =======================================================================================
Alert Messages
============================================================================= */

.customAlert {
    position: absolute;
    top: 53px;
    left: 32%;
    min-width: 35%;
    padding: 1rem;
    border-radius: 3px;
    color: #fff;
}

.customAlert.alert-success {
    background: #13AA41;
    color: #fff;
}

.customAlert.alert-primary {
    background: var(--SecondaryBrandColor);
    color: #fff;
}

.alertHeader {
    color: #fff;
    margin-bottom: .5rem;
}

.alertDesc {
    margin-bottom: 0;
    font-size: 14px;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: var(--TextFont);
    line-height: var(--LineHeight);
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
    display: flex;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
    color: var(--ErrorColor);
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}


/* PDF Viewer */

div [data-testid="viewer"] {
    height: auto !important;
}

.selection-cell{
    width: 50px;
}

.show-lg-up {
    display: block;
}

/* Styling for Breadcrumbs - Vendor Creation */
.breadcrumb {
    background-color: transparent;
    padding: 0px;
}

.breadcrumb-item.active::before, 
.breadcrumb-item::before {
   background-image: url("../assets/images/arrow-cheveron.svg");
   height: 8px;
   width: 4px;
   content: "" !important;
   margin-right: 8px;
   transform: rotate(180deg);
   padding-right: 0px !important;
   padding-left: 0px !important;
}

@media (max-width: 1410px) {
    .show-lg-up {
        display: none;
    }
}

.footerNav {
    list-style-type: none;
    display: inline-flex;
    padding-left: 0;
    text-decoration: none;
}

.footerNav li:first-child ::after {
    content: "|";
    color: #FFF;
    margin-left: 16px;
}

.footerNav li a:first-child {
    padding-right: 16px;
}

.footerNav li a {
    color: #FFF;
    padding: 0;
}
@import url('../../../css/VARIABLES.css');
@import url(../../../css/radiosAndChecks.css);
.planTotal {
    display: flex;
    justify-content: space-between;
}

.taxApplied {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Color__Neutral2);
    margin-bottom: 0.5rem;
}

.formLabel {
    color: var(--Input__LabelColor);
    font-weight: 600;
    line-height: 1.5;
    max-width: 100%;
    padding-top: 3px;
    display: inline-block;
}

.orderTotal {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: var(--Heading__Color);
    margin-bottom: 0.5rem;
}

.totalInfo p {
    margin-bottom: 0.5rem;
}

/*.paymentAmt {
    display: flex;
    width: fit-content;
    height: 26px;
    justify-content: space-between;
    border-radius: 22px;
    align-items: center;
    background-color: #E1EFFF;
    padding: 0px 10px;
}

.paymentAmt p {
    margin-bottom: 0rem;
}

.paymentAmt p:first-child {
    color: #4A4A49;
    margin-right: 6px;
}

.paymentAmt p:last-child {
    color: var(--Heading__Color);
    font-size: 18px;
    font-weight: 600;
}*/

.cardInfo {
    border-bottom: 1px solid var(--Color__Neutral2);
}

.customPayBtn {
    width: 184px;
}

.tooltiptext {
    width: 250px;
    top: 85%;
    left: -16%;
}

.tooltiptext::after {
    top: -22%
}

.inputTooltip,
.inputContainer {
    position: relative;
}

.inputTooltip input {
    padding-right: 15px;
}

.inputTooltip span {
    position: absolute;
    right: 0px;
    padding: 5px 10px;
}

.inputTooltip .hasTooltip {
    border-bottom: none;
}

.ccImageList {
    margin: 0;
    list-style: none;
    display: flex;
    position: absolute;
    top: 36px;
    right: 10px;
    pointer-events: none;
}

.ccImages svg {
    height: 25px;
    width: 35px;
    margin-left: 8px;
}

.ccPin {
    height: 45px;
    width: 68px;
    margin-bottom: 12px;
}

.hasTooltip {
    position: absolute;
    right: 14px;
    bottom: 0;
    height: 100%;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.secCodeInfo {
    position: absolute;
    right: 9px;
    bottom: 8px;
}

.codeHasTooltip {
    position: relative;
    letter-spacing: 0.44px;
    border-radius: 0;
}

.secCodeTooltip {
    visibility: hidden;
    display: grid;
    height: auto;
    width: 270px;
    background-color: white;
    color: var(--PrimaryTextColor);
    padding: 24px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: 115%;
    left: 165px;
    opacity: 0;
    transition: opacity 0.3s;
}

.secCodeTooltip::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 12%;
    top: -13px;
    border-left: 1px solid var(--Color__Neutral2);
    border-top: 1px solid var(--Color__Neutral2);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.codeHasTooltip:hover .secCodeTooltip {
    visibility: visible;
    opacity: 1;
}

.secCodeTooltip svg {
    margin-bottom: 10px;
}
.subTax {
    margin-left: 20px;
    margin-bottom: 1px !important;
}

.twoColWrapperXS {
  padding: 0 .5rem !important;
}

/* Advantage campaign styles */
.secondCardHeader {
    background-color: var(--AdvantageAccentColor);
    color: var(--Advantage__TextColor);
    padding: 10px 0px 10px 16px;
}


.secondCardHeaderh5 {
    color: var(--Advantage__TextColor);
    font-size: var(--H5__Size);
    margin-bottom: 0;
}

.benefitsList {
    border-radius: 5px;
    border: 1px solid var(--Color__Neutral3);
}

.listOfBenefits {
    padding: 1rem;
}

    .listOfBenefits p:nth-of-type(2) {
        color: var(--PrimaryBrandColor);
        font-weight: 600;
        font-size: 14px;
    }

.contactTypes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 27px;
}

.contactConfirmation svg {
    height: 40px;
    width: 83px;
    margin-right: 10px;
}

.contactConfirmation {
    display: flex;
    align-items: center;
}

    .contactConfirmation div {
        color: var(--SecondaryBrandColor);
        font-size: 16px;
        line-height: 20px;
    }

@media screen and (min-width:320px) and (max-width:568px)
{
    .twoColWrapperXS {
      margin-bottom: 24px;
    }
}
@media screen and (max-width:359px)
{
    .secCodeTooltip {
            left:83px;
    }
}
@media screen and (min-width:360px) and (max-width:375px)
{
        .secCodeTooltip {
            left:107px;
    }
}
@media screen and (min-width:411px) and (max-width:539px)
    {
        .secCodeTooltip {
            left:129px;
    }
}
@media screen and (min-width:540px) and (max-width:767px){
        .secCodeTooltip {
            left:189px;
    }
}

.customRadioButton:before {
    border: 1px solid var(--RadioButton__CheckedBorder) !important;
}
.editBtn {
    border: none;
    background-color: transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #343741;
    padding: 0px;
}

    .editBtn svg {
        margin-right: 9px;
    }

        .editBtn svg g g g g {
            fill: var(--IconColor);
        }

.coveragePrefHead {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

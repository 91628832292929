@import url('../../../css/VARIABLES.css');

.footer {
    border: none;
    height: 36px;
}

tfoot th.noborder {
    border: none;
}

.componentColumn{
    width: 255px;
}

.repairColumn {
    width: 310px;
}

.assetComponent {
    width: 280px;
}

.assetWidth{
    width: 209px;
}

.trashIcon {
    height: 16px;
    width: 16px;
    fill: var(--IconColor);
    cursor: pointer;
}

.trashIconHeader {
    height: 22px;
    width: 16px;
    fill: var(--IconColor);
    margin-left: 4px;
}

.partsAssetWidth{
    width: 200px;
}

.containsInput {
    padding: 0 !important;
}

.containsInputAlign {
    padding: 0 !important;
    font-size: 16px!important;
}

.makeQuantityInputCenter input{
    text-align: center;
}

.containsInput input,
.containsInput select {
    padding: var(--tablePadding);
    width: 100%;
    height: 100%;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: 2px;
}

.fontSizeReset * {
    font-size: 14px !important;
}

.textTransform {
    text-transform: capitalize;
}

.partsWidth{
    width: 80px;
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    .Container p:first-of-type {
        text-align: center;
    }
    .profileIcon svg {
        height: 120px !important;
    }
  }
  
  .profileIcon svg {
    height: 160px;
    margin-bottom: 32px;
  }


  .paraWrapper {
    margin-bottom: 0.5rem;
  }
@import url('../../css/VARIABLES.css');
@media screen and (max-width: 991px) {
    .leftCol {
        border-right: none !important;
        padding-right: 15px !important;
    }
    .eventNum p:nth-of-type(2) {
        display: block !important;
    }
    .ratesList li,
    .repairHistList li {
        padding: 8px 0px !important;
    }
    .incidentDetTable table {
        margin-bottom: 0px !important;
    }
    .incidentDetTable div {
        margin-bottom: 24px !important;
    }
    .rightCol {
        padding-left: 15px !important;
    }
    .actionButtons {
        box-sizing: border-box !important;
        flex-direction: column !important;
        display: flex !important;
        width: 100% !important;
        margin-left: none !important;
    }

    .reasonWrapper {
        margin-left: -16px !important;
    }

    .acceptButton {
        margin-left: 0rem !important;
    }
    .notePay {
        text-align: left !important;
        margin-bottom: 0px;
    }
    .incDetails {
        margin-bottom: 24px !important;
    }
    .ratesList {
        margin-bottom: 24px !important;
    }
    .rightCol h2 {
        text-align: left !important;
    }
    .amountBadge {
        justify-content: flex-start !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .repairHistList {
        margin-bottom: 24px !important;
    }
}


@media screen and (max-width: 767px) {
    .aigLogo {
        display: none !important;
    }
    .formTitle div {
        margin-bottom: 32px !important;
        margin-left: 0px !important;
    }
    .rfsHeader {
        flex-direction: column !important;
    }
    .repairHist button {
        margin-bottom: 24px !important;
        width: 100% !important;
    }   
    .detailsRow {
        display: block !important;
    }
    .repairHistList {
        margin-bottom: 16px !important;
    }
    .acceptButton {
        margin-left: 0rem !important;
    }
}

.nonCoveredRepairTopics {
    display: flex;
    flex-flow: column;
    max-height: 75vh;
    padding-inline-start: 16px;
    margin-top: 4px;
    overflow: auto;
}

.nonCoveredRepairTopics > li > span{
    position: relative;
    left: -6px;
}

.rfsHeader {
    display: flex;
    justify-content: space-between;
    background-color: var(--Color__Neutral1);
    padding: 40px !important;
    border-bottom: none;
}

.aigLogo svg {
    height: 53px;
    width: 99px;
}

.formTitle {
    display: flex;
    align-items: center;
}

.formTitle h1 {
    margin-bottom: 0px;
}

.formTitle div {
    margin-left: 32px;
}

.formTitle p {
    margin-bottom: 0px;
    font-weight: normal;
}

.eventNum p {
    font-size: 16px;
    font-weight: 600;
    color: var(--Heading__Color);
}

.eventNum p:nth-of-type(1) {
    margin-bottom: 8px;
}

.eventNum p:nth-of-type(2) {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
}

.rfsBody {
    padding: 40px;
}

.details h2,
.incDetailsHead,
.repairHist h2 {
    font-weight: 600;
    margin-bottom: 8px !important;
}

.details ul {
    list-style: none;
    padding: 0px;
    margin-bottom: 24px;
}

.incDetails {
    list-style: none;
    padding: 0px;
    margin-bottom: 24px;
}

.leftCol {
    border-right: 1px solid var(--Color__Neutral3);
    padding-right: 40px;
}

.rightCol {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
}

.rightCol h2 {
    text-align: center;
    margin-bottom: 0px;
}

.ratesList {
    list-style: none;
    padding: 0px;
    margin-bottom: 24px;
}

.repairHistList {
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
}

.ratesList li,
.repairHistList li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Color__Neutral2);
    padding: 8px 16px;
}

.ratesList li p,
.repairHistList li p {
    margin-bottom: 0px;
}

.totalLimit svg {
    height: 16px;
    width: 16px;
    margin-bottom: 9px;
    margin-left: 7px;
    fill: var(--IconColor);
    stroke: var(--IconColor);
}

.amountBadge {
    display: flex;
    justify-content: center;
    margin-bottom: 29px;
}

.amountBadge span {
    width: 71px;
    margin-left: 0px;
}

.notePay {
    text-align: center;
}

.repairHist {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #dce2ea;
    padding: 4px 16px;
}

.repairHist h2 {
    margin-bottom: 0px;
}

.conditionsList {
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
}

.conditionsList li span {
    color: var(--Heading__Color);
    font-weight: 600;
    margin-right: 5px;
}

.conditionsList li {
    margin-bottom: 6px;
}

.conditionsList li:last-of-type {
    margin-bottom: 0px !important;
}

.subList {
    list-style: none;
    padding-inline-start: 21px !important;
}

.incidentDetTable table {
    margin-bottom: 24px;
}

.totalLimit {
    margin-bottom: 0px;
    font-weight: 600;
}

.preAuth {
    font-weight: 600;
    margin-bottom: 8px !important;
}
.reasonLabel{
    color: #001871;
    font-weight: 600;
}
.rejectModalTitle{
    color: #001871;
}
.acceptButton{
    margin-left:1rem;
}
.actionButtons{
    margin-left: auto !important;
    display: flex;
    height: fit-content;
}
.reasonWrapper{
margin-left:-16px !important;
}

@import url('../../css/VARIABLES.css');
@media screen and (min-width:767.99px) and (max-width: 991px) {
    .emailInput {
        margin-left: 5%;
    }
    .switchWrapper {
        justify-content: flex-start !important;
    }
}

@media screen and (max-width: 760px) {
    .commSwitchProfileText {
         width: 80% !important;
    }
}

@media screen and (min-width: 760px) and (max-width: 991px) {
    .commSwitchProfileText {
         width: 50% !important;
    }
}

@media screen and (min-width: 992px) {
    #activeNavitem {
        border: none !important;
        border-bottom: 4px solid var(--ActiveTab) !important;
    }
    .navItem:nth-child(1) {
        margin-left: 8px !important;
    }
    .navItem {
      flex: 0 0 16.5% !important;
    }
}

@media screen and (max-width: 425px) {
    .tabsProfileInfo {
        padding: 0px 19px !important;
        margin-top: 15px !important;
    }
}

@media screen and (min-width:426px) and (max-width: 991px) {
    .tabsProfileInfo {
        padding: 0px 3% !important;
        margin-top: 15px !important;
    }
}

@media screen and (max-width: 991px) {
    .navItem {
        border: 1px solid var(--Color__Neutral2) !important;
        margin-bottom: 2% !important;
    }
}

@media screen and (max-width:765px){
    .contactTypeAlignment{
        margin-bottom: 16px !important;
    }
}

#activeNavitem {
    border-bottom: 4px solid var(--ActiveTab) !important;
}

.activeNavitem {
    background-color: transparent !important;
    border: none !important;
}

.nav-tabs .nav-link.active {
  background-color: #f00 !important;
  border: var(--Input__Border) !important;
  border-radius: var(--Input__BorderRadius) !important;
}

.navItem:hover {
    border: 1px solid var(--Color__Neutral2) !important;
}

.activeNavitem a {
    border: none !important;
}

.activeNavitem:hover,
.navLink:hover {
    border: none !important;
}

.tabsProfileInfo {
    font-weight: 600;
    padding-top: 1.5rem;
}

.navLink {
    text-align: center;
    font-size: 14px;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
}

.tabsProfileInfoc ul li a {
    border: none !important;
}

.editProfileBtn {
    background: transparent;
    border: none;
    font-weight: normal;
    display: flex;
}

.editProfileBtn img {
    margin-right: 0.2rem;
}

.companyInfoHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.companyInfoHead h3 {
    margin-bottom: 0rem;
}

.docIcon svg {
    height: 24px;
    width: 24px;
    margin-right: 10px;
}

.docIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.docIcon p {
    margin-bottom: 0rem;
    color: var(--IconColor);
    font-weight: 600;
}

.unselectedContact {
    border-radius: 4px;
    background-color: transparent;
    margin: 0rem;
    padding: 16px 9px 0px 9px;
    position: relative;
    border: 1px solid var(--ScriptBox__Border);
}

.contactTyp p {
    margin-bottom: 0rem;
    color: var(--PrimaryTextColor);
    font-weight: 400;
}
.contactTypeAlignment{
    margin-bottom: 0px;
}
.actionButtons {
    display: flex;
    justify-content: flex-end!important;
    margin-top: 40px;
}

.companyInfoRow p {
    margin-bottom: 0rem;
}

.companyInfoRow a p {
    color: var(--LinkColor)
}


/* commSwitch toggle */

.commSwitch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 18px;
}

.smsSwitch {
    margin-left: 32px;
}

.emailSwitch {
    margin-left: 66px;
}

.smsSwitch::before {
    top: -4%;
    content: "SMS";
    position: absolute;
    right: 115%;
}

.emailSwitch::before {
    top: -4%;
    content: "Email";
    position: absolute;
    right: 115%;
}

.commSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.commSwitchProfile {  
  position: relative;
  display: inline-block;
  width: 37px;
  height: 18px;
}
.commSwitchProfileText {
  display: inline-block;
  width: 40%;
}
.commSwitchProfileInput {
  display: inline;
}
.commSwitchProfileInput input{
  visibility: hidden;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid var(--Input__Border);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 13%;
    background-color: white;
    border: 1px solid var(--Input__Border);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background: var(--InputToggle__Color);
    border: 1px solid var(--InputToggle__Color);
}

input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

.slider.round {
    border-radius: 16px;
}

.slider.round:before {
    border-radius: 50%;
}

.switchWrapper {
    display: flex;
    padding-top: 8px;
}

.primeCont {
    color: var(--PrimaryBrandColor);
    margin-bottom: 0rem;
    font-size: 14px;
    font-weight: 600;
}

.bgSelected {
    border-radius: 4px;
    background-color: var(--ScriptBox__Bg);
    box-shadow: 0 2px 4px 3px rgba(0, 4, 8, 0.02);
    margin: 0rem;
    padding: 16px 9px 0px 9px;
    position: relative;
    border: 1px solid var(--ScriptBox__Border);
}

.bgSelectedIcp {
    border-radius: 0px;
}

.contactSelection {
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.contactSelection input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.contactSelLabel {
    margin-left: 1rem;
}

.contactSelection .contactSelLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    /* color: #343741; */
    color: var(--PrimaryTextColor);
    font-weight: 600;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 1.5;
    padding-left: 16px;
    position: relative;
    text-align: left;
}

.contactSelection input[type=radio]:checked + .contactSelLabel:before {
    border: 1px solid var(--RadioButton__CheckedBorder);
}

.contactSelection .contactSelLabel:before {
    border-radius: 50%;
}

.contactSelLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    border-radius: 0;
    border: 1px solid var(--RadioButton__Border);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 18px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 8px 0 -22px;
    max-width: 18px;
    transition: background .3s ease;
    width: 18px;
}

.contactSelection input[type=radio]:checked+.contactSelLabel:after {
    background: var(--RadioButton__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: -5%;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 9px;
}

.deleteIconMob svg g,
.deleteIconDesk svg g {
    fill: var(--IconColor);
}

.deleteIconMob {
    margin-right: 12px;
}

.addContactBtn {
    display: flex;
    margin-top: 1rem;
}

.addContactBtn:hover .regularAdd svg g g {
    stroke: white;
}

.addContactBtn:focus .regularAdd {
    display: flex;
    margin-right: 4px;
}

.addContactBtn:focus .regularAdd svg g g {
    stroke: var(--PrimaryBrandColor);
}

.addContactBtn .regularAdd {
    display: flex;
    margin-right: 4px;
}

.addContactBtn:focus {
  background: transparent !important;
}

.notEnrolledStyle a {
    background-color: var(--AdvantageAccentColor) !important;
    color: var(--Advantage__TextColor)!important;
}

.notEnrolledStyle {
    background-color: var(--AdvantageAccentColor) !important;
    color: var(--Advantage__TextColor);
    border-radius: 6px 6px 0px 0px;
}

.payMethod {
    display: flex;
    align-items: center;
}

.payMethod p {
    margin-bottom: 0rem;
}

.payMethod svg {
    height: 30px;
    margin-right: 8px;
}
.whySellPoints {
    display: flex;
    padding: 0px;
}

.whySellPoints svg {
    height: 42px;
    margin-right: 18px;
}

.purchasePath b {
    color: var(--PrimaryBrandColor);
}

.purchasePath:nth-of-type(3) {
    margin-bottom: 0px;
}

.lockIcon svg {
    margin-right: 26px !important;
}

.ratingsIcon svg {
    margin-right: 10px !important;
}

.checkboxesIcon svg{
    width: 44px;
    margin-right: 5px;
}

.checkboxesIconLennox svg{
    fill: var(--PrimaryBrandColor);
}
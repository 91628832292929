.Adjudication {
    --tablePadding: 4px 8px;
}

.trashIcon {
    height: 17px;
    width: 14px;
    cursor: pointer;
    transition: all .1s ease;
    margin: 0 4px 3px;
    fill: #001871;
}

.plus {
    fill: #1354de;
    height: 12px;
    width: 12px;
    margin-right: 4px;
}

.fontSizeReset * {
    font-size: 14px !important;
}

.deleteCell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    padding: 0px 5px;
}

caption {
    padding: 0;
    border:1px solid #dee2e6;
    border-bottom: none;
    border-top: none;
}
.topTable {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.removeBottomBorder {
    border-bottom: none;
}

.topTable caption {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid #dee2e6;
}

.sectionHeaderRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f9fd;
    padding: var(--tablePadding);
    height: 36px;
}
.sectionHeaderRow p {
    margin-bottom: 0;
    color: #343741;
    font-weight: 600;
}

.addRow {
    color: #1354de;
    cursor: pointer;
    text-align: left;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.milegeRow {
    margin-left: -50px;
}

tfoot > tr > th {
    border: none;
    font-weight: 400;
    text-align: right;
    height: 36px;
}

thead > tr > th {
    border-bottom: none !important;
    
}

.static {
    vertical-align: middle;
    margin-top: 4px;
}

.footer {
    border: none;
    height: 36px;
}

tfoot th.noborder {
    border: none;
}

.AdjudicationTable th {
    padding: var(--tablePadding);
}

.AdjudicationTable th,
.AdjudicationTable td {
    vertical-align: middle;
}

.AdjudicationTable input,
.AdjudicationTable select {
    width: 100%;
}

.containsInput {
    padding: 0 !important;
}

.containsInput input,
.containsInput select {
    padding: var(--tablePadding);
    width: 100%;
    height: 100%;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: 2px;
}

.EventTotalsRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background-color: #f6f9fd;
    font-weight: 600;
    height: 36px;
}

.EventTotalsRow * {
    padding: var(--tablePadding);
}

.EventTotalsRow div + div {
    border-left: 1px solid #ddd;
    width: 100px;
    display: flex;
    justify-content: end;
    align-items: center;
}


.remainingWrapper {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.remainingCoverage {
    padding: 8px 16px;
    line-height: 28px;
    align-self: flex-start;
    background-color: #DEE9F6;
    color: #001871;
    border-radius: 12px;
    margin-left: auto;
    min-height: 30px;
    text-align: right;
}

.remainingAmount {
    font-weight: 600;
    font-size: 18px;
    margin-left: 4px;
}
.submitInvoice {
    display: flex;
    justify-content: end;
    float:right;
}
.errorPadding{
    margin-top: -23px;
}

.readOnlyInput {
    text-align: right;
    padding: 6px;
}

.mileageTable span[class^="InfoTooltip_onsiteInfo"] {
    margin-left: 3px;
}

.mileageTable span[class^="InfoTooltip_onsiteInfo"] svg {
    margin-left: 3px;
    vertical-align: sub;
}

.mileageTable span[class^="InfoTooltip_onsiteInfoTooltiptext"] {
    width: 15rem;
}

@media (max-width: 1024px) {
    .AdjudicationTableContainer {
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

@media (max-width: 767px) {
    .AdjudicationTableContainer {
        overflow-x: scroll;
    }
}
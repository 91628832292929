.CalendarDay__selected_span {
    background: #C7DBF4 !important;
    border: 1px double #C7DBF4 !important;
    color: #001871 !important;
    justify-content: center;
    line-height: 1;
    margin: 0;
    padding: 8px;
}

.CalendarDay__selected {
    background: #0062cc !important;
    color: #fff !important;
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: none !important;
    color: #fff;
}

.CalendarDay__selected:hover {
    background: #0062cc !important;
    border: 1px double #005cbf !important;
    color: #fff !important;
}

.CalendarDay__default {
    border: none !important;
    color: #484848;
    background: #fff;
}

    .CalendarDay__default:hover {
        background: #0062cc;
        border: 1px solid #005cbf !important;
        color: #fff;
    }

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #0062cc !important;
    border: 1px double #005cbf !important;
    color: #fff !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid #005cbf !important;
}

.DayPickerKeyboardShortcuts_buttonReset {
    display: none !important;
}


.DateRangePickerInput {
    border: 1px solid #b9c6d4;
    border-radius: 3px;
    height: 36px;
    width: 100%;
}

.date-range-picker {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.DateInput input {
    background-image: none;
    border-radius: 3px;
    color: #343741;
    padding: 4px 0px 0 4px;
    font-size: 0.75rem;
}

.DateInput:first-child {
    width: 65px !important;
}

.DateInput {
    width: 49px !important;
}

.service-req-date-picker .DateInput {
    width: 65px !important;
}

.DateRangePicker {
    width: 100%;
}

.queuereport-dropmenu .DateRangePickerInput_clearDates_default:focus,
.queuereport-dropmenu .DateRangePickerInput_clearDates_default:hover {
    background: none;
}

.queuereport-dropmenu .DateRangePickerInput_clearDates {
    top: 43%;
}

.queuereport-dropmenu .DateInput input {
    min-width: 65px;
}

.queuereport-dropmenu .date-range-picker {
    margin-bottom: 15px;
}

.date-range-picker img {
    position: absolute;
    right: 10px;
    width: 20px;
    top: 6px;
}

.DayPicker_transitionContainer {
    position: fixed;
    overflow: hidden;
    border-radius: 3px;
}

.CalendarMonth_table, .CalendarMonth_table tr {
    border-top: none !important;
    border-bottom: none !important;
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
}

.DateRangePickerInput_clearDates, .DateRangePickerInput_clearDates_default, .DateRangePickerInput_clearDates_default:hover {
    background: none !important;
    border-radius: 50%;
    top: 14px !important;
}

@import url('./VARIABLES.css');
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: var(--Heading__Color);
    font-weight: var(--Heading__FontWeight);
    margin-bottom: 16px;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: var(--H1__Size);
    /* line-height: var(--H1__LineHeight); */
    font-weight: var(--H1__Weight);
}

h2,
.h2 {
    font-size: var(--H2__Size);
    /* line-height: var(--H2__LineHeight); */
}

h3,
.h3 {
    font-size: var(--H3__Size);
    /* line-height: var(--H3__LineHeight); */
}

h4,
.h4 {
    font-size: var(--H4__Size);
    /* line-height: var(--H4__LineHeight); */
}

h5,
.h5 {
    font-size: var(--H5__Size);
    font-weight: var(--H5__Weight);
    /* line-height: var(--H5__LineHeight); */
}

h6,
.h6 {
    font-size: var(--H6__Size);
    font-weight: var(--H6__Weight);
    /* line-height: var(--H6__LineHeight); */
}

p {
    font-size: var(--FontSize);
}

.legal {
    font-size: var(--Legal__Size);
}
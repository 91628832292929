@import url('../../css/VARIABLES.css');

@media (min-width: 767.99px) and (max-width: 991px) {
    .infoReviewCards:first-of-type {
        height: 362px;
    }
}

@media (min-width: 991px) {
    .infoReviewCards:first-of-type {
        height: 328px;
    }
}

@media screen and (max-width: 1020px) {
    .pageHeader {
        display: none !important;
    }
}

@media screen and (max-width: 425px) {
    .planDetTable div table {
        width: 1000px !important;
    }
}

.editBtn {
    background-color: transparent;
    border: none;
    color: var(--PrimaryTextColor);
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    width: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0rem;
}

.editBtn svg {
    height: 16px;
    width: 16px;
    fill: var(--IconColor);
}

.cardHead {
    display: flex;
    justify-content: space-between;
}

.planTotal {
    display: flex;
    justify-content: space-between;
}

.planTotal p {
    margin-bottom: 0rem;
}

.taxTotal {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Color__Neutral2);
}

.totalAmount {
    color: var(--Heading__Color);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 1.5rem;
}

.planDetTable div thead tr th:nth-child(1) {
    width: 20%;
}

.planDetTable div thead tr th:nth-child(2) {
    width: 10%;
}

.planDetTable div thead tr th:nth-child(3) {
    width: 14%;
}

.planDetTable div thead tr th:nth-child(4) {
    width: 11%;
}

.planDetTable div thead tr th:nth-child(5) {
    width: 10%;
}

.planDetTable div thead tr th:nth-child(6) {
    width: 9%;
}

.planDetTable tr,
.planDetTable th,
.planDetTable table {
    border: none !important;
}

.coveredProdTable tr,
.coveredProdTable th,
.coveredProdTable table {
    border: none !important;
}

.coveredProdTable thead tr th:nth-child(1) {
    width: 20%;
}

.coveredProdTable thead tr th:nth-child(2) {
    width: 10%;
}

.coveredProdTable thead tr th:nth-child(3) {
    width: 20%;
}

.coveredProdTable thead tr th:nth-child(4) {
    width: 10%;
}

.coveredProdTable thead tr th:nth-child(5) {
    width: 10%;
}

.coveredProdTable thead tr th:nth-child(6) {
    width: 14%;
}

.coveredProdTable thead tr th:nth-child(7) {
    width: 10%;
}

.errorText {
  color: var(--ErrorColor);
}
.headerRow {
    padding: 25px 15px 0;
    margin: 0;
    border-bottom: 1px solid #ddd;
}
 .customNavTabs {
    border-bottom: none !important;
    font-weight:bold;

}

.customNavTabs a[class^="navLink active"] {
    color: var(--SideNav__ActiveText);
    border-bottom: 4px solid var(--SideNav__ActiveSideLine) !important;
    border: none;
} 

.customNavTabs a[class^="navLink"]:hover {
    border: none;
}

@media screen and (max-width: 767px) {
    .customNavTabs {
        position:relative;
    }
}

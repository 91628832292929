@import url('../../css/VARIABLES.css');

.tableWrapper {
    min-height: 6rem;
}

.claimSummaryTable tbody tr:hover {
    background-color: var(--Table__RowBg) !important;
}

.claimSummaryTable thead th {
    vertical-align: top !important;
    white-space: nowrap;
}

.claimSummaryTable thead tr {
    border-top: none !important;
}

.react-bootstrap-table table {
    table-layout: auto;
}

.toggleIcon {
    fill: var(--IconColor);
    margin-right: 20px;
    width: 20px;
}

.disableToggleIcon {
    margin-right: 20px;
    fill: var(--Color__Neutral3);
    width: 20px;
}

.queueHeader {
    margin-bottom: 20px;
}

.queueLeftContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.headerText {
    font-size: 1.5rem;
    color: var(--Heading__Color);
}

.popper {
    width: 262px;
    height: 95.9px;
}

.tooltipInner {
    background: white;
    color: var(--PrimaryTextColor);
    font-size: 10px !important;
    max-width: 270px;
    height: 95px;
    border: 1px solid var(--Color__Neutral2);
    padding: 20px;
}

    .tooltipInner p {
        font-size: 13px;
        text-align: left;
        color: var(--PrimaryTextColor);
    }

    .tooltipInner::after {
        border-top-color: white !important;
    }


.tooltipArrow::before, .tooltip.arrow::before {
    border-top-color: var(--Color__Neutral2) !important;
    border-bottom-color: var(--Color__Neutral2) !important;
}

.seperator {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: -5px 0 10px;
    background: #eee;
}


.queueRightContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.resubmitDoc {
    display: flex;
    align-items: center;
}

.filterWidth {
    max-width: 150px;
}

.dropmenu {
    width: 13rem;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.dropmenubuttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

    .dropmenubuttons button {
        width: 100%;
        margin: 8px 0 0;
        padding: 3px;
    }

.dropmenuactions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.blueIcon {
    fill: var(--IconColor);
    width: 16px;
}

.dateFilterCalendar {
    height: 22px;
    fill: var(--IconColor);
    position: absolute;
    right: 10px;
    top: 5px;
}

.exceptionBorder {
    position: absolute;
    width: 4px;
    height: 42px;
    background: red;
    left: -65px;
    top: -11px;
}


.orderTotal {
    position: absolute;
    top: -91px;
    padding: 3px 10px;
    left: 0;
    border-radius: 22px;
}

/*Medium Down*/
@media screen and (max-width: 1200px) {
    .headerText {
        font-size: 1.1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media screen and (max-width: 480px) {
    .queueRightContent {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 480px) {
    .queueLeftContent {
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {
    .queueLeftContent {
        padding-bottom: 15px;
        margin-bottom: 10px;
    }
    .orderTotal, .headerTotal{
        min-width: 200px;
        font-size: 95%;
    }
}

.claimSummaryTable tbody tr td a:not([href]), .claimSummaryTable tbody tr td a:not([href]):not([tabindex]):hover {
    color: var(--LinkColor);
}

.claimSummaryTable td{
    vertical-align: middle;
}

.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -15px;
}

.buttonContainer button {
   margin-left: 10px;
}

.bold{
    font-weight: bold;
}

.brandColor {
    color: var(--BtnPrimary__Bg);
}


.header {
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerTotal{
    margin-left: 1px;
}

.pendingContractsModalTable td {
    padding-top: 12px;
}

.pendingContractsModalTable td label {
    display: block;
    margin-bottom: 12px;
}
.dropmenu button svg{
    width: 18px;
    height: 21px;
    fill: var(--IconColor);
    position: absolute;
    top: 3px;
}

.cardIcon {
  margin-right: 6px;
  width: 40px;
}
.refundAmount {
    color: #13aa41;
}


.exportContainer {
    left:-70px !important
}


.disableItem {
    background: var(--BtnDisabled__Bg) !important;
    border: var(--BtnDisabled__Border) !important;
    color:  var(--BtnDisabled__Text)!important;
}

.reportsCounterClient {
  color: var(--PrimaryBrandColor) !important;
}

.dayAlert {
  color: red;
}

.claimsHeader {
    position: relative;
    max-width: 80px;
}

/* === Report Search button css === */
.filterSearchIcon {
    height: 17px;
    fill: var(--IconColor);
    position: absolute;
    right: 9px;
    top: 9px;
    width: 17px;
    cursor: pointer;
}

.filterCloseIcon {
    position: absolute;
    height: 10px;
    width: 10px;
    top: 14px;
    right: 40px;
    fill: var(--IconColor);
    cursor: pointer;
}

.searchIconContainer {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--PrimaryBrandColor);
    width: 35px;
    height: 35px;
    border-bottom-right-radius: var(--Btn__BorderRadius);
    border-top-right-radius: var(--Btn__BorderRadius);
}
.searchIconContainer svg {
    fill: #FFF;
}
.customSearchBorder {
    border-radius: var(--Btn__BorderRadius) !important;
}
.search-input .form-control, .search-input input {
    border-radius: var(--Btn__BorderRadius) !important;
}
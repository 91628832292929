@import url('../../../css/VARIABLES.css');

.badge {
    background: var(--Color__Neutral2);
    color: var(--Heading__Color);
    height: 16px;
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11.5px;
    margin-left: 1rem;
    font-size: 16px;
}

.amount {
    font-weight: 600;
    margin-left: 8px;
}
@media screen and (max-width:767px) {
    .badge {
        margin-left:0px !important;
        margin-bottom:10px !important;
        width:fit-content !important;
    }  
}

@media screen and (min-width:320px) and (max-width:568px)
{
  .badge {
    padding-left: 15px;
    padding-right: 15px;
  }
}
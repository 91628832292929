@media screen and (min-width: 768px) and (max-width: 991px) {
    .transDateHeader::after {
        left: 83% !important;
        top: 56% !important;
    }
}

@media screen and (max-width: 767px) {
    .transDateHeader::after {
        top: 56% !important;
        left: 46% !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .transDateHeader::after {
        left: 58% !important;
    }
}

.transDateHeader {
    position: relative;
}

.transDateHeader::after {
    content: '';
    background-image: url("../../assets/images/blue-arrow.svg");
    height: 11px;
    width: 11px;
    position: absolute;
    top: 37%;
    background-repeat: no-repeat;
    right: 10px;
    /* transform: rotate(180deg); */
}

.ascHeader::after {
    content: '';
    background-image: url("../../assets/images/blue-arrow.svg");
    height: 11px;
    width: 11px;
    position: absolute;
    top: 37%;
    background-repeat: no-repeat;
    right: 10px;
    transform: rotate(180deg);
}

.transactionsTable table {
    margin-bottom: 0px;
}

.transAmount {
    color: #13AA41;
}

.refundAmount {
    color: #13aa41;
}
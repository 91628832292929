@import url('../../css/VARIABLES.css');

/* .formBody {
    margin-top: 22px;
} */

.badgeAlert {
    padding: 10px 20px;
    border-radius: 10rem;
    font-size: 16px;
    background-color: var(--AdvantageAccentColor);
    color: var(--Advantage_TextColor);
    margin-left: 0.5rem;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.docsReq {
    padding-left: 20px;
}

.infoIcon {
    width: 16px;
    height: 16px;
    fill: var(--IconColor);
    margin-left: 5px;
    position: relative;
    top: -2px;
}

.tooltiptext {
    width: 350px;
    left: -30px;
    top: 125%;
    font-size: 14px;
}

.tooltiptext::after {
    top: -10%;
}

.dropzone {
    min-height: 152;
    border: 1.5px dashed var(--Color__Neutral3);
    border-radius: 6px;
    padding: 24px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.mobileUpload {
    display: none;
}

.uploadImage svg {
    height: 7rem;
    width: 7rem;
}

.dzMessage {
    margin: auto 0 auto 21px;
    color: var(--Heading__Color);
    font-size: 18px;
}

.dzMessage label {
    color: var(--SecondaryBrandColor);
}

.fileTypes,
.fileSize {
    color: var(--PrimaryTextColor);
    font-size: 14px;
}

.fileSize {
    margin-top: 24px;
}

.uploadProgress {
    border: 1px solid var(--Color__Neutral3);
    padding: 12px 12px 12px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    max-width: 425px;
    margin-bottom: 24px;
}

.file {
    width: 27px;
    height: 34px;
    fill: var(--IconColor);
    margin: 9px 5px 0 0;
}

.fileName {
    margin: auto 0;
    padding-right: 24px;
}

.progressWrapper {
    margin: 0 8px;
    width: 100%;
}

.progress {
    width: 100%;
    height: .45rem;
    background-color: var(--Color__Neutral4);
}


/* .progressBar, .progress-bar {
    background-color: var(--IconColor);
    width: 25%;
    border-radius: .25rem;
} */


/* .progressBarWrapper {
    margin-left: .50rem;
    width: 75%;
} */

.uploadProgressWrapper {
    display: flex;
    align-items: center;
}

.closeButton {
    background-color: transparent;
    border: none;
    background: url('../../assets/images/close.svg');
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    margin: 0 0 0 8px;
    align-self: center;
}

.uploadPercentage {
    font-size: 12px;
    color: #8AA1B9;
}

.uploadPercentage span {
    color: var(--IconColor);
}


/* .ml15 {
    margin-left: 15px;
} */


 .browseLink {
    color: var(--LinkColor) !important;
    font-weight: 600;
    cursor: pointer;
} 


/* .container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
} */

.btmLine {
    border-bottom: 1px solid var(--Color__Neutral3);
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.submitBtn {
    width: 134px;
}


@media (max-width: 767px) {
    .submitBtn {
        width: 102px;
    }
    .fileSize {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    .dzMessage {
        margin: 1rem 0 0 0;
    }
    .mobileUpload {
        border: 1px solid var(--Color__Neutral3);
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 24px;
    }
    .dropzone {
        display: none;
    }
    .badgeAlert {
        margin-left: 0rem;
    }
    .uploadProgress {
        max-width: 100%;
    }
}
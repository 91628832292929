.btnWrapper {
    margin-right: 8px;
    padding: 5px 20px;
    border-radius: 0.25rem !important;
}

.verticalAlign {
    display: inline-table;
    -webkit-box-flex: 0;
    max-width: 20%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
}

.preferredServicerStyle {
    display: inline-table !important;
    padding-left: 0px !important;
    margin-left: -.5rem;
    margin-right: .5rem;
}

.alignLabel p {
    margin-bottom: 0px;
}

.header {
    padding: 24px 28px;
}

.inputLabel label {
    display:block;
    margin-bottom: 0px;
}

@media  (max-width: 768px) {
    .verticalAlign {
        min-width: 100%;
    }
}
.inputWrapper input {
    margin-top: 3px;
}
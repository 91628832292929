@import url('../../css/VARIABLES.css');

.tableTitle {
    background-color: var(--Color__Neutral3);
    color: var(--Heading__Color);
    height: 38px;
    display: flex;
    align-items: center;
}

.tableTitle h4 {
  margin-bottom: 0
}
.detailsTableAlignment{
  table-layout: fixed;
}
.tableCellWithTooltip {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.35rem;
}

.iconInfo{
  margin-top:-5px;
}

.iconInfo span span{
  width:18rem !important;
}

.alignText{
    text-align: left;
}

.buttonAlign{
    display: flex;
    justify-content:flex-end;
}

.footerAlign{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.coverageSummary {
    text-align: center;
    border-left: 1px solid var(--Color__Neutral2);
    border-bottom: 1px solid var(--Color__Neutral2);
    height: 77px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.coverageSummary:last-child {
  border-right: 1px solid var(--Color__Neutral2);
}

@media screen and (max-width: 991px){
  .coverageSummary:nth-child(2) {
    border-right: 1px solid var(--Color__Neutral2);
  }
}

.coverageSummary p, .coverageSummary h6 {
  margin-bottom: 0;
  color: var(--PrimaryTextColor);
}

.contactApple {
    font-size: 14px;
    font-weight: normal;
    color: var(--PrimaryTextColor);
    float: right;
    margin: 5px 0 0;
}

.contactApple span {
    color: var(--ErrorColor);
}

.fileClaim {
    min-width: 89px;
    margin-left :0px !important;
}

.tableAlignment td{
  vertical-align: middle !important;
}
/* Duplicated in AgentPortal under ClaimsQueue.module.css */
.popper {
  opacity: 1;
  width: 400px;
  max-width: 98vw;
}
.tooltip {
  color: var(--PrimaryTextColor);
  background-color: white;
  font-size: 13px;
  padding: 16px;
  opacity: 1 !important;
  border: 1px solid var(--Color__Neutral2);
  text-align: left;
}

.tooltip::after {
  border-top-color: white !important;
}

.tooltipArrow::before, 
.tooltip.arrow::before {
  border-top-color: var(--Color__Neutral2) !important;
  border-bottom-color: var(--Color__Neutral2) !important;
}

.pppOptionDisabled{
  pointer-events: none;
  background-color: var(--InputDisabled__Bg);
  color: var(--InputDisabled__Text) !important;
}
@import url('../../css/VARIABLES.css');

/*Includes all the styling of the Resources tab - Styles can be added based on requirement*/

/* Resource Container Styles */
.headDescription {
    font-size: 16px;
}

#topNav{
    background: none;
    font-size: 14px;
    color: var(--PrimaryTextColor);
    border-bottom: 1px solid  var(--Color__Neutral2);
}
.nav{
    border-bottom: none;
    margin:0px !important;
}
.nav ul{
 
    padding: 1.0rem  1.0rem 0  ;
    cursor: pointer;
}
.nav ul li{
    padding-inline-start:0px !important;
}
.nav ul li{
    display:flex;
    flex-wrap:nowrap;
    list-style-type:none;
    font-weight: 600 !important;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.ActiveTopNav{
    border-bottom: 4px solid var(--ActiveTab) !important;
    color:var(--ActiveTab__Text);
}

/*MainTabContent Styles*/
.sideNav{
        border-right:  1px solid  var(--Color__Neutral2);
        padding-right: 0% !important;
        height:auto;
}
.sideNav ul{
        padding-inline-start:0px !important;
}
.sideNav ul li{
        list-style-type: none;
        border-bottom:1px solid var(--Color__Neutral2);
        padding:14px 14px;
        padding-inline-start: 17px !important;
        color: var(--PrimaryTextColor);
        cursor:pointer;
}

.activeSideTab{
        background-color: var(--ResourceSideTab__ActiveBg) !important;
        border:1px solid var(--ResourceSideTab__ActiveBorder) !important;
        color: var(--Heading__Color) !important;
        padding-left:4px;
}

/*Content panel layout styles*/

.btnDiv{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.screenHeader .btnDiv button:last-child {
    margin-left:16px;
}

.downloadHov button:hover, 
.downloadHov button:active,
.downloadHov button:focus,
.downloadHov button:visited {
    /* color: var(--BtnSecondary__Text) !important;
    background: transparent !important;
    border: var(--BtnSecondary__HoverBorder) !important;
    box-shadow: none !important; */
}

.downloadHov button:hover svg {
    /* fill: var(--BtnSecondary__Text);
    stroke: var(--BtnSecondary__Text); */
}

.downloadHov .iconBtn svg:hover {
    fill: var(--BtnSecondary__HoverText);
    stroke: var(--BtnSecondary__HoverText);
}
.screenHeader{
    display:flex;
    justify-content: space-between;
}

.screenHeader button{
    font-size: 12px !important;
}

@media screen and (min-width:100px) and (max-width:767px) {
    .pdfDiv{
        display:none;
    }
    .screenHeader {
        display: flex;
        flex-direction: column;
        padding-left: 2px;
    }
    .screenHeader .btnDiv button:last-child {
        margin-left:0px;
    }
    .btnDiv{
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    .btnDiv button{
        padding: 10px 15px;
        margin: 4px 0px;
        width: 100%;
    }
    .sideNav ul{
      margin-bottom: 0px !important;
      background-color: var(--Color__Neutral2);
    }
    .showSm {
      display: none !important;
    }
    .sideNav ul li{
      background-color: #FFFFFF;
      border: 1px solid #B9C6D4;
      margin-bottom: 5px;
    }
    .sideNav ul li:last-child{
      margin-bottom: 0;
    }
    .sideNav ul li:hover {
      background-color: var(--Color__Neutral2);
    }
}

.showSm {
  display: flex;
}

.iconContainer svg {
  height: 20px;
  width: 20px;
  fill: var(--IconColor) !important;
}

.pdfDiv > div > div > div > div {
    overflow-x: hidden !important;
}
.button {
    background-color: transparent;
    border: none;
    color: var(--PrimaryTextColor);
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    width: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0rem;
}

.icon {
    height: 16px;
    width: 16px;
    fill: var(--IconColor);
}

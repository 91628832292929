@import url('../css/VARIABLES.css');

.page-item.active .page-link {
    background-color: var(--ActiveTab);
    border-color: var(--ActiveTab);
    z-index: 0 !important;
}

.page-link:hover {
    border-color: none !important;
    z-index: 0;
    text-decoration: none;
    background-color: none!important;
    border-color: none!important;
}

.page-link:focus {
    box-shadow: none!important;
}

.react-bootstrap-table-pagination-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--Heading__Color);
    background-color: #fff;
    
}

.page-item:first-child .page-link {
    margin-left: 140px!important;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}


.react-bootstrap-table-pagination-list-hidden {
    display: none !important;
}
.enrollmentIcon svg {
    height: 190px;
    width: 190px;
  }

.enrollmentIcon {
    display: flex;
    justify-content: center;
}

@media (min-width: 576px) {
  .container {
    max-width: 50%;
    margin: auto;
  }
}

@media (max-width: 576px) {
  .uploadBtn {
    width: 100%;
  }
}
@import url('../../../css/VARIABLES.css');

.wrap {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .dot {
    background-color: #343741;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    animation: fade 0.75s linear infinite alternate;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.25s;
  }
  .dot:last-child {
    animation-delay: 0.5s;
  }
  
  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
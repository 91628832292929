@import url('../../../css/VARIABLES.css');

@media screen and (max-width: 374px) {
    .coverageTerm .radioCylinder:last-child {
        padding-top: 0.5rem !important;
    }

    .coverageTerm .disabledRadioBtn:last-child {
        padding-top: 0.5rem !important;
    }
}

.marginAdj {
    margin: 0 -.5rem;
}

.reimburseInfoIcon {
    fill: var(--IconColor);
    height: 18px;
    width: 18px;
}

.reimburseInfoPopover {
    max-width: 540px;
    border: 1px solid var(--Color__Neutral2);
    -webkit-box-shadow: 0 12px 24px 5px rgba(0, 87, 184, 0.06);
    box-shadow: 0 12px 24px 5px rgba(0, 87, 184, 0.06);
    padding: 0;
    top: -4px !important;
    font-family: var(--TextFont);
}

    .reimburseInfoPopover div h3 {
        background-color: #fff;
        font-size: 18px;
        padding: 24px 0.75rem;
    }

.reimbursePopFooter {
    display: flex;
    justify-content: space-between;
}

    .reimbursePopFooter .btnLink {
        color: var(--LinkColor);
        font-size: 12px;
    }

@media screen and (max-width: 991.99px) {
    .planOptions {
        margin-bottom: 24px;
    }

        .planOptions:last-child {
            margin-bottom: 0;
        }
}

.reimbursePlanType {
    font-weight: 600;
    text-align:left;
}
.reimbursePlanTable table th:first-child {
    text-align:left ;
}

/* .reimburseOption {
  padding: 0 .5rem !important;
} */

.reimburseOption svg {
    height: 18px;
    width: 18px;
    fill: var(--IconColor)
}

.reimbursePlanTable table {
    border: 1px solid var(--Color__Neutral2) !important;
}


/* Radio Buttons */

.radioCylinder input[type="radio"]:checked + label {
    background-color: var(--RadioWithBg__CheckedBg);
    border: 1px solid var(--RadioWithBg__CheckedBorder);
    color: var(--RadioWithBg__CheckedText);
}

.disabledRadioBtn input[type="radio"]:checked + label {
    background-color: var(--InputDisabled__Bg);
    border: 1px solid var(--InputDisabled__Border);
    color: var(--InputDisabled__Text);
}

.controlsLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);;
    cursor: pointer;
    border-radius: 101px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    padding: 0rem 1rem 0rem 1rem;
    position: relative;
    align-items: center;
    border: 1px solid var(--RadioWithBg__Border);
    margin-bottom: 0rem;
}

.radioCylinder input[type=radio]:checked + .controlsLabel:after {
    background: var(--RadioButton__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 8px;
    position: absolute;
    top: 5px;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    width: 9px;
}

.disabledRadioBtn input[type=radio]:checked + .controlsLabel:after {
    background: var(--InputDisabled__Bg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 8px;
    position: absolute;
    top: 19%;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    width: 9px;
}

.controlsLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 50%;
    border: 1px solid var(--RadioWithBg__Border);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 31px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5px 5px 5px -8px;
    max-width: 18px;
    transition: background .3s ease;
    width: 24px;
}

.radioCylinder input[type=radio]:checked + .controlsLabel:before {
    border-color: var(--RadioButton__CheckedBorder);
}

.disabledRadioBtn input[type=radio]:checked + .controlsLabel:before {
    border-color: var(--InputDisabled__Border);
}

.disabledRadioBtn input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radioCylinder input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radioCylinder {
    position: relative;
    margin-right: 0.5rem;
}

.disabledRadioBtn {
    position: relative;
    margin-right: 0.5rem;
}

.radioCylinder:last-child {
    margin-right: 0;
}

.disabledRadioBtn:last-child {
    margin-right: 0;
}

.radioCylinder:last-child {
    margin-right: 0;
}

.addBenefits {
    display: flex;
    margin-left: 41px;
    margin-top:5px;
    border-top: none !important;
}

@media screen and (max-width:410px) {
    .addBenefits {
        margin-left: 20px;
    }
}

.addBenefits svg {
    height: 12px;
    width: 12px;
    fill: var(--IconColor);
}

.borderCol, .borderCol::before{
    border: 1px solid var(--ErrorColor) !important;
}


@import url('../../../css/VARIABLES.css');

.saveCardHeader {
  display: flex;
  border-bottom: 1px solid var(--Color__Neutral2);
  justify-content: space-between;
}

.headingColor {
  color: var(--PrimaryBrandColor);
}

.headingFontStyle {
  font-size: var(--FontSize);
  font-weight: bold;
}

.btnAlign {
  display: flex;
  justify-content: flex-end;
}

.downloadFormContainer {
  border-radius: 3px !important;
  padding: 4px 8px 4px 4px;
}

.downloadFormContainer:focus {
  background: transparent !important;
  color: var(--BtnSecondary__Text) !important;
}

.countryIcon {
  display: flex;
}

.countryIcon svg {
  margin-right: 5px;
  height: 24px;
  width: 28px;
  margin-top: 0;
  padding-top: 0;
}

.countryIcon span {
  margin-top: 2px;
}

.iconContainer svg{
  width: 18px;
  fill: var(--IconColor);
  stroke: var(--IconColor)
}
.emailContainer ul, 
.mailContainer ul, 
.faxContainer ul, 
.phoneContainer ul {
  list-style: none;
  font-weight: normal;
}

.emailContainer ul li:nth-child(2) {
  font-weight: bold;
}

.lapIcon svg {
  width: 18px
}

@media (max-width: 576px) {
  .alignVertical {
    display: block;
    margin-right: 0px;
  }
  .downloadFormContainer {
    width: 100%
  }
}
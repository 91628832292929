@media screen and (max-width: 767px) {
    .anotherSaleBtn button {
        margin-bottom: 1rem;
        width: 100%;
    }
    .homeBtn {
        width: 100%;
    }
    printConfirm {
        justify-content: center !important;
        margin-bottom: 1rem;
    }
    .thanksConfirmation p:first-of-type {
        text-align: center;
    }
    .printBtn {
        position: relative !important;
        right: 0% !important;
    }
    .finalContractsTable div {
        display: block !important;
    }
    .finalContractsTable table {
        width: 100% !important;
    }
    .finalContractsTable{
        width: 100% !important;
    }
    .succuessIcon svg {
        height: 120px !important;
    }
}

@media screen and (max-width: 524px) {
    .planNameHeader::after {
        top: 68% !important;
    }
}

@media screen and (min-width: 525px) and (max-width: 767px) {
    .planNameHeader::after {
        top: 57% !important;
    }
}

.thanksConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.thanksConfirmation h1 {
    margin-bottom: 8px;
}

.thanksConfirmation p {
    margin-bottom: 24px;
}

.finalContractsTable table thead tr {
    border-bottom: none !important;
}

.finalContractsTable table tbody tr:first-child {
    border-top: none !important;
}

.finalContractsTable table {
    margin-bottom: 1rem;
}

.finalContractsTable table tbody tr td {
    vertical-align: middle !important;
}

.columnContractNum {
    color: #1352DE !important;
}

.anotherSaleBtn button:first-of-type {
    margin-right: 1rem;
}

.covCertificateHeader,
.covCertificate {
    text-align: center;
}

.succuessIcon svg {
    height: 200px;
    margin-bottom: 2rem;
}

.printConfirm {
    display: flex;
    justify-content: flex-end;
}

.finalContractsTable{
    width: 72%;
    margin: auto;
}

.textClr{
    color: var(--BtnPrimary__Bg);
    font-weight: 600;
}
@import url('../../css/VARIABLES.css');
@import url('../../css/typography.css');


@media screen and (max-width: 767px) {
    .thanksConfirmation {
        text-align: center;
    }
}

.thanksConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thanksConfirmation svg {
    margin: 1.5rem 0;
    width: 100%;
    max-width: 176px;
}

.reviewConfirmation svg {
    max-width: 250px !important;
}
.invMessage {
    margin: 1.0rem auto 0.8rem;
    text-align: center;
}

.invMessage p {
    margin: 0 auto 16px;
}

a.redirectLink:not([href]), a.redirectLink:not([href]):hover {
    color: var(--LinkColor);
}

@media (max-width: 1410px) {
    .invMessage p {
        max-width: 600px;
    }
}


.btnWrapper {
    align-items: center;
}

.breadcrumb li:first-child:before {
    margin-right: 0px;
}

@media (max-width: 576px) {
  
    .continueBtn {
        margin-top: 0.5rem;
    }

    .continueBtn span {
        justify-content: center;
    }

    .saveBtn {
        width: 100%;
    }

    .btnWrapper {
        flex-direction: column;
        justify-content: center;
        margin-top: 0.5rem;
    }
}


@import url("../../css/VARIABLES.css");

.confirmPassCheck {
    display: flex;
    align-items: center;
}
.confirmPassCheck svg {
    height: 9px;
    width: 10px;
}
  
.confirmPassColor {
    fill: #13AA41;
}
.closePassColor {
    fill: var(--ErrorColor);
}

.confirmPassCheckText {
    background: url("../../assets/images/Check_icon.svg") no-repeat left;
    padding-left: 25px;
    color: var(--PrimaryTextColor);
    font-size: 14px;
    padding-top: 3px;
}

.brandColor{
    color: var(--PrimaryBrandColor);
}

.confirmPassHeadText {
    font-weight: bold;
}
  

.iconStyle {
    fill: var(--IconColor);
}

.header {
    padding: 24px;
}

.tooltiptext {
    width: 400px;
}

@media (max-width: 576px) {
    .tooltiptext {
      left: 16px !important
    }
  }

@media (min-width: 992px){
    .passwordEmailWrapper {
        max-width: 40% !important
    }
}



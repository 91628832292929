.anotherSaleBtn button:first-of-type {
    margin-right: 1rem;
}

.iconBtn svg {
    fill: var(--IconColor);
    stroke: var(--IconColor);
    height: 16px;
    margin-right: 8px;
}

.iconBtn:hover svg {
    fill: var(--BtnSecondary__HoverText) !important;
    stroke: var(--BtnSecondary__HoverText) !important;
}

.addIcon{
    height: 11px;
    width: 11px;
}
@import url("../../css/VARIABLES.css");

.searchBtn, .searchBtn:hover, .searchBtn:visited, .searchBtn:focus {
    color: #ffffff !important;
}
.infoBox {
    border: 1px solid var(--ScriptBox__Border);
    border-radius: 6px;
    background: var(--ScriptBox__Bg);
}
.searchText {
    text-align: center;
    font-weight: bold;
    min-height: 100px;
}
.searchText label {
    margin-top: 30px;
}
.copyIcon svg {
    width: 16px;
    height: 16px;
}
.copy {
    cursor: pointer;
}
.pricingTable tr th:last-child,
.pricingTable tbody tr td:last-child {
    border-left: 1px solid var(--Color__Neutral2);
}

.pricingTable{
    display: block;
    width:100%;
}
.rowDrdopdown div[class^="autoDrop__single-value"] {
    padding-right: 0px !important;
}

.rowDrdopdown div[class^="autoDrop__control"] {
    width: 48% !important;
}

.export {
    color: var(--LinkColor);
    font-size: 14px;
    padding: 10px;
    border: 0;
    cursor: pointer;
    text-align: right;
}
.export svg {
    fill: var(--LinkColor);
    width: 18px;
}
.retailType {
    top: -3px !important;
}
.retailTypeInfoText {
    width: 39rem;
    white-space: "pre-line";
}
.priceTableTopContainer {
    display: flex;
    justify-content: flex-end;
}
.priceTableTopContainer div:nth-child(1) {
    font-weight: bold;
    padding-top: 8px;
    width: 100px;
}
.priceTableTopContainer div:nth-child(3) {
    width: 90px;
}

.descending:after {
    content: ' ';
    background-image: url("../../assets/images/arrow-down.svg");
    color: var(--SecondaryBrandColor);
    padding: 0;
    background-color: transparent;
    width: 16px !important;
    background-repeat: no-repeat;
    margin-left: 3px;
    top: 20px;
    position: absolute;
    height: 30px;
    background-size: 60%;
}
.ascending:after {
    content: ' ';
    background-image: url("../../assets/images/arrow-down.svg");
    color: var(--SecondaryBrandColor);
    padding: 0;
    background-color: transparent;
    width: 16px !important;
    background-repeat: no-repeat;
    margin-left: 1px;
    top: 0;
    position: absolute;
    transform: rotate(-180deg);
    height: 30px;
    background-size: 60%;
}
.customDropDownItem a {
    color: #FFFFFF !important;
    text-decoration: none !important;
}
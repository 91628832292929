@import url('./VARIABLES.css');

/* =============================================================================
  Tables
============================================================================= */

table,
.table {
    font-size: 14px;
    color: var(--PrimaryTextColor);
    margin-bottom: 1rem;
    border-left: 1px solid var(--Color__Neutral2);
    border-right: 1px solid var(--Color__Neutral2);
}

    table thead th {
        border-bottom: none;
        font-weight: 600;
    }

    table td {
        border-color: var(--Color__Neutral2);
    }

    table tr {
        border-top: 1px solid var(--Color__Neutral2);
    }

        table tr:last-child {
            border-bottom: 1px solid var(--Color__Neutral2);
        }

    table.borderless,
    .table.borderless,
    table.borderless tr,
    table.borderless thead th,
    table.noBorder tr,
    table.noBorder thead th,
    table.noBorder th,
    table.noBorder td,
    .table.noBorder {
        border: none;
        border-left: none;
        border-right: none;
    }

    .tableStriped tr:nth-child(even),
    table tr.active {
        background-color: var(--Table__RowBg);
    }

    .table .controls-label {
        margin-bottom: 0;
    }

td.expand-cell {
    padding: 0;
}

th.expand-cell-header {
    color: #fff;
    pointer-events: none;
}

th.radioHeading {
    padding-left: 2.4rem;
}

th.sortable {
    position: relative;
}

td.expand-cell {
    /*border-left: 1px solid var(--Color__Neutral2);*/
    text-align: center;
}

th.expand-cell-header {
    color: #fff;
}


/*
th.ascending:after, th.descending:after {
    content: ' ';
    background-image: url(../assets/images/arrow-down.svg);
    color: #0057B8;
    position: relative;
    padding: 0px 0px 0px 10px;
    background-color: transparent;
    width: 8px !important;
    background-repeat: no-repeat;
    margin-left: 4px;
    top: .20rem;
  transform: rotate(-30deg);
}

th.descending:after {
  -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
}
*/

.ascending:after {
    content: ' ';
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZmlsbD0iYmx1ZSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNy42NCAxMC4xNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7Y2xpcC1ydWxlOmV2ZW5vZGQ7fS5jbHMtMntjbGlwLXBhdGg6dXJsKCNjbGlwLXBhdGgpO30uY2xzLTN7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMzcuMjQgMjEuNjEgMTM3LjI0IDAuMDIgMTM1LjI0IDAuMDIgMTM1LjI0IDIxLjYxIDEyOC45NSAxNS4zMiAxMjcuNTQgMTYuNzMgMTM2LjI0IDI1LjAyIDE0NC45NSAxNi43MyAxNDMuNTQgMTUuMzIgMTM3LjI0IDIxLjYxIi8+PC9jbGlwUGF0aD48L2RlZnM+PHBvbHlnb24gY2xhc3M9ImNscy0zIiBwb2ludHM9IjcuNjQgNi4zMyA3LjE0IDUuODIgNC4xOCA4Ljc4IDQuMTggMC4wMSAzLjQ2IDAuMDEgMy40NiA4Ljc4IDAuNTEgNS44MiAwIDYuMzMgMy44MiAxMC4xNSA3LjY0IDYuMzMiLz48L3N2Zz4=);
    color: var(--SecondaryBrandColor);
    padding: 0px 0px 0px 10px;
    background-color: transparent;
    width: 8px !important;
    background-repeat: no-repeat;
    margin-left: 4px;
    top: 17px;
    position: absolute;
    height: 12px;
}

.descending:after {
    content: ' ';
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZmlsbD0iYmx1ZSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNy42NCAxMC4xNiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7Y2xpcC1ydWxlOmV2ZW5vZGQ7fS5jbHMtMntjbGlwLXBhdGg6dXJsKCNjbGlwLXBhdGgpO30uY2xzLTN7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMzcuMjQgMjEuNjEgMTM3LjI0IDAuMDIgMTM1LjI0IDAuMDIgMTM1LjI0IDIxLjYxIDEyOC45NSAxNS4zMiAxMjcuNTQgMTYuNzMgMTM2LjI0IDI1LjAyIDE0NC45NSAxNi43MyAxNDMuNTQgMTUuMzIgMTM3LjI0IDIxLjYxIi8+PC9jbGlwUGF0aD48L2RlZnM+PHBvbHlnb24gY2xhc3M9ImNscy0zIiBwb2ludHM9IjcuNjQgNi4zMyA3LjE0IDUuODIgNC4xOCA4Ljc4IDQuMTggMC4wMSAzLjQ2IDAuMDEgMy40NiA4Ljc4IDAuNTEgNS44MiAwIDYuMzMgMy44MiAxMC4xNSA3LjY0IDYuMzMiLz48L3N2Zz4=);
    color: var(--SecondaryBrandColor);
    padding: 0px 0px 0px 10px;
    background-color: transparent;
    width: 8px !important;
    background-repeat: no-repeat;
    margin-left: 3px;
    top: 17px;
    transform: rotate(-180deg);
    position: absolute;
    height: 12px;
}


.rheemAscending:after {
  content: ' ';
  background-image: url('../assets/images/Vendor/Rheem/arrow-down.svg');
  color: var(--SecondaryBrandColor);
  padding: 0px 0px 0px 10px;
  background-color: transparent;
  width: 8px !important;
  background-repeat: no-repeat;
  margin-left: 4px;
  top: 17px;
  position: absolute;
  height: 12px;
}

.rheemDescending:after {
  content: ' ';
  background-image: url('../assets/images/Vendor/Rheem/arrow-down.svg');;
  color: var(--SecondaryBrandColor);
  padding: 0px 0px 0px 10px;
  background-color: transparent;
  width: 8px !important;
  background-repeat: no-repeat;
  margin-left: 3px;
  top: 17px;
  transform: rotate(-180deg);
  position: absolute;
  height: 12px;
}

.table.ltHead th {
    font-weight: 600;
}

@media screen and (max-width: 997px) {
    .descending:after, .ascending:after {
        right: 0;
    }
}

/*Small Down*/

@media screen and (max-width: 767.99px) {
    .table-responsive {
        border-left: 1px solid var(--Color__Neutral2);
        border-right: 1px solid var(--Color__Neutral2);
    }

        .table-responsive table,
        .table-responsive .table {
            border-left: none;
            border-right: none;
        }
}

@import url('../../css/VARIABLES.css');

/* =============================================================================
  Header
============================================================================= */

.logoBar {
    height: 100%;
    /* display: inline-flex; */
    vertical-align: top;
    justify-content: space-between;
    align-items: center;
}

.userName {
    vertical-align: middle;
}

.header {
    background-color: white;
    border-bottom: 1px solid var(--Color__Neutral2);
    display: flex;
    height: 42px;
}

.aigIcon {
    height: 18px;
}

.lennoxIcon {
    height: 18px;
    padding-left: 18px;
}

.rheemIcon {
    width: 125px;
    padding-top: 5px;
}

.carrierIcon {
    width: 120px;
    padding-top: 0;
}
.traneIcon {
  width: 125px;
  padding-top: 0;
}
.geIcon {
  width: 62px;
  padding-top: 0;
}

.icpIcon {
    height: 26px;
    padding-top: 0;
  }

.hdIcon {
    height: 23px;
    padding-top: 0;
}

.staplesIcon {
    height: 26px;
    padding-top: 0;
}
.csLogo {
    height: 22px;
}

.portalName {
    font-size: 14px;
    line-height: 3;
    font-weight: 500;
    padding-left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
}

.leftWrapper {
    display: flex;
    align-items: center;
    height: 100%
}

.iconWrapper {
    display: inline-flex;
    min-width: 50px;
}

.dropdownDisplay {
    left: 45px !important;
}

/*Small devices (landscape phones, 576px and up)*/
@media screen and (max-width: 576px) {
    .portalName {
        display: none;
    }

    .userName {
        vertical-align: baseline;
        line-height: 0px !important;
    }

    .fontsizeDiv {
        line-height: 15px;
    }

    .dropdownDisplay {
        left: -50px !important;
    }

    .dropDownDiv {
        position: relative;
        bottom: 33px;
    }
}

@media screen and (max-width:320px) {
    .leftWrapper .csLogo {
        height: 19px;
    }

    .leftWrapper .lennoxIcon {
        height: 15px;
        padding-left: 9px;
    }

    .header .logoWrapper, .removeLogosPadd {
        padding-right: 5px;
        padding-left: 5px;
    }

    .fontsizeDiv, .iconUser {
        margin-left: -6px;
    }

    .iconArrow {
        margin-left: 4px !important;
    }
}

@media screen and (max-width:375px) {
    .iconArrow {
        margin-left: 4px !important;
    }
}

.header [class^="icon"] {
    fill: #001871;
    display: block;
    margin: auto;
}

.profileWrapper {
    padding: 0;
    padding-right: 15px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconUser {
    height: 21px;
    width: 21px;
    margin-right: 12px;
    fill: var(--MainNav__Icon);
}

.iconArrow {
    height: 12px;
    width: 12px;
    margin-left: 12px;
    margin-right: 12px;
}

.iconBell {
    height: 20px;
    width: 20px;
    fill: var(--MainNav__Icon)
}

.notificationsPop {
    min-width: 482px;
}

.notificationBody {
    padding: 0px !important;
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: var(--TextFont) !important;
}

.notification {
    position: relative;
}

    .notification span {
        position: absolute;
        left: -2px;
        top: -2px;
    }

.notificationsPop .arrow::after {
    border-bottom-color: var(--SecondaryBrandColor) !important;
}

.notificationsPop::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    right: 226px;
    top: -12px;
    border-left: 1px solid var(--SecondaryBrandColor);
    border-top: 1px solid var(--SecondaryBrandColor);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: var(--SecondaryBrandColor);
}

.notificationPopHeader::before {
    border-bottom: 1px solid var(--SecondaryBrandColor) !important;
}

.notificationBodyCover {
    padding: 8px 8px 8px 24px;
    font-family: var(--TextFont) !important;
}

.notificationPopHeader {
    padding: 16px 24px;
    color: white;
    font-family: var(--TextFont);
    font-size: 24px;
    font-weight: 600;
    background: var(--SecondaryBrandColor);
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.5px;
}

    .notificationPopHeader h3 {
        color: white;
        margin-bottom: 0px;
    }

.noNotifications {
    min-width: 482px;
    font-family: var(--TextFont) !important;
}

.notificationPopHeader span {
    display: flex;
    align-items: center;
}

.closeNotification svg {
    height: 18px;
    width: 18px;
    fill: white;
}

.notificationBody::-webkit-scrollbar {
    width: 8px;
    margin: 8px 0px;
}

.notificationBody::-webkit-scrollbar-track {
    box-sizing: border-box;
    width: 8px;
    border: 1px solid var(--Color__Neutral3);
    border-radius: 7px;
    background-color: var(--Color__Neutral1);
}

.notificationBody::-webkit-scrollbar-thumb {
    height: 107.33px;
    width: 8px;
    border-radius: 7px;
    background-color: var(--Color__Neutral3);
}

/* =============================================================================
  Main Nav
============================================================================= */

.mainNavWrapper {
    background: linear-gradient(to bottom, var(--Color__Neutral1) 0, #fff 100%);
}

nav.mainNav {
    display: inherit;
    height: 57px;
    width: 100%;
    margin-bottom: 0;
}

.navLink:not([href]):not([tabindex]) {
    display: flex;
    align-items: center;
    height: 100%;
    border-right: 1px solid var(--Color__Neutral2);
    letter-spacing: 0.56px;
    color: #717171;
    font-size: 18px;
    padding: .5rem 1rem;
}

.navLink.active:not([href]):not([tabindex]) {
    background-color: var(--Color__Neutral2);
    color: var(--MainNav__TextColor);
    border-radius: 0px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.56px;
    line-height: 39px;
    border: 0;
}

.navLink:hover {
    border-radius: 0;
    border-right: 1px solid var(--Color__Neutral2);
}

.mainNavBtn {
    background: transparent;
    border: none;
    border-right: 1px solid var(--Color__Neutral2);
    border-radius: 0;
    height: 57px;
    width: 66px;
}

    .mainNavBtn:hover {
        background-color: var(--Color__Neutral2);
    }

.closeTab {
    height: auto;
    width: auto;
    border: 0;
    padding: 0 0 0 16px;
}

.iconClose {
    height: 14px;
    width: 14px;
    fill: #717171;
    stroke: #717171;
    stroke-width: 1;
}

.navLink.active .iconClose {
    fill: var(--MainNav__ActiveColor);
    stroke: var(--MainNav__ActiveColor);
}

.search {
    composes: mainNavBtn;
    right: 66px;
    position: absolute;
    border-left: 1px solid var(--Color__Neutral2);
}

.queue {
    composes: mainNavBtn;
    right: 0;
    position: absolute;
    fill: var(--PrimaryBrandColor);
}

.icon {
    fill: var(--MainNav__Icon);
    display: block;
    margin: auto;
}

.iconHome {
    composes: icon;
    height: 20px;
    width: 17.5px;
}

.iconSearch {
    composes: icon;
    height: 23.5px;
    width: 23.5px;
}

.iconQueue {
    composes: icon;
    height: 21.5px;
    width: 24px;
}

.contactUsLink {
    color: var(--PrimaryTextColor);
    margin-left: 12px;
    margin-right: 12px;
}

    .contactUsLink:hover {
        text-decoration: none;
        color: var(--PrimaryTextColor);
    }

/* =============================================================================
  Main
============================================================================= */


/*main {
    position: absolute;
    top: 99px;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background-color: #F0f2f5;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
}*/

.mainContainerWrapper {
    height: 100%;
}


/* =============================================================================
  Secondary Nav
============================================================================= */

.secondaryNavWrapper {
    background-color: var(--Color__Neutral2);
    height: 56px;
}

nav.secondaryNav {
    padding-left: 59px;
    margin-bottom: 1rem;
}

.secondaryNav .navPills .navItem {
    margin-top: 13px;
    margin-left: 9px;
    height: 27px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.44px;
}

.secondaryNavLink {
    border-radius: 13.5px;
    border: 1px solid #1352DE;
    line-height: 9px;
    color: #000;
    padding: .5rem 2rem .5rem 1rem;
    display: block;
    cursor: pointer;
}

.secondaryNavLink.blue {
    background-color: rgba(0, 121, 255, .08);
    padding: .5rem 2rem .5rem 1rem;
}

    .secondaryNavLink.green {
        background-color: #E0EED0;
        border-color: #13AA41;
    }

    .secondaryNavLink.orange {
        background-color: #FFE8C9;
        border-color: #E36F1E;
    }

    .secondaryNavLink.white {
        background-color: #fff;
        border-color: orange;
    }

    .secondaryNavLink.active {
        background-color: #fff;
        border: 1px solid #1352DE;
        color: #1352DE;
        font-weight: 600;
    }

    .secondaryNavLink .closeTab {
        padding: 0 0 0 8px;
        border: 0;
        background: transparent;
        line-height: 0;
        position: absolute;
    }

    .secondaryNavLink .iconClose {
        height: 10px;
        width: 10px;
        fill: #717171;
        stroke: #717171;
        stroke-width: 2;
    }

    .secondaryNavLink.active .iconClose {
        fill: #1352DE;
        stroke: #1352DE;
    }

    .secondaryNavLink.green:not(.active) .iconClose {
        fill: #13AA41;
        stroke: #13AA41;
    }


/* =============================================================================
  Sidebar Nav
============================================================================= */

.sideBar {
    position: absolute;
    top: -61px;
    height: 103%;
    left: 0;
    width: 66px;
    padding-top: 10px;
    background-color: var(--SideNav__Bg);
    max-width: 66px;
    text-align: center;
    z-index: 1;
}

.sideNavLink {
    padding: 16px 0;
    margin-bottom: .5rem;
    /* border-left: 5px solid var(--SideNav__ActiveSideLine); */
    border-radius: 0;
    color: var(--SideNav__TextColor);
}

a.sideNavLink.active,
a.sideNavLink:hover {
    background-color: var(--SideNav__ActiveBg);
    border-left: 5px solid var(--SideNav__ActiveSideLine);
    color: var(--SideNav__ActiveText);
    text-decoration: none;
}


/*hr {
  height: 1px;
  background-color: #6A7580;
  width: 52px;
  border: none;
  margin-top: 7px;
  margin-bottom: 18px;
}*/

.sideBarIcon {
    display: block;
    fill: var(--SideNav__TextColor);
    stroke: var(--SideNav__TextColor);
    margin: 0 auto;
}

a.sideNavLink.active .sideBarIcon,
a.sideNavLink:hover .sideBarIcon {
    fill: var(--SideNav__ActiveText);
    stroke: var(--SideNav__ActiveText);
}

.iconOverview {
    composes: sideBarIcon;
    height: 24px;
    width: 20px;
}

.iconShield {
    composes: sideBarIcon;
    height: 24px;
    width: 18px;
}

.iconTrans {
    composes: sideBarIcon;
    height: 23.8px;
    width: 23.8px;
}

.iconDocs {
    composes: sideBarIcon;
    height: 24px;
    width: 28.8px;
}

.iconEscalate {
    composes: sideBarIcon;
    height: 24px;
    width: 27.7px;
}

.iconTcs {
    composes: sideBarIcon;
    height: 24px;
    width: 18px;
}

.iconPay {
    composes: sideBarIcon;
    height: 24px;
    width: 33.9px;
}

.activeLink {
    background-color: var(--SideNav__ActiveBg);
    border-left: 5px solid var(--SideNav__ActiveSideLine);
    color: var(--SideNav__ActiveText)
}

    .activeLink .sideBarIcon {
        fill: var(--SideNav__ActiveText);
        stroke: var(--SideNav__ActiveText);
    }

.disabledLink {
    color: var(--Color__Neutral4);
    pointer-events: none;
}

    .disabledLink .sideBarIcon {
        fill: var(--Color__Neutral4);
        stroke: var(--Color__Neutral4);
    }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .logoBar {
      justify-content:flex-start;
  }
  .lennoxIcon{
      height: 38px;
      width:80px;
  }
  .csLogo{
      width:90px;
      margin-right:-24px !important;
  }
  .portalName {
      line-height: 1;
  }
  .leftWrapper {
      margin-left:-25px !important;
  }
}

.serviceRequestList {
    font-weight: normal !important;
    color: var(--PrimaryTextColor) !important;
}

.serviceRequestHeader {
    background: #F5F5F5 !important;
    border-bottom: none !important;
}

.serviceRequestHeaderRow {
    padding-bottom: 5px;
    padding-top: 20px;
}

.serviceRequestHeaderCol {
    display: flex;
}

.aigLogo {
    height: 53px;
    width: 98px;
}

.serviceHeaderRight {
    font-size: var(--H6__Size);
    font-weight: var(--Heading__FontWeight);
    color: var(--Heading__Color);
}

.serviceHeaderMiddle p {
    padding-top: 8px;
    color: var(--PrimaryTextColor);
    font-weight: normal;
}

.serviceRequestTable table, .serviceRequestTable .table {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-bottom: 0 !important;
}

.serviceRequestTable tr, .serviceRequestTable td {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--Color__Neutral2);
}

.serviceRequestTable table thead {
    display: none !important;
}

.authorizeTable table {
    margin-bottom: 15px !important;
}

    .authorizeTable table th:first-child {
        border-top: 0px !important;
    }

    .authorizeTable table th {
        font-weight: normal !important;
        text-align: left !important;
    }

    .authorizeTable table td {
        text-align: right !important;
    }

    .authorizeTable table tr:nth-child(even) {
        background-color: inherit !important;
    }

.serviceRequestSeparator {
    border-right: 1px solid var(--Color__Neutral2);
}

.serviceRequestSideButton {
    font-size: 12px !important;
    height: 32px;
    width: 178px;
}

.conditionNumber {
    font-weight: 600;
    color: var(--Heading__Color);
}

.subHeading {
    font-size: var(--H6__Size);
}

.btnServiceAccept {
    position: absolute !important;
    bottom: 0;
    right: 10px;
    min-width: 75px;
}

.btnServiceReject {
    position: absolute;
    bottom: 0;
    right: 100px;
    min-width: 75px;
}

.customLeftCol {
  margin-right: 0;
  padding-right: 0;
  max-width: 66px !important;
}

.copyRight {
    color: var(--Color__Neutral1);
}
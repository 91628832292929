@import url('../../css/VARIABLES.css');
@import url('../../css/typography.css');

.dealersNote {
    display: flex;
    align-items: center;
}

.dealersNote h2 {
    margin-right: 4px;
}

.processingCert p {
    font-size: 1.0rem;
}

.processingCert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.processingCert svg {
    height: 164px;
}

.spinnerCard {
    height: 702px;
}

@media screen and (max-width: 767px) {
    .thanksConfirmation {
        text-align: center;
    }
}

.thanksConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thanksConfirmation svg {
    margin: 1.5rem 0;
    width: 100%;
    max-width: 11rem;
}

.invMessage {
    margin: 1.0rem auto;
    text-align: center;
    padding-bottom: 3rem;
}

.invMessage p {
    margin: 0 auto 1.0rem;
}

@media (max-width: 1410px) {
    .invMessage p {
        max-width: 700px;
    }
}

.wrapperDetails {
    text-align: center;
}

.wrapperDetails>div {
    margin-bottom: 16px;
}

.wrapperTitle {
    font-weight: bold;
    font-size: 14px
}

.wrapperHeader {
    font-size: 14px;
    font-weight: 600;
    margin: 16px auto;
}

.wrapperContainerHeader {
    font-weight: 600;
    font-size: 14px;
    margin: auto;
}

.textContainer {
    font-weight: bold;
    font-size: 11px;
    font-style: italic;
}

.downloadWrapper {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-grow: 1;
}

.productDetailWrapper {
    display: flex;
    flex-direction: column;
    font-size: 9px;
}

.headerWrapper {
    font-size: 29px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.containerWrapper {
    width: 80%;
    font-size: 12px;
}

.horizontalWrapper {
    width: 548px;
    border: 1px solid #000;
}

.addressHeader {
    background-color: #000;
    color: var(--Color__Neutral1);
    font-weight: 500;
    padding: 2px;
    font-size: 12px;
}

.imageWrapper {
    height: 75px;
    margin-bottom: 56px;
}

.cardWrapper {
    font-size: 12px;
    font-family: 'Times New Roman';
    width: 612px;
    margin: auto;
}

.cardWrapper p,
.cardWrapper div {
    color: #000;
}


.dealerImageContainer {
    width: auto;
    height: 54px;
}

.customerImageContainer {
    height: 54px;
    width: 160px;
}

.boxWrapper {
    border: 2px solid #000;
}

.tableWrapper>div {
    padding-left: 0px;
    padding-right: 4px;
}


.wrapperDetails strong {
    font-weight: bold;
    font-size: 11px;
}

.headerContainer,
.imageContainer>div:first-child {
    padding-left: 32px;
}

/* Add this CSS to your stylesheet */

@media only screen and (max-width: 768px) {
    .introWrapper {
        margin-bottom: 8px;
    }

    .contextWrapper {
        font-size: 10px;
    }

    .wrapperDetails>div {
        margin-bottom: 8px;
    }

    .wrapperContainerHeader,
    .wrapperHeader {
        font-size: 12px;
    }

    .textContainer {
        font-size: 9px
    }

    .imageContainer {
        margin-bottom: 56px !important;
    }

    .imageContainer>div:first-child>div,
    .dealerImageContainer {
        margin-bottom: 16px;
    }

    .imageContainer>div {
        padding: 0px;
    }

    .imageWrapper {
        height: 45px;
        margin-bottom: 0px;
        padding: 0px;
    }

    .addressWrapper div {
        font-size: 10px;
    }

    .addressWrapper {
        padding: 0px;
    }

    .headerContainer {
        margin-bottom: 16px;
        padding: 0px;
    }

    .wrapperDetails strong {
        font-size: 10px;
    }

    .headerWrapper {
        font-size: 18px;
    }

   

    .wrapperHeader {
        text-align: center;
    }

    .productDetailWrapper {
        width: fit-content;
    }

    .productTableWrapper {
        overflow-x: scroll;
        margin-bottom: 8px;
    }

    .wrapperHeader {
        text-align: center;
    }

    .contentWrapper {
        background-color: white;
    }

    .wrapperDetails div {
        display: flex;
        flex-direction: column;
    }

    .wrapperDetails span {
        display: block;
        width: 100%;
    }

    .imageContainer>div:first-child {
        padding-left: 0px;
    }
}


@media only screen and (max-width: 320px) {
    .cardWrapper {
        font-size: 10px;
        width: 290px !important;
        margin: auto;
    }
}


@media only screen and (min-width: 321px) and (max-width: 385px) {
    .cardWrapper {
        font-size: 10px;
        width: 355px !important;
        margin: auto;
    }
}


@media only screen and (min-width: 385px) and (max-width: 768px) {
    .cardWrapper {
        font-size: 10px;
        width: 368px !important;
        margin: auto;
    }
}
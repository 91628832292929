@import url('../../css/VARIABLES.css');

.salesOptionsContainer {
    background-color: var(--Color__Neutral1);
    padding: 70px 0px;
}

.colorBar {
    width: 78px;
    margin-bottom: 8px;
    border: 3px solid var(--SecondaryBrandColor);
    border-radius: 8px;
}

.salesOptionsHeader {
    font-size: 38px;
}

.paragraphContainer {
    padding: 0px 165px 16px;
}

.paragraphContainer p {
    font-size: var(--H5__Size);
    line-height: 30px;
    margin-bottom: 16px;
}

.textHighlight {
    color: var(--PrimaryBrandColor);
    font-weight: bold;
}

.salesInfoContainer {
    padding: 0px 100px;
}

.salesIcon {
    padding: 64px 60px;
    border: 1px solid var(--Color__Neutral4);
    background-color: #FFFFFF;
}

.dealerBenefitsIcon svg {
    height: 60px;
    width: 85px;
    margin-bottom: 32px;
}

.salesIcon p {
    font-size: var(--H6__Size);
}

.dealerContainer {
    padding: 55px;
    color: #FFFFFF;
    background-color: var(--PrimaryBrandColor);
}

.dealerBenefits>div {
    font-size: var(--H5__Size);
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
}

.dealerBenefits>div svg {
    width: 16px;
    fill: #FFFFFF;
    margin-right: 10px;
    vertical-align: baseline;
    height: 14px;
}

@media screen and (max-width: 767.98px) {

    .salesOptionsContainer {
        padding: 50px 1px;
    }

    .salesOptionsHeader {
        font-size: var(--H2__Size);
    }

    .paragraphContainer {
        padding: 0px;
    }

    .salesInfoContainer {
        padding: 16px 16px 0px;
    }

    .salesIcon {
        padding: 24px;
    }

    .salesIcon>div {
        display: flex;
    }

    .dealerBenefitsIcon svg {
        margin-right: 16px;
        margin-bottom: 16px;
    }

    .dealerBenefitsIcon h3 {
        margin-top: 16px;
    }

    .dealerContainer {
        padding: 24px
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .salesOptionsContainer {
        padding: 60px 16px;
    }

    .salesOptionsHeader {
        font-size: 32px;
    }

    .paragraphContainer {
        padding: 0px;
    }

    .salesInfoContainer {
        padding: 0px 60px;
    }

    .salesIcon {
        padding: 32px;
    }

    .salesIcon>div {
        display: flex;
    }

    .dealerBenefitsIcon h3 {
        margin-top: 16px;
    }

    .dealerBenefitsIcon svg {
        margin-right: 16px;
        margin-bottom: 8px;
    }

    .dealerContainer {
        padding: 32px
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    .salesInfoContainer {
        padding: 0px 20px;
    }
}
@import url('../../../css/VARIABLES.css');

.helpBtnWrapper {
    position: fixed;
    bottom: 66px;
    right: 40px;
    color: var(--PrimaryTextColor);
    font-size: 16px;
}

.helpBtn {
    font-size: 1rem;
    line-height: inherit;
    padding: 8px 15px;
    transition: all .3s ease;
    align-items: center;
    border-radius: 100px !important;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    letter-spacing: .5px;
    text-align: center;
    background: var(--Color__Neutral2);
    color: var(--Heading__Color);
    border: 2px solid var(--Heading__Color);
}

.helpBtn::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 60%;
    bottom: 1%;
    border-right: 2px solid var(--Heading__Color);
    border-bottom: 2px solid var(--Heading__Color);
    transform: translate(-50%,50%) rotate(45deg);
    background-color: var(--Color__Neutral2);
    box-shadow: 0 2px 4px 0 rgba(0,87,184,0.01);
}

.moreHelp,
.moreHelp:hover {
    color: var(--BtnLink__Text);
    padding: 0;
    font-weight: 600;
    text-decoration: none;
    cursor:pointer;
}

.helpTipText {
  background: white;
  padding: 40px;
  position: absolute;
  right: 0;
  bottom: 60px;
  width: 260px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);  
}
.helpHeading {
    color: var(--PrimaryTextColor)
}

.helpTipText .close {
    background-color: transparent;
    border: none;
    background: url('../../../assets/images/close.svg');
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    margin: auto 0.35rem auto 0;
    position: absolute;
    top: 14px;
    right: 2px;
}

@media (max-width: 767px) {
    .helpTipText {
        width: 300px;
        overflow: auto;
    }
    .helpBtnWrapper li:first-child a {
        color: var(--LinkColor);
        font-weight: 600;
        cursor: pointer;
    }
}


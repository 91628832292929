@import url('./VARIABLES.css');

button {
    /* positioning */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border-radius: var(--Btn__BorderRadius) !important;
    border: var(--BtnSecondary__Border);
    /* typography */
    font-size: 14px;
    font-family: inherit;
    line-height: 1.5;
    transition: all .3s ease;
    font-weight: 600;
    text-align: center;
    letter-spacing: var(--LetterSpacing);
    /* other */
    cursor: pointer;
    transition: all .3s ease
}

button:focus {
    outline: 0;
    outline-offset: -2px;
}

button.iconBtn svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

.btn-primary {
    color: var(--BtnPrimary__Text);
    background: var(--BtnPrimary__Bg) !important;
    border: var(--BtnPrimary__Border) !important;
}

.btn-primary svg {
    fill: var(--BtnPrimary__Text);
}
.btn-primary:hover svg {
    fill: var(--BtnPrimary__HoverText)
}

.btn-primary:active {
    border-color: var(--FocusColor) !important;
    background: white !important;
    color: var(--PrimaryBrandColor) !important;
    box-shadow: inset 0 0 0 1px var(--FocusColor) !important;
}

.btn-primary:hover {
    color: var(--BtnPrimary__HoverText) !important;
    background: var(--BtnPrimary__HoverBg) !important;
    border: var(--BtnPrimary__HoverBorder) !important;
}

.btn-secondary:hover {
    color: var(--BtnSecondary__HoverText) !important;
    background: var(--BtnSecondary__HoverBg) !important;
    border: var(--BtnSecondary__HoverBorder) !important;
}

.btn-secondary svg {
    fill: var(--IconColor);
}
.btn-secondary:hover svg {
    fill: var(--BtnSecondary__HoverText);
}
button span {
    display: flex;
}

.btn-primary:focus {
    border-color: var(--FocusColor) !important;
    box-shadow: inset 0 0 0 1px var(--FocusColor) !important;
}

.btn-secondary:focus {
    border-color: var(--FocusColor);
    background: white;
    color: var(--PrimaryBrandColor);
    box-shadow: inset 0 0 0 1px var(--FocusColor);
}

.btn-primary:focus {
  background: transparent;
}

.btn-secondary {
    color: var(--BtnSecondary__Text);
    background: transparent;
    border: var(--BtnSecondary__Border);
}

.btn-secondary:active {
    color: #fff;
    background: var(--SecondaryBrandColor);
    border-color: var(--SecondaryBrandColor);
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    background: var(--BtnDisabled__Bg) !important;
    border: var(--BtnDisabled__Border) !important;
    color:  var(--BtnDisabled__Text)!important;
    cursor: default;
}
.iconBtn:disabled svg,
.iconBtn.disabled svg,
.iconBtn.disabled svg,
.iconBtn:disabled svg,
.iconBtn.disabled:hover svg,
.iconBtn:disabled:hover svg,
.iconBtn.disabled:hover svg,
.iconBtn:disabled:hover svg {
    fill: var(--BtnDisabled__Text)
}

.continueBtn {
    min-width: 84px;
}

.btnSave {
    min-width: 56px;
}

.btnLink,
.btnLink:hover {
    background: none;
    color: var(--BtnLink__Text);
    border: none;
    padding: 0;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

    .btnLink:disabled {
        color: var(--BtnDisabled__Text);
    }

/* Links */

a {
    color: var(--PrimaryTextColor);
    cursor: pointer;
}

.dummy-link {
    color: var(--LinkColor);
    cursor: pointer;
}

.callLink {
    text-decoration: none;
    cursor: text !important;
    color: var(--PrimaryTextColor);
    font-weight: 400;
}

.callLink:hover, .callLink:focus {
    text-decoration: none;
    color: var(--PrimaryTextColor);
    font-weight: normal;
}

@media screen and (max-width: 767px) {
    .callLink, .callLink:hover {
        cursor: pointer !important;
        color: var(--LinkColor);
        font-weight: 600;
    }
}

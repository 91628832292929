.accordionHeader {
    font-size: 16px !important;
    font-weight: normal !important;
    padding: 18px !important;
    border-bottom: 1px solid var(--Input__Border) !important;
}

.accordionStyle {
    box-shadow: none !important;
    border-color: var(--Input__Border) !important;
}

.accordionTitle {
    display: flex;
    justify-content: space-between;
}

.active {
    transform: rotate( 180deg)!important;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.active svg,
.closed svg{
    fill: var(--IconColor);
    width: 20px;
    height: 20px;
}

.closed {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
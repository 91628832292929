@import url('../../css/VARIABLES.css');

.errorMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 160px 0px;
    text-align: center;
}

.errorMessage svg {
    margin: 1.5rem 0;
    width: 100%;
    max-width: 130px;
}

.errorMessage p:first-of-type {
    font-size: 30px;
    font-weight: 600;
    color: var(--PrimaryBrandColor);
}

.errorMessage p:nth-of-type(2) {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
    color: var(--PrimaryTextColor);
}

.errorMessage a {
    font-weight: 600 !important;
}
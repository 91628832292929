.wrapper {
    display: flex;
    justify-content: space-between;
}

.left, .right {
    display: flex;
    flex-direction: row nowrap;
}
.left button {
    margin-right: 16px;
}

.right button {
    margin-left: 16px;
}
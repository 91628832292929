/* Select A Term Accordion */

.coverageIcon {
    height: 85px;
    margin-bottom: 16px;
}

.coverageIcon svg {
    fill: var(--IconColor);
}

.yesIcon svg {
    height: 12px;
    width: 13px;
    fill: var(--IconColorYes);
}

.yesIcon {
    text-align: center;
}


/* Reimbursement Rates Acoordion */

.reimbursementRatesTable table tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.reimbursementRatesTable table {
    margin-bottom: 0px;
    border-color: var(--Color__Neutral2);
}

.reimbursementRatesTable table td,
.reimbursementRatesTable table th {
    text-align: center;
}

.reimbursementRatesTableFull table td,
.reimbursementRatesTableFull table th {
    text-align: left;
}

.reimbursementRatesTableCarrier table th {
  vertical-align: top;
}

.reimbursementRatesTableGE {
    width: 50%;
}

@media screen and (max-width: 991px) {
    .reimbursementBullets {
        padding-left: 16px !important;
        margin-top: 24px;
        margin-bottom: 0px !important;
    }
}


/* Coverage Type Accordion */

.coverageTypeTable table tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.coverageTypeTable table {
    margin-bottom: 0px;
    border-color: var(--Color__Neutral2);
}

.yesIcon svg {
    height: 12px;
    width: 13px;
    fill: var(--IconColorYes);
}

.yesIcon {
    text-align: center;
}

@media screen and (min-width: 1440px) {
    .coverageTypeTable table thead th:nth-child(1) {
        width: 29% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
    .coverageTypeTable table thead th:nth-child(1) {
        width: 35% !important;
    }
}


/* Plan Options */

.poDescription {
    font-size: 16px;
}

.traneTableHead {
  background-color: var(--Table__RowBg);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0;
  text-align: center;
}

.traneTableHead h1, .traneTableHead .h3 {
  margin: 0 !important;
  padding: 10px 0 !important;
}

.reimbursePlanType {
    text-align:left !important;
}
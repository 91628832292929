@import url('../../css/VARIABLES.css');

.modal-header.suggestedAddress {
    flex-direction: column;
    padding-bottom: 0;
    border-bottom: none;
}

.radioBox input[type="radio"]:checked+label {
    background-color: var(--RadioWithBg__CheckedBg);
    border: 1px solid var(--RadioWithBg__CheckedBorder);
    color: var(--RadioWithBg__CheckedText);
}

.radioControlsLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    padding: 1rem 3rem 1rem 3rem;
    position: relative;
    text-align: left;
    border: 1px solid var(--RadioWithBg__Border);
    margin: 0.25rem 0 1rem;
    border-radius: 3px;
    width: 100%;
}

.radioBox input[type=radio]:checked+.radioControlsLabel:after {
    background: var(--RadioButton__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 16px;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 9px;
}

.radioControlsLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 50%;
    border: 1px solid var(--RadioButton__Border);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 31px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 18px;
    transition: background .3s ease;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
}

.radioBox input[type=radio]:checked+.radioControlsLabel:before {
    border-color: var(--RadioButton__CheckedBorder);
}

.radioBox input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radioBoxWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radioBox {
    position: relative;
}

@media screen and (min-width:501px) and (max-width:575px) {
    .termsAndConditions {
      width:87%;
      height:100%;
    }
}

@media screen and (min-width:576px){
    .termsAndConditions {
      width:100%;
      height:100%;
    }
}

@media screen and (min-width:404px) and (max-width:500px) {
    .termsAndConditions {
      width:70.5%;
      height:100%;
    }
}

@media screen and (min-width:360px) and (max-width:403px) {
    .termsAndConditions {
      width:62%;
      height:100%;
    }
}

@media screen and (max-width:359px) {
    .termsAndConditions {
      width:55%;
      height:100%;
    }
}
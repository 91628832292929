@import url('./VARIABLES.css');

/* =============================================================================
  Inputs
============================================================================= */

.form-group {
    margin-bottom: 24px;
    position: relative;
}

.form-group .control-label {
    display: block;
    margin-bottom: var(--Input__LabelMarginBottom);
}

.form-label,
.form-label-radio {
    color: var(--Input__LabelColor);
    font-weight: 600;
    line-height: 1.5;
    max-width: 100%;
    padding-top: 3px;
}
.form-label-radio {
    margin-bottom: 0;
}

.form-label-radio {
    display: block;
}
.required-indicator {
    color: var(--Input__RequiredAsterisk);
    font-size: 1rem;
    font-weight: 600;
    margin: 0 8px 0 4px;
}

.form-control {
    background-color: white;
    background-image: none;
    border: 1px solid var(--Input__Border);
    color: var(--PrimaryTextColor);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    height: 35px;
    letter-spacing: var(--LetterSpacing);
    line-height: 1.25;
    padding: 6px;
    width: 100%;
    padding-right: 24px !important;
    border-radius: var(--Input__BorderRadius);
    transition: border-color, border-width ease-in-out .2s;
}

.form-control:focus {
    border: var(--InputFocus__Border);
    outline: 0;
    box-shadow: inset 0 0 0 1px var(--FocusColor);
}

.form-control[disabled] {
    background-color: var(--InputDisabled__Bg) !important;
    border: var(--InputDisabled__Border) !important;
    color: var(--InputDisabled__Text);
    opacity: 1;
    cursor: not-allowed;
}

.form-control-static {
    min-height: 56px;
}

.textarea.form-control {
    width: 100%;
    height: auto;
    padding: 8px;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}
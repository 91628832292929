@import url('../../../css/VARIABLES.css');

@media screen and (min-width:767.99px) and (max-width: 991px) {
  .summaryWidth {
    width: 69% !important;
  }
}

@media screen and (min-width:768px) {
  .infoMobile {
    display: none;
  }
}

@media screen and (max-width:767px) {
  .infoMedDiv {
    display: none;
  }
  .serialinfoIcon {
    margin-top: -.65rem !important;
  }
}

.addBtn {
  float: right;
  display: flex;
  margin-bottom: 1.5rem;
}

.addBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

/* .addBtnWrapper button:nth-of-type(1) {
  margin-right: 0.8rem;
} */

/* .addBtnNew {
  display: flex;
} */

/* .regularAdd {
  display: flex;
}*/

.hoverAdd {
  display: flex;
  /* fill: var(--BtnSecondary__HoverText) */
} 

/* .addBtn:hover .regularAdd,
.addBtnNew:hover .regularAdd {
  display: none;
} */

/* .addBtn:hover .hoverAdd,
.addBtnNew:hover .hoverAdd {
  display: flex;
} */

/* .addBtn svg,
.addBtnNew svg {
  margin-right: .25rem;
} */

.editOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.deleteIcon {
  background-image: url(../../../assets/images/Delete_Icon.svg);
  border: none;
  height: 18px ;
  width: 19px ;
  cursor: pointer ;
  background-repeat: no-repeat;
  background-color: transparent ;
}

.closeIcon {
  background: url(../../../assets/images/close_med.svg);
  border: none;
  height: 17px;
  width: 18px;
  cursor: pointer;
  margin-right: 3px;
  background-repeat: no-repeat;
}

.EditIcon {
  background-image: url(../../../assets/images/Edit_Icon.svg) ;
  border: 0 ;
  height: 21px ;
  width: 23px ;
  cursor: pointer ;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-color: transparent ;
}

.greenCheck {
  background: url(../../../assets/images/Check_icon.svg);
  width: 16px;
  margin-top: 5px;
  margin-left: 0.5rem;
  background-repeat: no-repeat;
}

.greenCheckHeight {
  height: 12px;
  margin-top: 8px !important;
  margin-left: 13px !important;
}

.rowTableSelected {
  background-color: var(--Color__Neutral2);
}

.rowcheckboxLabel {
  letter-spacing: 0.29px;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
  display: flex;
  /* height: -webkit-fill-available; */
  align-items: center;
  color: var(--PrimaryTextColor);
  word-break: break-word;
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel:before {
  margin-top: 0;
  border: none;
  background-image: url('../../../assets/images/checkbox-selected.svg');
  background-position: center;
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.Lennox:before,
.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.lennox:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.Lennox:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.lennox:before {
  background-image: url('../../../assets/images/Vendor/Lennox/checkbox-selected.svg');
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.Rheem:before,
.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.rheem:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.Rheem:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.rheem:before {
  background-image: url('../../../assets/images/Vendor/Rheem/checkbox-selected.svg') !important;
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.Carrier:before,
.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.carrier:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.Carrier:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.carrier:before {
  background-image: url('../../../assets/images/Vendor/Carrier/checkbox-selected.svg') !important;
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.Trane:before,
.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.trane:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.Trane:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.trane:before {
  background-image: url('../../../assets/images/Vendor/Trane/checkbox-selected.svg') !important;
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.ICP:before,
.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.icp:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.ICP:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.icp:before {
  background-image: url('../../../assets/images/Vendor/ICP/checkbox-selected-icp.svg') !important;
}

.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.HomeDepot:before,
.rowcheckbox input[type=checkbox]:checked+.rowcheckboxLabel.homedepot:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.HomeDepot:before,
.serialHeader input[type=checkbox]:checked+.serialHeaderLabel.homedepot:before {
  background-image: url('../../../assets/images/Vendor/HomeDepot/checkbox-selected-hd.svg') !important;
}

.serialHeaderLabel:before,
.rowcheckboxLabel:before {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 0;
  border: 1px solid var(--Color__Neutral3);
  content: '';
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex: 0 0 18px;
  flex: 0 0 18px;
  height: 18px;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 18px;
  width: 18px;
  height: 18px;
  margin: 0px;
  margin-left: 0.5rem;
  margin-right: 2.5rem;
  align-items: center;
}

.rowcheckbox input[type=checkbox],
.serialHeader input[type=checkbox] {
  left: -9999px;
  opacity: 0;
  position: absolute;
}

.rowcheckbox,
.serialHeaderLabel {
  display: flex;
}

.serialHeaderLabel {
  display: flex;
  margin-bottom: 0rem;
}

.addProductsTable table tr:first-child {
  border-top: none !important;
}

.addProductsTable div thead tr th {
  width: 15.5%;
}

.addProductsTable div thead tr th:nth-child(1) {
  width: 25%;
}

.addProductsTable div thead tr th:nth-child(6) {
    width: 20.5%;
}

.addProductsTable div thead tr th:last-child {
  width: 10%;
}

.margLeft {
  margin-left: 66px;
}

/* Lennox Add Product */

.searchIcon {
  border: 1px solid var(--Input__Border);
  border-left: none;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 35px;
  width: 48px;
  text-align: center;
  cursor:pointer;
}

.searchIcon svg {
    width: 22px;
    fill: var(--IconColor)
}

.searchIconStyle {
  fill: var(--IconColor);
  height: 22px;
  width: 22px;
}

.searchIcon img {
  height: 19px;
}

.searchWrapper {
  display: flex;
  align-items: center;
}

.searchWrapper input[type=text] {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.serialinfoIcon {
  text-align: center;
  position: relative;
  margin-top: 1.25rem;
  fill: var(--IconColor);
  height: 18px;
}

.serialCheckIcon {
  height: 14px !important;
}

.seriailInfoTooltiptext {
  visibility: hidden;
  height: auto;
  width: 20rem;
  background-color: white;
  color: var(--PrimaryTextColor);
  padding: 24px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
  box-sizing: border-box;
  border: 1px solid var(--Color__Neutral2);
  position: absolute;
  z-index: 1;
  bottom: -76px;
  left: 26px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
}

.seriailInfoTooltiptext::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0%;
  top: 38%;
  border-left: 1px solid var(--Color__Neutral2);
  border-bottom: 1px solid var(--Color__Neutral2);
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.serialinfoIcon:hover .seriailInfoTooltiptext {
  visibility: visible;
  opacity: 1;
}

.verifyMsg {
  color: #13AA41;
}

.notFoundMsg {
  color: var(--ErrorColor);
}

.installationDateWidth svg{
  min-width: 32px !important;
  padding: 7px !important;
  fill: var(--IconColor)
}

.editCloseButton {
  background-color: transparent;
  border: none;
  color: var(--PrimaryTextColor);
  font-size: 14px;
  font-weight: normal;
  height: 24px;
  width: auto;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.editCloseIcon {
  height: 16px;
  width: 16px;
  fill: var(--IconColor);
}

.closeIconSvg g rect{
  fill: var(--IconColor);
}
@media screen and (max-width: 767px) {
    .anotherSaleBtn button {
        margin-bottom: 1rem;
        width: 100%;
    }
    .homeBtn {
        width: 100%;
    }
    .printConfirm {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .saveSaleConfirmation p:first-of-type {
        text-align: center;
    }
    .printBtn {
        position: relative !important;
        right: 0% !important;
    }
    .finalContractsTable div {
        display: block !important;
    }
    .finalContractsTable table {
        width: 100% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .finalContractsTable table {
        width: 73% !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .finalContractsTable table {
        width: 51% !important;
    }
}

.saveSaleConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saveSaleConfirmation p:first-of-type {
    font-size: 32px;
    font-weight: 600;
    color: var(--Heading__Color);
    margin-bottom: 0rem;
}

.saveSaleConfirmation p:nth-of-type(2) {
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.finalContractsTable table thead tr {
    border-bottom: none !important;
}

.finalContractsTable table tbody tr:first-child {
    border-top: none !important;
}

.finalContractsTable table {
    margin-bottom: 0rem;
    width: 45%;
}

.finalContractsTable div {
    display: flex;
    justify-content: center;
}

.columnLastName {
    color: var(--LinkColor) !important;
    cursor: pointer;
}

.printBtn {
    position: absolute;
    right: 5%;
    display: flex;
}

.regularP {
    margin-right: 5px;
    margin-bottom: 3px;
    height: 15px;
    width: 15px;
}

.anotherSaleBtn {
    margin-right: 1rem;
    display: flex;
    justify-content: end;
}

.anotherSaleBtn button:first-child {
  margin-right: 16px;
}

.printBtn .regularP svg {
    margin-bottom: 7px;
}

.printBtn:hover .regularP svg {
    fill: #ffffff;
}

.printBtn:focus .regularP svg {
    fill: var(--PrimaryBrandColor);
}

.succuessIcon svg {
  height: 200px;
  margin-bottom: 2rem;
}
.addContactBtn {
    display: flex;
    margin-top: 1rem;
}

    .addContactBtn:hover .regularAdd svg g g {
        stroke: #fff;
    }

    .addContactBtn:focus .regularAdd {
        display: flex;
        margin-right: 4px;
    }

        .addContactBtn:focus .regularAdd svg g g {
            stroke: #001871;
        }

    .addContactBtn .regularAdd {
        display: flex;
        margin-right: 4px;
    }

.regularAdd svg g g {
    stroke: var(--BtnSecondary__Text);
}

@media (min-width: 992px) {
    .colSize {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding: 0 0.5rem !important;
    }
    .colSize:nth-child(n+6) {
        margin-top: 1rem;
    }
}
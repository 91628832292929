@import url("../css/VARIABLES.css");

.mainContainer {
    display: flex;
}

.mainContainerLogin {
    padding: 0;
    margin: 0;
}

.closeIconContainer {
    position: relative;
    display: flex;
    flex-flow: row-reverse nowrap;
    margin-bottom: 20px;
}

.closeIcon {
    height: 24px;
    width: 24px;
    fill: var(--IconColor);
    cursor: pointer;
}

.dealerContainer {
    background: url("../assets/images/vendor-login.svg") #ffffff no-repeat top
        right;
    background-size: 36%;
    flex: 1 0 auto;
    display: flex;
}

.dealerLogin {
    background-size: 30%;
}

.dealerLoginFormContainer {
    padding-right: 20px;
    padding-left: 48px;
    padding-top: 20px;
    background-color: var(--Color__Neutral2);
}

.dealerText {
    margin-top: 120px;
    margin-left: 45px;
}

.dealerLoginForm {
    width: 350px;
    margin-right: 30px;
}

@media (max-width: 575.98px) {
    .dealerContainer {
        background: #fff;
        flex: auto;
    }
    .dealerLoginFormContainer {
        padding-left: 20px;
        width: 100%;
    }
    .dealerText {
        display: none;
    }
    .dealerLoginForm {
        width: 100%;
    }
    .mainContainer {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 680px) {
    .dealerContainer {
        flex: auto;
    }
    .dealerLoginFormContainer {
        padding-left: 20px;
        width: 100%;
    }
    .dealerText {
        display: none;
    }
    .dealerLoginForm {
        width: 100%;
    }
    .mainContainer {
        width: 100% !important;
        max-width: 100% !important;
    }
    .dealerButtonScreen {
        padding: 0 11%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .dealerContainer {
        background-size: 55%;
    }
    .dealerLogin {
        background-size: 27%;
    }
    .dealerLoginForm {
        width: 280px;
    }
    .dealerText {
        margin-top: 90px;
        margin-left: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .dealerContainer {
        background-size: 50%;
    }
    .dealerLogin {
        background-size: 28%;
    }
    .dealerText {
        margin-top: 90px;
        margin-left: 20px;
    }
}

@import url('../../css/VARIABLES.css');

.contentContainerICP {
  background-color: #FFFFFF;
  padding-top: 70px;
  padding-bottom: 70px;
}

.headerContainer {
  padding: 78px;
  display: flex;
  flex-direction: column;
  height: 278px;
}

.headerContainer h1 {
  font-size: 38px;
}

.paragraphContainer {
  padding: 42px;
  background-color: var(--PrimaryBrandColor);
  height: 278px;
}

.paragraphContainer p {
  font-size: var(--H5__Size);
  color: #FFFFFF;
  line-height: 28px;
  margin-bottom: 0px;
}

.colorBar {
  width: 78px;
  margin-bottom: 8px;
  border: 3px solid var(--SecondaryBrandColor);
  border-radius: 8px;
}

.infoContainerICP {
  padding: 40px 16px 16px 24px;
  border: 1px solid rgba(220, 220, 220, .7);
  height: 278px;
}

.loginParagraphICP {
  font-size: var(--H6__Size);
  margin-bottom: 0;
}

.loginIconICP svg {
  width: 55px;
  height: 60px;
  margin-bottom: 32px;
}

.businessInfoContainer {
  padding-right: 0;
}

@media screen and (max-width:767.98px) {

  .contentContainerICP {
    padding: 50px 1px;
  }

  .businessInfoContainer {
    padding-right: 15px;
  }

  .headerContainer {
    padding: 16px;
    align-items: center;
    height: auto;
  }

  .headerContainer h1 {
    font-size: var(--H2__Size);
    text-align: center;
  }

  .paragraphContainer {
    padding: 30px;
    height: auto;
  }

  .serviceInfoContainer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .infoContainerICP {
    padding: 24px;
    height: auto;
  }

  .infoContainerICP>div {
    display: flex;
  }

  .loginIconICP svg {
    margin-right: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
}

@media (min-width:768px) and (max-width:991.98px) {

  .contentContainerICP {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 4px;
  }

  .businessInfoContainer {
    display: flex;
    padding-right: 15px;
  }

  .headerContainer {
    padding: 100px 0 0 24px;
    height: auto;
  }

  .headerContainer h1 {
    font-size: 32px;
  }

  .paragraphContainer {
    padding: 24px;
    height: auto;
  }

  .serviceInfoContainer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .infoContainerICP {
    padding: 24px;
    height: auto;
  }
}

@media (min-width:992px) and (max-width:1199.98px) {

  .contentContainerICP {
    margin-left: -16px;
  }

  .headerContainer {
    height: auto;
    padding: 100px 8px 64px 48px;
  }

  .infoContainerICP {
    height: auto;
  }

  .paragraphContainer {
    height: auto;
  }
}
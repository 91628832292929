@import url('../../../css/VARIABLES.css');
.checkbox {
    margin-bottom: 24px;
    display: inline-flex;
}

.checkbox.scriptBox {
    display: block;
}

.checkboxLabel {
    color: var(--PrimaryTextColor) !important;
    font-family: var(--TextFont);
    letter-spacing: var(--LetterSpacing);
    margin-bottom: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 14px !important;
}

.checkboxLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 2px; 
    border: 1px solid var(--Checkbox__Border); 
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px; 
    flex: 0 0 18px; 
    height: 18px; 
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 18px;
    width: 18px; 
    height: 18px; 
    margin: 0px;
    margin-right: 10px;
    align-items: center;
}

.checkbox input[type=checkbox] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

/* CHECKED  */

.checkbox input[type=checkbox]:checked+.checkboxLabel:before {
    border: none;  
    background: var(--Checkbox__CheckedBg);
    background-image: url('../../../assets/images/checkbox-selected.svg');
    background-position: center;
    background-repeat: no-repeat;
}

/* FOCUS AND HOVER  */

:focus {
    outline: -webkit-focus-ring-color auto 1px;
}
.checkbox input[type=checkbox]:focus+.checkboxLabel:before {
    border-color: var(--FocusColor) !important;
    border-width: 2px;
}
.checkboxinput[type=checkbox]:hover+.checkboxLabel:before {
    border-color: var(--FocusColor) !important;
    border-width: 1px;
}




/*  
    ========== BRAND SPECIFIC CUSTOM CHECKBOX =========== 
    add more of these rules for future clients
*/

.checkbox input[type=checkbox]:checked+.checkboxLabel.lennox:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.Lennox:before {
    background-image: url('../../../assets/images/Vendor/Lennox/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.rheem:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.Rheem:before {
    background-image: url('../../../assets/images/Vendor/Rheem/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.carrier:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.Carrier:before {
  background-image: url('../../../assets/images/Vendor/Carrier/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.trane:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.Trane:before {
  background-image: url('../../../assets/images/Vendor/Trane/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.GE:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.ge:before {
    background-image: url('../../../assets/images/Vendor/GE/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.ICP:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.icp:before {
    background-image: url('../../../assets/images/Vendor/ICP/checkbox-selected-icp.svg');
}


.checkbox input[type=checkbox]:checked+.checkboxLabel.HomeDepot:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.homedepot:before {
    background-image: url('../../../assets/images/Vendor/HomeDepot/checkbox-selected-hd.svg');
}

.checkboxLabel.GE:before, .checkboxLabel.ge:before {
    background-image: url('../../../assets/images/Vendor/GE/checkbox.svg');
    max-width: 24px;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 0;
    flex: 1 0 0;
}
@import url('../../../css/VARIABLES.css');

.secondaryNav {
    background: var(--Color__Neutral2);
    width: 101%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    margin-left: -15px;
}

.secondaryNavServiceRequest {
    width: 111%;
}

.navPillsUl {
    margin:0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
    display: flex;
}

.navPillsUlPadding {
    padding-left: 70px;
}

.navItem {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.44px;
    border-radius: 20px;
    border: 1px solid var(--PrimaryBrandColor);
    color: #000;
    padding: 3px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navItem.green {
    background-color: #E0EED0;
    border-color: #13AA41;
}

.navItem.orange {
    background-color: #FFE8C9;
    border-color: #E36F1E;
}

.navItem.yellow {
    background-color: #FFF6CB;
    border-color: #FDB813;
}

.navItem.active {
    background-color: #fff;
}

.navItem:nth-child(1){
    margin-left: 0;
}
.subTab {
    color: inherit;
}

.closeTab {
    padding-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconClose {
    height: 10px;
    width: 10px;
    fill: #717171;
    stroke: #717171;
    stroke-width: 2;
}

.secondaryNavLink.active .iconClose {
    fill: #1352DE;
    stroke: #1352DE;
}

.secondaryNavLink.green:not(.active) .iconClose {
    fill: #13AA41;
    stroke: #13AA41;
}

.secondaryNav ul {
    padding-left: 70px;
}
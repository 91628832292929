@import url('./VARIABLES.css');

/* Style The Dropdown Button */

.dropbtn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: border-color ease-in-out .2s;
    display: inline-block;
    line-height: 1.25;
    text-align: left;
    vertical-align: middle;
    font-weight: 400;
    border-radius: var(--Input__BorderRadius);
    border: 1px solid var(--Input__Border);
    padding: 6px 48px 6px 6px;
    color: #767676;
    width: 100%;
    height: 35px;
    background: #fff;
}

.dropbtn:after {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/arrow-cheveron-down.svg');
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 15px;
    top: 10px
}

.reportsDropdown:after {
  display: none;
  content: ' ';
  background-image: none;
  background-size: 0;
  height: 0;
  width: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.reportsOtherDropdown:after {
  display: none;
}

.reportsOtherDropdown svg {
  top: 12px;
}

.dropbtnIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  fill: var(--IconColor) !important;
}

.dropdown {
    position: relative;
    display: block;
    color: var(--PrimaryTextColor);
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: solid var(--Color__Neutral3);
    border-width: 0 1px 1px;
    font-size: 14px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-content a {
    border-top: 1px solid var(--Color__Neutral3);
    color: #343741;
    cursor: pointer;
    display: block;
    margin-bottom: 0;
    font-weight: 400;
    padding: 8px 6px;
}

.dropdown-content a:not([href]):not([tabindex]):hover,
.dropdown-content a:not([href]):not([tabindex]):hover .checkboxLabel {
    background: var(--DropOption__HoverBg);
    color: #fff;
    text-decoration: none;
}


/*
.dropdown:hover .dropdown-content {
    display: block;
}
*/

.dropdown:hover .dropbtn {
    border: 1px solid #00A4E4;
}

.input-group-prepend .dropbtn {
    border-radius: 3px 0 0 3px;
    width: 114px;
}


/* =============================================================================
  DropDown Menu
============================================================================= */

.dropdown-toggle {
    width: 100%;
    background-color: inherit;
    display: flex;
    align-items: center;
    height: 100%;
    border-top: 1px solid var(--Color__Neutral2);
    border-left: 1px solid var(--Color__Neutral2);
    cursor: pointer;
}

.dropdown-toggle svg {
    margin: 0 auto;
    display: block;
}

.dropleft {
    width: 2.5rem;
}

.dropleft .dropdown-toggle::before {
    display: none;
}

.dropdown-menu {
    margin: 0rem;
    padding: 0rem;
    border-radius: 0rem;
    min-width: 5rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: var(--DropOption__HoverBg);
    color: #fff;
    border-radius: 0;
}

.dropdown-menu.show {
    display: block;
    top: 15px !important;
    transform: translate3d(-30px, 19px, 0px) !important;
}

.queueDropdown button.dropbtn {
    background: transparent;
    border: none !important;
    padding: 6px 30px 6px 6px;
    font-size: 16px;
}

.queuereport-dropmenu.dropdown-menu.show {
    display: block;
    left: 29px !important;
    top: 17px !important;
    min-width: 220px;
    padding: .5rem;
}

button.dropdown-item {
    color: var(--Heading__Color);
    background-color: #fff;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 1px solid var(--Color__Neutral2);
    
}


/*Claims Queue drop down with no border*/

.queueDropdown button.dropbtn {
    background: transparent;
    border: none !important;
    padding: 6px 30px 6px 6px;
    font-size: 16px;
}


/*.queueDropdown button.dropbtn::after {
        right: 68px !important;
    }*/

.reportsDropdown,
.reportsDropdown:hover {
    font-Size: 25px !important;
    font-weight: bolder;
    color: var(--PrimaryTextColor);
    text-overflow: revert !important;
}

.reportsDropdown .reportsCounter {
    color: var(--PrimaryTextColor);
    font-weight: 300;
    padding-right: 10px;
    display: inline-flex;
}

.reportsDropdown::after {
    width: 15px !important;
    height: 15px !important;
    top: 14px;
    background-size: 15px 15px !important;
}

.dropbtn img,
.dropdown-content img {
    width: 25px;
    height: 25px;
    min-width: 20px;
    min-height: 20px;
    object-fit: contain;
}


/*Assign DropDown*/

.addon-dropdown-group .dropbtn {
    border: 1px solid var(--Color__Neutral3) !important;
}

.addon-dropdown-group:first-child .dropbtn {
    border-radius: 0 3px 3px 0;
}

.addon-dropdown-group:first-child .dropbtn.addon-left {
    border-radius: 3px 0 0 3px;
    padding-right: 30px;
    border-right: 0 !important;
}

.addon-dropdown-group .dropdown-item {
    padding: 8px 6px;
    color: var(--PrimaryTextColor);
}

.addon-dropdown-group .dropdown-item:hover {
    color: white;
}

.addon-dropdown-group button.dropbtn::after {
    right: 10px !important;
}

.addon-dropdown-group .dropdown-menu.show {
    display: block;
    left: 0px !important;
    top: 0px !important;
    min-width: 110px;
}

.dropdown-grp .dropdown-menu.show {
    left: 0px !important;
    top: 0px !important;
}

.dropdown-grp .dropbtn:hover {
    color: var(--Heading__Color);
    background-color: #fff;
}


/*DropDown group radio button*/

.datesGroup .radioButton input[type="radio"]+label {
    width: 100%;
    padding: 2px;
    margin-bottom: .5rem!important;
}

.datesGroup {
    margin: 0rem;
    margin-top: 1.3rem;
}

.datesGroup .radioButton:last-child input[type="radio"]+label {
    margin: 0;
}
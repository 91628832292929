@import url('../../css/VARIABLES.css');

@media screen and (max-width: 767px) {
    .stepsCol {
        padding: 0rem !important;
        margin-bottom: 1.3rem;
    }
    .advBenCol,
    .advantageCampaignsSteps {
        padding: 0rem !important;
    }
    .threeYrPlan {
        margin-bottom: 1rem;
    }
    .strtDt {
        padding: 0rem !important;
        margin-bottom: 24px;
    }
    .endDt {
        padding: 0rem !important;
    }
    .advCampIcon {
        justify-content: center !important;
        padding: 0rem;
        margin-bottom: 1rem;
    }
    .stepsAdvCampaigns li b {
        flex: 0.20 !important;
    }
    .planOptionsRow {
        display: block !important;
    }
    .crtCampaignBtn {
        display: flex;
        justify-content: center;
        width: 100% !important;
    }
    .listOfBenefits button,
    .crtCampaignBtn button,
    .createCampnBtn button {
        width: 100%;
    }
    .createCampnBtn {
        margin-top: 1rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .stepsCol {
        padding: 0rem !important;
        margin-bottom: 1rem;
    }
    .advBenCol {
        padding: 0rem;
    }
    .planOptionsRow {
        display: block !important;
    }
    .threeYrPlan {
        margin-bottom: 24px !important;
    }
}

.campaignStepsCard {
    border: 1px solid var(--ScriptBox__Border);
    border-radius: 6px;
    background-color: var(--ScriptBox__Bg);
    padding: 1rem;
}

.createCampnBtn {
    margin-top: 1rem;
}

.stepsCol {
    padding-left: 0rem;
}

.advBenCol {
    padding-right: 0rem;
}

.listHeader {
    color: var(--PrimaryBrandColor);
    font-size: 16px;
    font-weight: 600
}

.stepsAdvCampaigns {
    padding: 0rem;
    list-style: none;
    margin-bottom: 0rem;
}

.advlist li::marker {
     color: var(--PrimaryBrandColor)
}

.dateIcon {
    fill: var(--PrimaryBrandColor)
}

.stepsAdvCampaigns li {
    display: flex;
    color: var(--PrimaryBrandColor);
    font-size: 14px;
}

.stepsAdvCampaigns li p {
    display: flex;
    flex: 0.91;
}

.stepsAdvCampaigns li b {
    flex: 0.09;
    display: flex;
}

.advantageCampaignsSteps b {
    color: var(--PrimaryBrandColor);
}

.advantageCampaignsSteps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0rem;
}

.advCampIcon {
    display: flex;
    align-items: center;
    padding-left: 0rem;
}

.advCampIcon svg {
    width: 100%;
}

.secondCardHeader {
    background-color: var(--AdvantageAccentColor);
    color: var(--Advantage__TextColor);
    padding: 10px 0px 10px 16px;
}


.secondCardHeaderh5 {
    color: var(--Advantage__TextColor);
    font-size: var(--H5__Size);
    margin-bottom: 0;
}

.secondCardHeader h4 {
    margin-bottom: 0rem;
}

.benefitsList {
    border-radius: 5px;
    border: 1px solid var(--Color__Neutral3);
}

.listOfBenefits {
    padding: 1rem;
}

.listOfBenefits ul {
    padding-left: 16px;
}

.lrnMoreWrapper {
    display: flex;
    justify-content: center;
    margin-top: 26px;
}

.listOfBenefits p:nth-of-type(2) {
    color: var(--PrimaryBrandColor);
    font-weight: 600;
    font-size: 14px;
}

/*
* MOVED TO GLOBAL STYLES: RADIOSANDCHECKS.CSS
*/

/* .squareRadioBtn input:checked+label,
.squareRadioBtn label.selected,
.squareRadioBtn input[type="radio"]+label:hover,
.squareRadioBtn input[type="radio"]+label:focus {
    color: #fff !important;
    background-color: #1352DE !important;
    border-color: #1352DE !important;
}

.squareRadioBtn input[type="radio"]+label {
    padding: 8px 1rem 8px 1rem;
    border: 1px solid var(--Color__Neutral3);
    background-color: #fff;
    color: var(--PrimaryBrandColor);
    text-align: center;
}

.squareRadioBtn input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
} */

.autoPayLabel {
    font-size: 14px !important;
    font-weight: 600;
}

.customParagraph {
    margin-left: 28px;
    margin-bottom: 0rem;
    color: var(--Input__LabelColor);
}

.planOptionsRow {
    display: flex;
    justify-content: space-between;
}

.threeYrPlan {
    background-color: var(--AdvantageAccentColor);
    color: var(--Advantage__TextColor);
    font-weight: 600;
    border-radius: 6px;
    width: 203px;
    font-size: 16px;
    padding: 0.2rem 0.5rem;
}

.threeYrPlan p {
    margin-bottom: 0rem;
}

.threeYrPlan p:first-of-type {
    font-size: 11px;
    width: 37px;
}

.threeYrPlan p:nth-of-type(2) {
    font-size: 37px;
}

.planDiscount {
    display: flex;
    align-items: center;
}

.linkStyle {
    color: var(--LinkColor) !important;
    margin-left: 2px;
    cursor: pointer;
    font-weight: 600;
}

.modHeader {
    padding: 16px;
    border-bottom: 1px solid var(--Color__Neutral2);
}

.createCampnBtn {
    display: flex;
    justify-content: flex-end;
}

.contactTypes {
    display: flex;
    align-items: center;
}

.radioWrapper {
    width: 30%;
}

.contactConfirmation svg {
    height: 40px;
    width: 83px;
    margin-right: 10px;
}

.contactConfirmation {
    display: flex;
    align-items: center;
}

.contactConfirmation div {
    color: var(--SecondaryBrandColor);
    font-size: 16px;
    line-height: 20px;
}

.cardSectionHR {
    margin: 24px auto
}

.calendarIcon svg {
  fill: var(--IconColor);
}

.validationWrapper {
    width: 70%;
}

.contactBtn:focus {
    color: #FFF !important;
    background: var(--BtnSecondary__HoverBg) !important;
    border: var(--BtnSecondary__HoverBorder) !important;
    box-shadow: none !important;
}

.radioLabel {
    text-wrap: wrap !important;
}
.specialNotesWrapper {
    width: 100%;
    max-width: 425px;
}

.notesLabel {
    color: #001871;
    font-weight: 600;
} 

.notesField {
    min-height: 125px;
}

.autoHeight {
    min-height: 25px;
    max-height: 300px;
}

.specialNotesWrapper p {
    text-align: right;
}

.shortNotesField {
    min-height: 90px;
}
.fullWidthNotesWrapper {
    width: 100%;
}
    .fullWidthNotesWrapper p {
        text-align: right;
    }
@import url('../../css/VARIABLES.css');

.notification {
    position: relative;
}

.notification span {
    top: 25px !important;
}

.head {
    display: flex;
    justify-content: space-between;
}

/* .dropTime used in jsx but not defined here. */

.head div {
    display: flex;
    justify-content: flex-end;
    /* position: relative; */
}

.dropTime {
    margin-bottom: 0;
    width: max-content !important;
}

.dismiss {
    height: 10px;
    width: 10px;
    margin: 0 1rem;
}

.dismiss svg {
    fill: var(--IconColor);
    margin-bottom: 4px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: var(--Heading__Color);
    margin-bottom: 0px;
}


.message {
    font-size: 16px;
    margin-bottom: 0px;
}
@import url('../../css/VARIABLES.css');

.vendorLoginICP {
    background-image: url('../../assets/images/Vendor/ICP/customer-benefits.jpg');
    min-height: 480px;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: top;
}

.benefitsContainer {
    padding: 85px 90px 70px;
    background-color: var(--Color__Neutral1);
    height: 480px;
}

.benefitsContainer h1 {
    font-size: 32px;
}

.benefitsContainer p {
    font-size: var(--H5__Size);
    margin-right: 60px;
}

.colorBar {
    width: 78px;
    margin-bottom: 8px;
    border: 3px solid var(--SecondaryBrandColor);
    border-radius: 8px;
}

.serviceBenefits>div {
    font-size: var(--H5__Size);
    margin-bottom: 16px;
    display: flex;
}

.serviceBenefits>div svg {
    width: 16px;
    fill: var(--PrimaryBrandColor);
    margin-right: 10px;
    vertical-align: baseline;
    height: 14px;
}

@media screen and (max-width: 767.98px) {

    .customerBenefitsContainer {
        flex-direction: column-reverse;
    }

    .vendorLoginICP {
        min-height: 220px;
    }

    .benefitsContainer {
        padding: 32px 32px 16px;
        height: 440px;
    }

    .benefitsContainer h1 {
        font-size: var(--H2__Size);
    }

    .benefitsContainer p {
        margin-right: 8px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .customerBenefitsContainer {
        flex-direction: column-reverse;
    }

    .vendorLoginICP {
        min-height: 415px;
    }

    .benefitsContainer {
        padding: 40px 48px 24px;
        height: 400px;
    }

    .benefitsContainer p {
        margin-right: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    .vendorLoginICP {
        min-height: 420px;
    }

    .benefitsContainer {
        padding: 32px 40px 16px;
        height: 420px;
    }

    .benefitsContainer p {
        margin-right: 0px;
    }
}
.customAlert {
    border-radius: 0;
    background-color: #fff !important;
    border: 1px solid #E81944 !important;
    color: #E81944;
    font-weight: bold;
    margin-bottom: 0;
}

.customAuthAlert {
    background-color: #FFBF3F !important;
    color: #001871;
    border: 1px solid #FFBF3F !important;
}

.customAlert.alert-danger {
    border-radius: 0;
    background-color: #fff !important;
    border: 1px solid #E81944 !important;
    color: #E81944;
    font-weight: bold;
    margin-bottom: 0;
}

    .customAlert.alert-success {
        border-radius: 0;
        background-color: #fff !important;
        border: 1px solid #E81944 !important;
        color: #E81944;
        font-weight: bold;
        margin-bottom: 0;
    }

    .customAlert.alert-warning {
        border-radius: 0;
        background-color: #fff !important;
        border: 1px solid #E81944 !important;
        color: #E81944;
        font-weight: bold;
        margin-bottom: 0;
    }

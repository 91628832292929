@import url('../../css/VARIABLES.css');

.searchColumn {
    padding-left: 39.5px;
    border-left: 1px solid var(--Color__Neutral2);
}

@media screen and (max-width: 767px) {
    .searchColumn {
        padding-left: 15px;
        border-left: none;
    }
}

label.searchCriteria {
    font-size: 18px;
    margin: .125rem 0 1rem;
}

.radio {
    padding-bottom: .5rem;
}

    .radio .controls-label {
        font-size: 16px;
    }

.clientLabel, .searchCriteria {
    color: var(--Input__LabelColor);
    display: inline-block;
    font-weight: 600;
    line-height: inherit;
    margin-bottom: 0;
    margin-right: 8px;
    max-width: 100%;
    font-size: 18px;
    margin-right: 1rem;
}

.radio input[type=radio]:checked + .controls-label:after {
    top: 4;
}

.controls-label:before {
    margin-top: 4px;
}

.mt1 {
    margin-top: 40px;
}

.resultsTable td:nth-child(1n) {
    font-weight: 600;
    color: var(--PrimaryTextColor);
}

.search {
    min-width: 75px;
}

.toolTipInfo{
    position: relative;
    font-weight: 600 !important;
    border-bottom: 1px dashed var(--LinkColor);
    letter-spacing: var(--LetterSpacing);
    border-radius: 0;
    color: var(--LinkColor);
}

.onsiteInfoTooltiptext {
    visibility: hidden;
    height: auto;
    width: 16rem;
    background-color: #fff;
    color: var(--PrimaryTextColor);
    padding: 16px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgb(0 87 184 / 1%);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: 30px;
    left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    text-align: left;
    font-weight: normal !important;
    font-size:14px  !important;
    font-family:var(--TextFont) !important;
}

.onsiteInfoTooltiptext::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 109px;
    top: -13px;
    border-top: 1px solid var(--Color__Neutral2);
    border-left: 1px solid var(--Color__Neutral2);
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
}

.toolTipInfo:hover .onsiteInfoTooltiptext {
    visibility: visible;
    opacity: 1;
}

.onsiteInfoTooltiptext table{
    margin-bottom: 0px;
}

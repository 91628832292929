@import url('../../../../css/VARIABLES.css');

.desc {
    font-size: 16px;
}

.smallNote:nth-of-type(3) {
    margin-bottom: 24px !important;
}

.yesIcon svg {
    height: 12px;
    width: 13px;
    fill: var(--IconColor);
}

.noIcon svg {
    height: 10px;
    width: 10px;
    fill: var(--IconColor);;
}

.yesIcon,
.noIcon {
    text-align: center;
}

@media screen and (min-width: 1440px) {
    .coverageTable table thead th:nth-child(1) {
        width: 29% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
    .coverageTable table thead th:nth-child(1) {
        width: 35% !important;
    }
}

.link {
    color: var(--LinkColor);
    cursor: pointer;
}

.headerIcon {
    height: 30px;
    fill: var(--IconColor);
    margin-right: 10px;
}

.shieldIcon {
    height: 30px;
    margin-right: 10px;
}

.coverageTable table tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.coverageTable table thead th {
    vertical-align: top;
}

.noWrapIcon {
    white-space: nowrap;
}

.circleLabel {
    display: flex;
    align-items: center;
    float: right;
}

.circle {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: block;
    margin-right: 0.25rem;
    margin-left: 1.2rem;
}

.OEMCov {
    background-color: #343741;
}

.planCov {
    background-color: var(--PrimaryBrandColor);
}

.OEMCovLennox {
    background-color: #000000;
}

.planCovLennox {
    background-color: var(--IconColor);
}

.agreement li::marker {
    color: #235191;
}

.agreementLennox li::marker {
    color: var(--IconColor);
}

.progressTimeline {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.progressBars {
    margin-top: 1rem;
    position: relative;
}

.progressBarsTop {
    display: flex;
    justify-content: center;
}

.oemSubHead {
    text-align: center;
}

    .oemSubHead p:last-of-type {
        color: var(--Heading__Color);
        font-weight: bold;
    }

    .oemSubHead p {
        margin-bottom: 0rem;
        font-size: 14px !important;
    }

.progressBars div h5 {
    margin-top: 0.15rem;
    margin-bottom: 0rem;
}

.progressBars p {
    margin-bottom: 0rem;
    margin-right: 0.2rem;
    font-size: 16px;
}

.progress {
    width: 100%;
    height: .35rem;
    background-color: var(--Color__Neutral2);
    position: relative;
}

.partsSubhead {
    display: flex;
    align-items: center;
}

.esa {
    width: 100%;
    background-color: var(--PrimaryBrandColor);
    position: relative;
}
.esaLennox {
    width: 100%;
    background-color: var(--IconColor);
    position: relative;
}

.oem {
    background-color: black;
    width: 100%;
}

.oemSubHeadLeft {
    flex: 60%;
    max-width: 60%;
    text-align: center;
}

.esaSubHeadRight {
    flex: 40%;
    max-width: 40%;
    text-align: center;
}

.measureheader {
    display: flex;
    justify-content: flex-end;
}

/* Tooltip */

.infoIcon {
    margin-left: 5px;
    margin-bottom: 5px;
    font-family: var(--TextFont) !important;
}

.retailPriceInfoIcon {
    margin-left: 5px;
    margin-top: 4px;
}

.retailPriceWrapper {
    display: flex;
    position: relative;
}

.retailPriceInfo {
    position: relative;
    margin-left: 10px;
}

.preText {
    background-color: white;
    background-image: none;
    border: 1px solid var(--Input__Border);
    color: var(--PrimaryTextColor);
    display: block;
    font-size: 0.875rem;
    height: 35px;
    letter-spacing: .5px;
    padding: 6px;
    padding-left: 10px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: none;
    transition: border-color, border-width ease-in-out .2s;
}

.preTextInput {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: var(--Input__BorderRadius);
    border-bottom-right-radius: var(--Input__BorderRadius);
    border-left: none!important;
}

.carrierProgressLabel {
    color: var(--PrimaryTextColor) !important;
}

.twoColWrapperXS {
    padding: 0 0.5rem 0 0 !important;
  }
@media screen and (max-width: 767px) {
  .logoutContainer p:first-of-type {
      text-align: center;
  }
  .logoutIcon svg {
      height: 120px !important;
  }
}

.logoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logoutIcon svg {
  height: 160px;
  margin-bottom: 32px;
}
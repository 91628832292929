@import url('../../../css/VARIABLES.css');
.AIGBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    font-size: var(--FontSize);
    position: relative;
    top: 0;
}

.closeBanner {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
}

.AIGBanner a {
    margin-left: 24px;
    text-decoration: underline !important;
    font-weight: normal;
}
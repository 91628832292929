/*.variableIconWrapper svg {
    height: 125px;
    margin-bottom: 24px;
}*/

.footerAlign {
    padding: 24px 40px;
    display: flex;
    align-items: center;
    background-color: #eee;
}

@media (max-device-width: 1024px) {
    .footerAlign {
        display: grid;
    }
}
@import url('../../../css/VARIABLES.css');

.saveSaleHeader h4 {
    margin-bottom: 0px;
}

.saveSaleHeader {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Color__Neutral2);
    justify-content: space-between;
}
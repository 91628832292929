.vendorVerifyCode {
    background: url("../assets/images/verify-code.svg") #FFF no-repeat top right;
}

@media screen and (max-width:640px){
   .vendorVerifyCode{
        background: #FFF;
   }  
}

.mainContainer {
    padding-top: 60px;
}

.formGroupSpacing{
    margin-bottom: 32px;
}

.resendCol {
    padding: 15px 0;
    margin: 0;
}

.infoText {
    font-size: 16px;
    color: #343741 ;
    line-height: 20px;
}

a.resendLink:not([href]), a.resendLink:not([href]):hover{
    color: var(--LinkColor);
}
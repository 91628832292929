div [data-testid="viewer-page-layer-0"],
div [data-testid="viewer-page-layer-1"],
div [data-testid="viewer-page-layer-2"],
div [data-testid="viewer-page-layer-3"],
div [data-testid="viewer-page-layer-4"],
div [data-testid="viewer-page-layer-5"],
div [data-testid="viewer-page-layer-6"],
div [data-testid="viewer-page-layer-7"],
div [data-testid="viewer-page-layer-8"],
div [data-testid="viewer-page-layer-9"],
div [data-testid="viewer-page-layer-10"],
div [data-testid="viewer-page-layer-11"],
div [data-testid="viewer-page-layer-12"] {
    width: 100% !important;
    text-align: center !important;
}

.saveBtn {
    text-align: center !important;
}
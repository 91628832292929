@import url('../../../css/VARIABLES.css');

@media screen and (max-width: 991px) {
    .totalAmount {
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 1020px) {
    .pageHeader {
        display: none !important;
    }
}

@media screen and (max-width: 425px) {
    .oemSubHead {
        min-width: 45% !important;
    }
    .planHeader {
        display: block !important;
        text-align: left !important;
    }
    .planHeader span {
        margin-left: 0rem !important;
    }
}

@media screen and (max-width: 325px) {
    .circle {
        margin-left: 0rem !important;
    }
}

.activePrice {
    color: var(--Heading__Color);
}

.inactivePrice {
    opacity: 5.4 !important;
}


/* .pageHeader {
  margin-left: 1.5rem;
} */

.planHeader {
    display: flex;
    align-items: center;
    text-align: center;
}

.planHeader span {
    padding:3px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--Color__Neutral2);
    border-radius: 11.5px;
    margin-left: 1rem;
}

.planHeader h2 {
    margin-bottom: 0rem;
    text-align: left;
}

.totalAmount {
    display: flex;
    margin-top: 2rem;
    align-items: center;
    font-weight: 600;
    color: var(--PrimaryTextColor);
}

.totalAmount p {
    margin-bottom: 0rem;
}

.currencySym {
    font-size: 43px;
}

.amountNum {
    font-size: 29px;
    line-height: 0.7;
}

.circle {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: block;
    margin-right: 0.25rem;
    margin-left: 1.2rem;
}

.circleLabel {
    display: flex;
    align-items: center;
}

.measureheader {
    display: flex;
    align-items: center;
}

.measureheader h4 {
    margin-bottom: 0rem;
}

.measureheader img {
    float: right;
    margin-left: auto;
    height: 23px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
}

.cardHeader img {
    height: 23px;
}

.circleLabel p {
    margin-bottom: 0rem;
}

.measureheader .circleLabel:first-of-type p {
    margin-right: 1rem;
}

.progressTimeline {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.progressBars {
    margin-top: 1rem;
    position: relative;
}

.oemSubHead {
    display: flex;
    justify-content: center;
    min-width: 21%;
}

.oemSubHead p:last-of-type {
    color: var(--Heading__Color);
    font-weight: bold;
}

.oemSubHead p {
    margin-bottom: 0rem;
    font-size: 14px !important;
}

.progressBars div h5 {
    margin-top: 0.15rem;
    margin-bottom: 0rem;
}

.progressBars p {
    margin-bottom: 0rem;
    margin-right: 0.2rem;
    font-size: 16px;
}

.progress {
    width: 100%;
    height: .35rem;
    background-color: var(--Color__Neutral2);
    position: relative;
}

.partsSubhead {
    display: flex;
    align-items: center;
    /* font-size: 16px; */
    /* align-items: center; */
}

.partsSubhead img {
    height: 12px;
}

.w-10 {
    width: 13%;
}

.w-30 {
    width: 30%;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.planProgress {
    width: 30%;
    background-color: var(--IconColor);
    position: relative;
}

.oemProgress {
    background-color: black;
    width: 100%;
}

.OEMCov {
    background-color: black;
}

.planCov {
    background-color: var(--IconColor);
}

.reimbursePlanTable table {
    border: 1px solid var(--Color__Neutral2) !important;
}

.progressDesignTop {
    position: absolute;
    right: 0rem;
    top: -0.55rem;
    height: 0.35rem;
    border-right: 2px solid var(--PrimaryTextColor);
}

.progressDesignBottom {
    position: absolute;
    right: 0rem;
    height: 0.35rem;
    top: 0.19rem;
    border-right: 2px solid var(--PrimaryTextColor);
}

.aigSubH {
    position: relative;
    display: flex;
    justify-content: center;
}

.product {
    height:100%;
    min-height: 74px;
    background: var(--Color__Neutral1);
    border-radius: 4px;
    padding: 1rem;
}


@media screen and (max-width: 767px) {
    .plandetailProduct {
        width: 100%;
    }
}

.planProgress::after {
    content: ' ';
    position: absolute;
    width: 2px;
    height: 0.35rem;
    background-color: var(--PrimaryTextColor);
    right: 0px;
}

.product p {
    margin-bottom: 0rem;
}

.productName {
    font-weight: 600;
}

.noOfProd {
    height: 1.1rem;
    width: 1.75rem;
    background: var(--Color__Neutral2);
    border: 1px solid rgba(0, 19, 118, 0.29);
    border-radius: 11.5px;
    color: var(--Heading__Color);
    margin-left: 0.25rem;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.addProduct p {
    margin-bottom: 0rem;
    color: var(--BtnSecondary__Text)
}

.addProduct:hover p {
    color: var(--BtnSecondary__HoverText)
}

.addProduct svg {
    height: 19px;
    width: 17px;
    fill: var(--BtnSecondary__Text)
}
.addProduct:hover svg {
    fill: var(--BtnSecondary__HoverText)
}

.addProduct {
    border: var(--BtnSecondary__Border);
    background-color: var(--BtnSecondary__Bg);
    color: var(--BtnSecondary__Text) !important;
    border-radius: 4px;
    height: 100%;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}
.addProduct:hover {
    border: var(--BtnSecondary__HoverBorder);
    background-color: var(--BtnSecondary__HoverBg);
    color: var(--BtnSecondary__HoverText);

}


/* Radio Buttons */

.radioCylinder input[type="radio"]:checked + label {
    background-color: var(--RadioWithBg__CheckedBg);
    border: var(--RadioWithBg__CheckedBorder);
    color: var(--RadioWithBg__CheckedText);
    box-shadow: 0 2px 4px 3px rgba(0, 4, 8, 0.02);
}

.controlsLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);
    cursor: pointer;
    border-radius: 101px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    padding: 0rem 1rem 0rem 1rem;
    position: relative;
    align-items: center;
    border: 1px solid var(--Color__Neutral3);
    margin-bottom: 0rem;
    box-shadow: 0 2px 4px 3px rgba(0, 4, 8, 0.02);
}

.radioCylinder input[type=radio]:checked + .controlsLabel:after {
    background: var(--RadioWithBg__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 10px;
    left: 8px;
    position: absolute;
    top: 18%;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    width: 10px;
}

.controlsLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 50%;
    border: 1px solid var(--Color__Neutral3);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 31px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 6px 6px 6px -7px;
    max-width: 18px;
    transition: background .3s ease;
    width: 24px;
}

.radioCylinder input[type=radio]:checked + .controlsLabel:before {
    border: var(--RadioWithBg__CheckedBorder);
}

.radioCylinder input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radioCylinder {
    position: relative;
    margin-right: 0.5rem;
}

.radioCylinder:last-child {
    margin-right: 0;
}


/* Tooltip */

.infoIcon {
    margin-left: 5px;
    margin-bottom: 5px;
    font-family: var(--TextFont) !important;
}

.retailPriceInfoIcon {
    margin-left: 5px;
    margin-top: 4px;
}

.retailPriceWrapper {
    display: flex;
    position: relative;
}
.retailPriceInfo {
    position: relative;
    margin-left: 10px;
}

.preText {
    background-color: white;
    background-image: none;
    border: 1px solid var(--Input__Border);
    color: var(--PrimaryTextColor);
    display: block;
    font-size: 0.875rem;
    height: 35px;
    letter-spacing: .5px;
    padding: 6px;
    padding-left: 10px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: none;
    transition: border-color, border-width ease-in-out .2s;
    text-wrap: nowrap;
}

.preTextInput {
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-top-right-radius: var(--Input__BorderRadius);
    border-bottom-right-radius: var(--Input__BorderRadius);
    border-left: none!important;
}

.deleteCard {
    /* float: right */
    margin-left: auto;
}

.deleteCard img {
    /* position: absolute;
    right: 0px; */
    height: 23px;
    /* margin-left: auto; */
}

.planName {
    color: var(--PrimaryTextColor) !important;
    font-weight: normal !important;
}

.sideTab {
    background: var(--AdvantageAccentColor);
    color: var(--Advantage__TextColor);
    position: relative;
    left: -24px;
    padding: 6px 22px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    width: fit-content;
    margin-top: 12px;
    border-radius: 0 100px 100px 0;
}


/* AdvPlanSummary Modal */

.advPlanSummaryHeader h4 {
    margin-bottom: 0px;
}

.advPlanSummaryHeader {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Color__Neutral2);
    justify-content: space-between;
}

.diviceList {
    padding: 0px;
    margin-left: 16px;
}

.borderCol{
    border: 1px solid var(--ErrorColor) !important;
    border-right: none !important;
}

.linkRed, .linkRed:hover {
    color: var(--ErrorColor);
    text-decoration: underline;
}
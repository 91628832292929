.customCounter {
    counter-reset: my-sec-counter;
    list-style: none;
    padding: 0px;
    margin-bottom: 24px;
}

.customCounter li h2::before {
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter);
    margin-right: 6px;
}

.customCounter li h2 {
    margin-bottom: 0px;
}

.customCounter li p {
    padding-left: 18px;
}

.note {
    font-size: 12px;
    margin-bottom: 0px;
}

.valuePropTable {
    margin: 16px 0px;
}

.valuePropTable table {
    margin-bottom: 0px;
    border-color: var(--Color__Neutral2);
}

.valuePropTable table tbody td:nth-child(1) {
    padding: 0.38rem 0.20rem!important;
}

.valuePropTable table tbody td {
    text-align: center;
}

.valuePropTable table tbody tr:last-child {
    font-weight: 600;
}

.prodIcon {
    text-align: center;
}

.prodIcon svg {
    height: 30px;
    width: 30px;
    fill: var(--IconColor);
}

a.redirectLink:not([href]), a.redirectLink:not([href]):hover{
    color: var(--LinkColor);
}
@import url('../../../css/VARIABLES.css');

.w100 {
    width: 100%;
}

.tableWrapper {
    width: 100%;
    margin: 0 auto;
}

.labelAlignment label {
    padding-top: 0;
}

.disableClickActions {
    pointer-events: none;
}

.footerRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #dee2e6;
    padding: 0 5px;
    border-radius: 0 0 4px 4px;
    border-top: none;
}

    .footerRow .flex {
        display: flex;
    }

    .footerRow .addRowBtn {
        border-radius: 16px;
        margin-right: auto;
        border: none;
        background: none;
        color: var(--LinkColor);
        cursor: pointer;
        transition: .25s all ease;
    }

    .footerRow .disableBtn {
        pointer-events: none;
        color: var(--InputDisabled__Text);
    }

    .footerRow .addRowBtn:hover {
        text-decoration: underline;
    }

.customTable th {
    padding: var(--tablePadding);
}

.linkStyle {
    margin-left: 2px;
    cursor: pointer;
    text-decoration: underline;
    color: var(--ErrorColor);
}

.customTable th,
.customTable td {
    vertical-align: middle;
}

.customTable input,
.customTable select {
    width: 100%;
}

.topTable {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.removeBottomBorder {
    border-bottom: none;
}

caption {
    padding: 0 5px;
    border-bottom: none;
    background: var(--Table__RowBg_);
}

.tableWrapper table {
    border-radius: 0;
}

table caption {
    border-top: none;
    border-radius: 0;
}

.tableWrapperContainer .tableWrapper:first-child caption {
    border: 1px solid #dee2e6;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
}

.tableFooter {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    align-items: end;
    gap: 2ch;
    padding-right: 15px;
}

.footerTotalContainer {
    display: flex;
}

.totalLable {
    text-transform: capitalize;
    padding: 6px 0;
}

.total, .totalPrice, .totalStatic, .totalStaticPrice {
    border-left: 1px solid #dee2e6;
    margin-left: 5px;
    padding: 6px 0 6px 5px;
    text-align: right;
}

.total, .totalPrice {
    width: 75px;
}

.totalStatic {
    width: 75px;
}

.totalStaticPrice {
    width: 76px;
}

.sectionHeaderRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f9fd;
    border: 1px solid #dee2e6;
    padding: 0 5px;
    border-radius: 0 0 4px 4px;
    border-top: none;
    height: 36px;
}

.taxTable tfoot th {
    border: none;
}

    .taxTable tfoot th:last-child {
        border-left: 1px solid #dee2e6
    }

@media (max-width: 1024px) {
    .AdjudicationTableContainer {
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

@media (max-width: 767px) {
    .AdjudicationTableContainer {
        overflow-x: scroll;
    }
}

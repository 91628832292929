@import url('../../css/VARIABLES.css');


@media (max-width: 767px) {
    .uploadProgress {
        max-width: 100%;
    }
    .taxContainer {
        width: 100%;
    }
    .taxWrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem !important;
    }
    .deleteContainer {
        width: 100% !important;
    }
}


@media (min-width: 576px) {
    .checkbxInpWrapper {
        padding-left: 30px;
    }
}

.taxContainer {
    border: 1px solid var(--Color__Neutral3);
    padding: 15px 0px 0px 15px;
    border-radius: 4px;
    margin-bottom: 15px;
    width: 97%;
}

.taxContainer section {
    width: fit-content;
}

.deleteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3%;
}

.trashIcon {
    height: 17px;
    width: 14px;
    cursor: pointer;
    transition: all .1s ease;
    margin: 0 4px 3px;
    fill: var(--PrimaryBrandColor);
}
.searchIcon {
    position: absolute;
    top: 8px;
    right: 0px;
    height: 18px;
    fill: var(--IconColor);
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 8px;
    height: 15px;
    fill: var(--IconColor);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.ratesTable table tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.ratesTable table tbody tr td:nth-child(2),
.ratesTable table thead tr th:nth-child(2),
.ratesTable table tbody tr td:nth-child(3),
.ratesTable table thead tr th:nth-child(3) {
    text-align: center;
}

@media screen and (max-width: 991px) {
    .ratesTable table thead th:nth-child(1) {
        width: 16% !important;
    }
}

/* Reimbursement Rates Acoordion */

.reimbursementRatesTable table tbody tr:nth-child(odd) {
    background-color: var(--Table__RowBg);
}

.reimbursementRatesTable table {
    margin-bottom: 0px;
    border-color: var(--Color__Neutral2);
}

.reimbursementRatesTable table td,
.reimbursementRatesTable table th {
    text-align: center;
}

.reimbursementRatesTableGE {
    width: 50%;
    margin-bottom: 10px;
}

.reimbursementRatesTableICP {
    width: 100%;
    margin-bottom: 10px;
}
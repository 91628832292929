/*  
* IT SEEMS THIS IS THE ONLY RULE  BEING USED 😫 
* COMMENTING FOR REMOVAL AFTER DEV TESTING
*/
@media screen and (max-width: 1020px) {
    .pageHeader {
        display: none !important;
    }
}

/* .processSteps {
    height: 55px;
    background-image: linear-gradient(180deg, #f7f9fb 0%, #ffffff 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.processStepNum {
    border-radius: 50%;
    height: 19px;
    width: 19px;
    margin-right: 3px;
    text-align: center;
    margin: 0 3px;
    border: 1px solid var(--Color__Neutral5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.inProcessStepNum {
    border: 1px solid #0079ff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formStep {
    display: flex;
    justify-content: center;
    padding: 17px 8px;
    color: var(--Color__Neutral5);
    border-bottom: 2px solid var(--Color__Neutral5);
}

.inProgressStep {
    color: #0079ff;
    border-bottom: 2px solid #0079ff;
}

.btn {
    font-size: 14px;
    font-family: inherit;
    line-height: 1.5;
    padding: 6px 12px;
    transition: all .3s ease;
    align-items: center;
    border-radius: 3px;
    border: 1px solid var(--Color__Neutral3);
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    letter-spacing: .5px;
    text-align: center;
}

.continueLink {
    color: #fff;
    background: #001871 !important;
    border-color: #001871 !important;
    float: right;
}

    .continueLink:active {
        border-color: #00a4e4 !important;
        background: #fff !important;
        color: #001871 !important;
        box-shadow: inset 0 0 0 1px #00a4e4 !important;
    }

    .continueLink:hover,
    .backLink:hover {
        color: #fff !important;
        background: #1352de !important;
        border-color: #1352de !important;
        text-decoration: none;
    }

    .continueLink:focus,
    .backLink:focus {
        border-color: #00a4e4 !important;
        background: #fff !important;
        color: #001871 !important;
        box-shadow: inset 0 0 0 1px #00a4e4 !important;
    }

.backLink {
    color: #001871;
    background: transparent;
    border-color: var(--Color__Neutral3);
}

    .backLink:active {
        color: #fff;
        background: #1352de;
        border-color: #1352de;
    }

    .continueLink.disabled,
    .continueLink:disabled,
    .backLink.disabled,
    .backLink:disabled {
        background: rgba(199, 219, 244, .24);
        border-color: var(--Color__Neutral3);
        color: #5d738d;
    }

.linkWapper {
    display: flex;
}

    .linkWapper .continueLink {
        margin-left: 8px;
    } */

.customPayBtn {
    width: 184px;
}

.btnContainer {
    padding-bottom: 50px;
}


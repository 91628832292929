@font-face {
    font-family: "aig_sanssourcepro";
    src: url('../assets/fonts/SourceSansPro-regular.eot');
    src: url('../assets/fonts/SourceSansPro-regular.woff') format("woff"), url('../assets/fonts/SourceSansPro-regular.otf') format("opentype");
}

@font-face {
    font-family: "aig_sanssourcepro_semibold";
    src: url('../assets/fonts/SourceSansPro-semibold.eot');
    src: url('../assets/fonts/SourceSansPro-semibold.woff') format("woff"), url('../assets/fonts/SourceSansPro-semibold.otf') format("opentype");
}

:root {
    /* ============================== */
    /* ======= BRAND COLORS: ======== */
    /* ============================== */
    --PrimaryBrandColor: #001871;
    --SecondaryBrandColor: #1352de;
    --PrimaryTextColor: #343741;
    --AdvantageAccentColor: #FFD279;
    --Advantage__TextColor: var(--PrimaryBrandColor);
    --FocusColor: #00a4e4;
    --ErrorColor: #E81944;
    --ProgressColor: #8AA1B9;
    /* NEUTRALS */
    --Color__Neutral1: #f6f9fb;
    --Color__Neutral2: #dee9f6;
    --Color__Neutral3: #b9c6d4;
    --Color__Neutral4: #5d738d;
    --Color__Neutral5: #616160;
    --Color__Nertral6: #495057;
    /* DASHBOARD */
    --Color__Accent1: #fca946;
    --Color__Accent2: #50d986;
    --Color__Accent3: #a478f3;
    --Color__Accent4: #007d7d;
    /* ============================== */
    /* ========== TYPOGRAPHY ======== */
    /* ============================== */
    --TextFont: "aig_sanssourcepro", Arial, Helvetica, sans-serif;
    --Legal__Size: 12px;
    --FontSize: 14px;
    --LineHeight: 1.5;
    --LinkColor: var(--SecondaryBrandColor);
    --IconColor: var(--SecondaryBrandColor);
    --LetterSpacing: 0.5px;
    --Heading__Font: var(--TextFont);
    --Heading__FontWeight: 600;
    --Heading__Color: var(--PrimaryBrandColor);
    --H1__Size: 28px;
    --H1__LineHeight: 1.2;
    --H1__Weight: 700;
    --H2__Size: 25px;
    --H2__LineHeight: 1.2;
    --H3__Size: 22px;
    --H3__LineHeight: 34px;
    --H4__Size: 20px;
    --H4__LineHeight: 32px;
    --H5__Size: 18px;
    --H5__LineHeight: 26px;
    --H5__Weight: var(--Heading__FontWeight);
    --H6__Size: 16px;
    --H6__LineHeight: 24px;
    --H6__Weight: var(--Heading__FontWeight);
    /* ============================== */
    /* ========== BUTTONS =========== */
    /* ============================== */
    --BtnAndInput__FontSize: 14px;
    --BtnAndInput__ActiveBorder: 1px solid var(--FocusColor);
    --Btn__BorderRadius: 3px;
    --Btn__Padding: 6px 12px;
    --Btn__LineHeight: 16px;
    --BtnPrimary__Bg: var(--PrimaryBrandColor);
    --BtnPrimary__Text: white;
    --BtnPrimary__Border: 1px solid var(--PrimaryBrandColor);
    --BtnPrimary__HoverBg: var(--SecondaryBrandColor);
    --BtnPrimary__HoverBorder: 1px solid var(--SecondaryBrandColor);
    --BtnPrimary__HoverText: white;
    --BtnSecondary__Bg: white;
    --BtnSecondary__Border: 1px solid var(--Color__Neutral3);
    --BtnSecondary__Text: var(--PrimaryBrandColor);
    --BtnSecondary__HoverBg: var(--SecondaryBrandColor);
    --BtnSecondary__HoverBorder: 1px solid var(--SecondaryBrandColor);
    --BtnSecondary__HoverText: white;
    --BtnLink__Text: var(--SecondaryBrandColor);
    --BtnDisabled__Bg: var(--Color__Neutral1);
    --BtnDisabled__Border: 1px solid var(--Color__Neutral3);
    --BtnDisabled__Text: var(--Color__Neutral4);
    /* ============================== */
    /* =========== INPUTS =========== */
    /* ============================== */
    --Input__LabelColor: var(--PrimaryBrandColor);
    --Input__LabelLineHeigt: 16px;
    --Input__LabelMarginBottom: 8px;
    --Input__Placeholder: var(--Color__Neutral3);
    --Input__RequiredAsterisk: var(--ErrorColor);
    --Input__BorderRadius: var(--Btn__BorderRadius);
    --Input__LineHeight: 24px;
    --Input__Border: var(--Color__Neutral3);
    --Input__LabelWeight: 600;
    --Input__Entered: var(--PrimaryTextColor);
    --InputFocus__Border: 2px solid var(--FocusColor);
    --InputError__Color: var(--ErrorColor);
    --InputError__Bg: #fefbfb;
    --InputDisabled__Bg: var(--Color__Neutral1);
    --InputDisabled__Text: var(--Color__Neutral4);
    --InputDisabled__Border: 1px solid var(--Color__Neutral3);
    --Input__Icon: var(--SecondaryBrandColor);
    --Input__IconHeight: 24px;
    --Input__IconWidth: 24px;
    --InputToggle__Color: var(--SecondaryBrandColor);
    /* ============================== */
    /* ======== MISC INPUTS ========= */
    /* ============================== */
    --Checkbox__Border: var(--Color__Neutral3);
    --Checkbox__Height: 18px;
    --Checkbox__Width: 18px;
    --Checkbox__CheckedBg: var(--PrimaryBrandColor);
    --Checkbox__CheckColor: white;
    --RadioButton__Border: 1px solid var(--Input__Border);
    --RadioButton__Bg: white;
    --RadioButton__CheckedBorder: var(--SecondaryBrandColor);
    --RadioButton__CheckedBg: linear-gradient(225deg, #1352de, #001871);
    --RadioWithBg__Border: var(--Color__Neutral3);
    --RadioWithBg__Bg: white;
    --RadioWithBg__Text: var(--PrimaryTextColor);
    --RadioWithBg__CheckedBorder: var(--SecondaryBrandColor);
    --RadioWithBg__CheckedBg: var(--Color__Neutral2);
    --RadioWithBg__CheckedText: var(--PrimaryBrandColor);
    /* ============================== */
    /* ========= DROPDOWNS ========== */
    /* ============================== */
    /* USE APPLICABLE INPUT VALUES ON DROPS TO UTILIZE OVERLAP */
    --Drop__ArrowColor: var(--PrimaryBrandColor);
    --DropOption__HoverBg: var(--SecondaryBrandColor);
    /* ============================== */
    /* ======== TABLES ========= */
    /* ============================== */
    --Table__RowBg: #f6f9fd;
    /* ============================== */
    /* ========= TABS ========== */
    /* ============================== */
    --ActiveTab: var(--PrimaryBrandColor);
    --ActiveTab__Text: var(--PrimaryTextColor);
    /* ============================== */
    /* ======== SIDEBAR NAV ========= */
    /* ============================== */
    --SideNav__Bg: var(--PrimaryBrandColor);
    --SideNav__TextColor: white;
    --SideNav__ActiveSideLine: var(--SecondaryBrandColor);
    --SideNav__ActiveBg: #0b3d7c;
    --SideNav__ActiveText: white;
    /* ============================== */
    /* ======== MAIN NAV BAR ========= */
    /* ============================== */
    --MainNav__Icon: var(--IconColor);
    --MainNav__Color: var(--SecondaryBrandColor);
    --MainNav__ActiveColor: var(--SecondaryBrandColor);
    /* ======== Script Box ========= */
    /* ============================== */
    --ScriptBox__Border: var(--SecondaryBrandColor);
    --ScriptBox__Bg: var(--Color__Neutral2);
    /* ============================== */
    /* ======== RESOURCE PAGE ICON ========= */
    /* ============================== */
    --IconColorYes: #13AA41;
    --IconColorNo: #E81944;
    /* ============================== */
    /* ======== RESOURCES SIDE TAB ========= */
    /* ============================== */
    --ResourceSideTab__ActiveBg: var(--Color__Neutral2);
    --ResourceSideTab__ActiveBorder: var(--SecondaryBrandColor);
}
@import url('../../../css/VARIABLES.css');
.expandableTextarea textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 35px;
    border: 1px solid var(--Color__Neutral3);
    font-size: inherit;
    padding: 8px 24px 8px 8px;
    border-radius: 3px;
}

.expandableTextarea {
    position: relative;
}

.expandableTextarea textarea:focus {
    border: var(--InputFocus__Border);
    outline: 0;
    box-shadow: inset 0 0 0 1px var(--FocusColor);
}

.emptyTextArea svg {
    height: 12px;
    width: 12px;
    fill: var(--IconColor);
    position: absolute;
    top: 10px;
    right: 10px;
}

.charCount {
    font-size: 16px;
    color: var(--PrimaryTextColor);
    text-align: right;
}
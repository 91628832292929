.Invoice th {
    padding: 4px 5px;
}

.Invoice th,
.Invoice td {
    vertical-align: middle;
}

.Invoice input,
.Invoice select {
    width: 100%;
}

.invoiceTextArea {
    min-height: 35px !important;
    height: 35px !important;
}

.calendarDisabled {
    pointer-events: none;
    opacity: 0.6;
    color: var(--InputDisabled__Text);
}

    .calendarDisabled input, .calendarDisabled svg {
        color: var(--InputDisabled__Text);
    }

.disableInvoice{
    pointer-events: none;
}
    .disableInvoice svg {
        visibility: hidden ;
        opacity: 0;
    }

    .disableInvoice .replacementDetails svg {
        visibility: visible !important;
        opacity: 1 !important;
    }

    .disableInvoice div {
       background-image: none;
    }

.errorMargin {
    margin-top: -20px;
}

.button {
    background-color: transparent;
    border: none;
    color: var(--PrimaryTextColor);
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    width: 50px;
    display: flex;
    padding: 0rem;
}

.buttonWidth {
    width: 20px !important;
}

.icon {
    height: 16px;
    width: 16px;
    fill: var(--IconColor);
}

.popper {
    width: 262px;
    height: 95.9px;
}

.tooltipInner {
    background: #fff;
    color: #4A4A49;
    font-size: 10px !important;
    max-width: 270px;
    height: fit-content;
    border: 1px solid #E0E5EB;
    padding: 20px;
}

    .tooltipInner p {
        font-size: 13px;
        text-align: left;
        color: #343741;
    }

    .tooltipInner::after {
        border-top-color: #fff !important;
    }


.tooltipArrow::before,
.tooltip.arrow::before {
    border-top-color: #ddd !important;
    border-bottom-color: #ddd !important;
}

/* confirmationModal modal */

.confirmCardHeader h4 {
    margin-bottom: 0px;
}

.confirmCardHeader {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Color__Neutral2);
    justify-content: space-between;
}

.centeredLoader {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
}
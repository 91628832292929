@import url('../../../css/VARIABLES.css');

.flex {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.headerRow > div {
  padding: 16px 32px;
  box-sizing: border-box;
}
  
.columnFlex {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  margin-bottom:auto;
}
  
.avatar {
  height: 80px;
  width: 80px;
  fill: var(--Color__Neutral5);
}

.headerRow div:not(:last-child) {
  border-right: 1px solid var(--Color__Neutral2);
}

@media screen and (max-width: 767px) {
  .headerRow div:not(:last-child) {
    border-bottom: 1px solid var(--Color__Neutral2);     
    border-right: none;   
  }
  .flex {
    justify-content: center;
  }
  .dealerId {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    padding-bottom: 0;
  }
}

.smallHeading {
  font-weight: 600;
  margin-bottom: 8px;
}
@import url('../../css/VARIABLES.css');

@media screen and (max-width: 1020px) {
    .processSteps {
        display: none !important;
    }
}

@media screen and (min-width: 1021px) {
    .headerForMobile {
        display: none !important;
    }
}

.circularProgress {
    height: 40px;
    width: 40px;
}

.processSteps {
    height: 55px;
    background-color: var(--Color__Neutral2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
}

.notInProcessStepNum {
    border-radius: 50%;
    height: 19px;
    width: 19px;
    margin-right: 3px;
    text-align: center;
    margin: 0 3px;
    border: 1px solid var(--Color__Neutral5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.inProcessStepNum {
    border: 1px solid var(--IconColor);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 19px;
    width: 19px;
    margin-right: 3px;
    text-align: center;
    margin: 0 3px;
}

.formStep {
    display: flex;
    justify-content: center;
    padding: 17px 8px;
}

.notInProgressStep {
    color: var(--Color__Neutral5);
    border-bottom: 2px solid var(--Color__Neutral5);
    padding: 19px 0px;
}

.inProgressStep {
    color: var(--IconColor);
    border-bottom: 2px solid var(--IconColor);
    padding: 19px 0px;
}

.completedStep {
    border-bottom: 2px solid #00AE2D;
    color: #001871;
    font-size: 16px;
}

.completedStepNum {
    background: url("../../assets/images/check.svg");
    border-radius: 50%;
    height: 19px;
    width: 19px;
    color: transparent;
    margin-right: 3px;
}

/* Circular Progress */

.headerForMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--Color__Neutral2);
    padding: 16px 16px;
    margin-bottom: 1rem;
    margin-left: -15px;
    margin-right: -15px;
}

.headerForMobile h3 {
    margin-bottom: 0rem;
}

.circularProgress {
    height: 40px;
    width: 40px;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke-linecap: round;

}

.currentStep {
    fill: var(--IconColor);
    /* stroke: #1352DE;
    stroke-width: 2px !important; */
    text-anchor: middle;
}

.completedAll {
    fill: var(--IconColor);
    /* stroke: #1352DE;
    stroke-width: 2px !important; */
}

.notCompleted {
    fill: var(--PrimaryTextColor) !important;
    opacity: 60% !important;
    /* stroke-width: 2px !important;
    stroke: #343741; */
}

@media print {
  .progressBarPrintLayout {
    display: none;
  }
}
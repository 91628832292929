/* .enrollmentForm {
    height: 773px;
     overflow: scroll;
    overflow-x: hidden;
} */

.pdfDoc::-webkit-scrollbar {
    width: 8px;
    margin: 8px 0px;
}

.pdfDoc::-webkit-scrollbar-track {
    box-sizing: border-box;
    width: 8px;
    border: 1px solid var(--Color__Neutral3);
    border-radius: 7px;
    background-color: var(--Color__Neutral1);
}

.pdfDoc::-webkit-scrollbar-thumb {
    height: 107.33px;
    width: 8px;
    border-radius: 7px;
    background-color: var(--Color__Neutral3);
}

.pdfDoc {
    /* max-width: 815px; */
    margin-bottom: 24px;
    height: 675px;
    overflow: scroll;
    overflow-x: hidden;
}
@import url('../../css/VARIABLES.css');

@media screen and (max-width: 767px) {
    .joinBtn button {
        margin-top: 1rem !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .joinBtn {
        justify-content: left !important;
        margin-top: 1rem !important;
    }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
    .categoryName {
        font-size: 19px !important;
    }
    .heightAdjust {
        margin-bottom: 19px !important;
    }
}

.dealerHm h1 {
    margin-bottom: 0px;
}

.instructionText {
    font-size: 16px;
    color: var(--PrimaryTextColor);
}

.benefitsList {
    border: 1px solid var(--ScriptBox__Border);
    border-radius: 6px;
}

.benefitsList h2 {
    letter-spacing: 0.3px;
}

.benefitsList ul {
    padding-left: 16px;
    margin-bottom: 0px;
}

.benefitsList ul li {
    margin-right: 35px;
}

.joinBtn {
    padding: 0px;
    display: flex;
    justify-content: center;
}


/* Upper Card */

.numberOf {
    font-size: 40px;
    font-weight: 600;
    color: var(--Heading__Color);
    margin-bottom: 0px;
    line-height: 34px;
}

.advCard {
    font-size: 36px;
    font-weight: 600;
    color: var(--Heading__Color);
    margin-bottom: 0px;
    line-height: 34px;
}

.categoryName {
    font-size: 20px;
    color: var(--Heading__Color);
    margin-bottom: 0px;
}

.upperCardWrapper {
    margin-bottom: 16px;
    padding: 0px 8px;
}
.upperCard,
.lowerCard {
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    /* background-image URL provided inline by jsx component */
    background-repeat: no-repeat;
    background-position: bottom -3px right -20px;
    background-size: auto 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 110px;
    height: 100%
}

.upperCard svg {
    height: 16px;
    width: 16px;
    margin-bottom: 10px;
    fill: var(--IconColor);
}

.pendingInfoTooltip {
    position: relative;
    margin-left: 7px;
}

.pendingInfoTooltiptext {
    visibility: hidden;
    height: auto;
    width: 190px;
    background-color: white;
    color: var(--PrimaryTextColor);
    padding: 24px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    box-sizing: border-box;
    border: 1px solid var(--Color__Neutral2);
    position: absolute;
    z-index: 1;
    top: -215%;
    left: 24px;
    opacity: 0;
    transition: opacity 0.3s;
    font-weight: normal;
    font-size: 14px;
}

    .pendingInfoTooltiptext::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: -1%;
        top: 47%;
        border-left: 1px solid var(--Color__Neutral2);
        border-bottom: 1px solid var(--Color__Neutral2);
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 87, 184, 0.01);
    }

.pendingInfoTooltip:hover .pendingInfoTooltiptext {
    visibility: visible;
    opacity: 1;
}


/* Lower Card */

.lowerCard svg {
    height: 46px;
    width: 46px;
    margin: 0 8px;
}

.stickDesign {
    height: 49px;
    width: 5px;
    border-radius: 11px;
    /* background-color: #FCA946; */
}

.lowerCard h3 {
    margin-bottom: 0px;
}

.lowerCardtitle {
    display: flex;
    align-items: center;
    height: 123px;
    border-bottom: 1px solid var(--Color__Neutral2);
    margin-bottom: 14px;
}

.lowerCard p {
    margin-bottom: 0px;
}

.linkIcon svg {
    height: 24px !important;
    width: 27px !important;
    fill: var(--IconColor);
    stroke: var(--IconColor);
}

.linkIcon {
    position: absolute;
    right: 16px;
    top: 10px;
}
@import url('../../css/VARIABLES.css');

.contactUsHeading {
    letter-spacing: 0.75px;
}

.cardDesc {
    font-size: 16px;
}


/* Confirmation Page */

@media screen and (max-width: 767.99px) {
    .contactUsConfirmation {
        text-align: center;
    }
}

@media screen and (max-width: 991px) {
    .contactUsConfirmation p:nth-of-type(3) a {
        color: #1352DE;
    }
}

.contactUsConfirmation p:nth-of-type(1) {
    font-size: 30px;
    font-weight: 600;
    margin-top: 22px;
    color: var(--PrimaryBrandColor);
}

.contactUsConfirmation p:nth-of-type(2) {
    font-size: 18px;
    font-weight: 600;
    color: var(--PrimaryBrandColor);
}

.contactUsConfirmation p:nth-of-type(3) {
    font-size: 16px;
    margin-bottom: 16rem;
}

.contactUsConfirmation p:nth-of-type(3) a {
    text-decoration: none;
}

.contactUsConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.contactUsConfirmation svg {
    margin: 1.5rem 0;
    width: 100%;
    max-width: 176px;
}

.commentArea{
    position: relative;
    bottom: 19px;
}
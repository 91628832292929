@import url('../../css/VARIABLES.css');
@media screen and (max-width: 767px) {
    .createCampgnBtn {
        margin-top: 51px !important;
    }
    .createCampgnBtn button {
        width: 100%;
    }
    .viewCampaignTable thead tr th:nth-child(3) {
        min-width: 108px !important;
    }
    .viewCampaignTable thead tr th:nth-child(6) {
        min-width: 65px !important;
    }
    .viewCampaignTable thead tr th:nth-child(8) {
        min-width: 80px !important;
    }
    .paymentRow div:nth-child(3) div {
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 991px) {
    .campaignBeginDt::after {
        top: 38px !important;
    }
}

@media screen and (min-width: 768px) {
    .paymentRow div div {
        margin-bottom: 0rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .viewCampaignTable thead tr th:nth-child(3) {
        width: 16%;
    }
    .viewCampaignTable thead tr th:nth-child(6) {
        width: 10%;
    }
    .viewCampaignTable thead tr th:nth-child(8) {
        width: 12%;
    }
}

.payMethod {
    display: flex;
    align-items: center;
}

.payMethod p {
    margin-bottom: 0rem;
}

.payMethod svg {
    height: 30px;
    margin-right: 8px;
}

.advTerms a {
    color: var(--LinkColor) !important;
    font-weight: 600;
    margin-right: 10px;
    cursor: pointer;
}

.advTerms span {
    margin-bottom: 0rem;
    color: var(--PrimaryTextColor);
    font-weight: 600;
    margin-left: 5px;
}

.addCampaignBtn {
    display: flex;
    margin-top: 1rem;
}

.addCampaignBtn:hover svg g g {
    stroke: var(--BtnPrimary__HoverText);
}

.addCampaignBtn:focus {
    display: flex;
    margin-right: 4px;
}

.addCampaignBtn:focus svg g g {
    stroke: var(--BtnSecondary__HoverText);
}

.addCampaignBtn svg {
    display: flex;
    margin-right: 4px;
}

.actionBtn svg {
    height: 18px;
    width: 18px;
    fill: var(--IconColor)
}

.actionBtn svg g g g g {
    fill: var(--IconColor);
}

.copyBtn svg {
    height: 15px;
    fill: var(--IconColor);
}

.editCampaignInfo {
    display: flex;
    justify-content: space-between;
}

.campaignBeginDt {
    position: relative;
}

.campaignBeginDt::after {
    content: '';
    background-image: url("../../assets/images/blue-arrow.svg");
    height: 11px;
    width: 11px;
    position: absolute;
    top: 37%;
    background-repeat: no-repeat;
    left: 80px;
}

.viewCampaignTable div:nth-child(2) div:nth-child(1) {
    display: none;
}

.viewCampaignTable div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    float: right;
}

.editPayment {
    border: none;
    background-color: transparent;
    display: flex;
    font-weight: normal;
    float: right;
}

.editPayment svg g g g g {
    fill: var(--IconColor);
}

.modalHeader {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 1px solid var(--Color__Neutral2);
}

.modalHeader h4 {
    margin-bottom: 0rem;
}

.customScript {
    display: block !important;
    margin-bottom: 24px;
}

.customScript p {
    margin-left: 28px;
    margin-bottom: 0rem;
    color: var(--PrimaryBrandColor);
}

.autoPayLabel {
    font-size: 14px !important;
    font-weight: 600;
}

.customParagraph {
    margin-left: 1.7rem;
    margin-bottom: 0;
    color: var(--Input__LabelColor);
}

.customParagraphNote {
    margin-left: 0rem;
    margin-bottom: 1.5em;
}

.advantageContainer {
  border: none !important;
  box-shadow: none !important;
}

.paymentInfoHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

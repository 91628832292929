@import url("../css/VARIABLES.css");

.vendorLogin {
  background: #F3F3F3;
  min-height: 538px;
}

.vendorLoginLennox {
  background: url("../assets/images/vendor-login.svg") #F3F3F3 no-repeat top right;
  min-height: 538px;
}

.vendorLoginRheem:before {
  background: url("../assets/images/hvac-dealer.jpg") center top no-repeat;
  background-size: cover;
  content: "";
  min-height: 538px;
  position: absolute;
  right: 0;
  top: 41px;
  width: 55%;
}

.vendorLoginCarrier:before {
  background: url("../assets/images/hvac-repair.jpg") left top no-repeat;
  background-size: cover;
  content: "";
  min-height: 538px;
  position: absolute;
  right: 0;
  top: 41px;
  width: 55%;
}

.vendorLoginICP {
  background-size: contain;
  min-height: 700px;
  background-image: url("../assets/images/Vendor/ICP/heroICP.jpg");
  background-position: right;
  background-repeat: no-repeat;
}

.loginRowContainer {
  background-color: var(--Color__Neutral3);
  border-bottom-right-radius: 26% 100%;
  padding-left: 0;
}

.loginFormContainer {
  padding: 125px 35px 0px;
  height: 700px;
}

.loginRowSection {
  width: 98%;
  border-bottom-right-radius: 27% 100%;
  background-color: var(--PrimaryBrandColor);
}

.checkboxICP label::before {
  border-radius: 0px;
}

.vendorForgotPass {
  background: url("../assets/images/forgot-password.svg") #FFF no-repeat top right;
  min-height: calc(100vh - 116px);
  margin-bottom: -24px;
  background-size: 25%;
}

.mainContainer {
  padding-top: 12vh;
}

.mainContainerRheem {
  padding-top: 20px;
}

.confirmPassCheckContainer {
  display: flex;
}

.confirmPassCheck {
  padding-right: 5px;
}
.confirmPassCheck svg {
  height: 9px;
  width: 10px;
  fill: #13AA41;
}

.confirmPassCheckText {
  background: url("../assets/images/Check_icon.svg") no-repeat left;
  padding-left: 25px;
  color: var(--PrimaryTextColor);
  font-size: 14px;
  padding-top: 3px;
}
.checkText{
  color: var(--PrimaryTextColor);
  font-size: 14px;
  padding-top: 3px;
}
.confirmPassHeadText {
  font-weight: bold;
}

.enrollNow {
  padding: 15px 0;
  margin: 0;
}

.infoText p{
  font-size: 16px !important;
  font-weight: normal !important;
  color: var(--PrimaryTextColor);
  line-height: 20px;
}

.passwordContainer {
  margin-bottom: 32px;
}

.loginRememberMe {
    float: left;
    width: auto;
}

.loginForgotPass {
    float: right;
    width: auto;
}

.clear {
    clear: both;
}
.enrollmentLink {
  color: var(--BtnLink__Text) !important;
  padding-left: 3px;
}

.createNewPassHeader {
    color: var(--Heading__Color);
}
.alignRight {
    text-align: right;
}
.roiCalculatorRowContainer {
  background: var(--PrimaryBrandColor);
}
.roiCalculatorColContainer h2, .roiCalculatorColContainer p {
  color: #FFFFFF;
  text-align: center;
}
.roiCalculatorColContainer p {
  font-size: 1.3rem;
}
.loginContainerRheem {
  background: url("../assets/images/Vendor/Rheem/rheem-logo.png") top left no-repeat;
  background-size: 20%;
  padding-top: 90px !important;
}

.infoParagraph {
  color: var(--PrimaryTextColor);
  font-size: 1.2rem;
}

.formContainer {
  background: #FFFFFF;
  border-radius: 4px;
}

.loginContentContainer {
  background: #FFFFFF;
}

.loginParagraph, .loginParagraph p {
  font-size: var(--H4__Size) !important;
}

.loginParagraph ul, .loginContentTextGE ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.loginParagraph li {
  margin-left: 0;
  margin-bottom: 20px;
  padding-left: 0;
}

.textHighlight {
  color: var(--PrimaryBrandColor);
  font-weight: bold;
}

.loginIcon svg {
  fill: var(--IconColor);
  height: 48px;
  width: 36px;
}

.customerCheckIcon svg {
  fill: var(--IconColor);
  height: 20px;
  width: 20px;
  margin-top: -14px;
}

.aigLogo svg {
  height: 75px;
  width: 138px;
}

.leftColumn:after {
  left: 100%;
  content: "";
  position: absolute;
  top: 20%;
  height: 50%;
  border-right: 3px solid #E70033;
}

.leftColumn h1 {
  font-size: 32px;
}

.customerInfoImage {
  height: 527px;
  width: 436px;
}

@media (max-width:425px) {
  .illustartionImg {
    height: 345px;
  }
  .vendorLogin {
    background: none;
    min-height: 100%;
  }
}

@media (max-width:768px) {
  .vendorLogin {
    background-size: 55%;
  }
  .vendorForgotPass {
    background-size: 40%;
  }
  .vendorLoginRheem:before, .vendorLoginCarrier:before{
    background: none;
  }

  .customerInfoImage {
    height: 411px;
    width: 340px;
    margin-top: 40px;
  }
}

@media only screen and (max-device-width : 1024px) 
  and (orientation : portrait) {
    .vendorForgotPass {
      background-size: 30%;
    }  
}

@media screen and (max-width: 767px) {
  .lennoxContainer p:first-of-type {
      text-align: center;
  }
  .lennoxIcon svg {
      height: 120px !important;
  }
  .mobileView {
    display: none;
  }
}

.lennoxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lennoxIcon svg {
  height: 160px;
  margin-bottom: 32px;
}

.loginImageRheem {
  background: #F3F3F3;
  height: 538px;
  width: 806px;
}

.footerInfoRheem {
  border-top: 1px solid #DCE2EA;
  font-size: 14px;
}

.loginContentContainerRheem {
  background: #F3F3F3;
}

.brandColor{
  color: var(--PrimaryBrandColor);
}

/* GE, ICP related styles starts */
.bodyBackground {
  background: #F5F9FF;
}
.vendorLoginGE:before {
  content: "";
  background: url('../assets/images/Vendor/GE/GE-hero.jpg') top right no-repeat;
  background-size: 100%;
  border-radius: 100% 0% 62% 38% / 0% 100% 0% 100%;
  position: absolute;
  top: 42px;
  right: 0;
  min-height: 550px;
  width: 60%;
  background-size: 100%;
  background-position-y: -104px;
}
.vendorLoginGE {
  background: linear-gradient(270deg, #1F6596 0%, #206495 41.27%, #0D4971 99.91%);
  min-height: 550px;
}
.vendorLoginInnerGE {
  min-height: 511px;
  margin: 0;
  padding: 0;
}
.loginFormGE {
  width: 90%;
  padding-top: 60px;
  padding-bottom: 30px;
}
.inputStyles {
  height: 56px !important;
}
.loginContentTextGE {
  color: var(--PrimaryTextColor);
  font-size: var(--H5__Size);
  line-height: 30px;
}
.loginParagraphGE, .loginParagraphGE p {
  font-size: var(--H6__Size) !important;
}
.loginContentTopGE {
  background-color: #FFFFFF;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 40px;
  position: absolute;
  top: 521px;
  left: 70px;
}
.loginContentBottomGE {
  background-color: #FFFFFF;
  position: relative;
  top: -42px;
  border-radius: 12px;
  padding: 90px;
}
.businessProtectionIcon {
  padding-left: 40px;
  padding-right: 40px;
}
.loginBtnGE {
  background-color: #F1BA55 !important;
  border: none;
  color: var(--PrimaryBrandColor) !important;
  height: 45px !important;
  width: 126px !important;
}
.formLabelGE {
  color: #FFFFFF !important;
}

.loginBtnICP {
  background-color: var(--SecondaryBrandColor);
  color: var(--BtnPrimary__Text);
  border: none;
  height: 45px;
  width: 126px;
}

.vendorLoginGE label,
.vendorLoginGE h1,
.vendorLoginGE a,
.vendorLoginGE .enrollNow,
.vendorLoginICP label,
.vendorLoginICP h1,
.vendorLoginICP a,
.vendorLoginICP .enrollNow {
  color: #FFFFFF !important;
}

.vendorLoginGE a,
.vendorLoginICP a {
  text-decoration: underline !important;
}
.getStartedGE {
  background: #F5F9FF;
  padding: 30px 60px 80px;
}
.getStartedHeadTextGE {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 40px;
}
.getStartedIconContainer {
  padding-left: 30px;
  padding-right: 30px;
}
.loginIconGE svg {
  fill: var(--IconColor);
  width: 65px;
}
.loginIcoMiddleGE svg {
  height: 99px;
  width: 99px;
}
.laborProtectionOuterContainer:before {
  content: "";
  background: url('../assets/images/Vendor/GE/GE-homeowners.jpg') top left no-repeat;
  border-radius: 0% 6% 45% 0% / 0% 100% 84% 10%;  
  min-height: 655px;
  width: 53%;
  background-size: 149%;
  position: absolute;
}
.laborProtectionOuterContainer {
  background: #FFFFFF;
}
.laborProtectionContainer {
  background: none;
}
.laborProtectionText {
  border: 2px solid #E7EAEE;
  border-radius: 0 0 90px 0;
  padding: 20px;
  min-height: 140px;
  display: flex;
}
.dealerContainer {
  background: #FFFFFF;
  border: 1px solid #E7EAEE;
  border-radius: 6px;  
  min-height: 401px;
  width: 60%;
}
.customerContainer {
  background: #1E6394;
  color: #FFFFFF !important;
  border-radius: 6px;  
  min-height: 401px;
  width: 60%;
}
.customerContainerFont {
  color: #FFFFFF !important;
}
.btnBenefits {
  background: #FFFFFF !important;
  height: 45px;
  width: 243px;
}
.btnBenefitsClose {
  background: #FFFFFF !important;
  height: 45px;
  width: 125px;
}
.dealerHeadContainer {
  border-bottom: 1px solid #DEE9F6;
  padding-bottom: 20px;
  margin: auto;
}
.protectionSubHead {
  font-size: var(--H3__Size);
}
.dealerCheckIcon svg {
  fill: #003B71;
  height: 13px;
  width: 13px;
  margin-top: -6px;
}
.customerCheckIconGE svg {
  fill: #FFFFFF;
  height: 13px;
  width: 13px;
  margin-top: -6px;
}
.loginContentTextGE li {
  margin-left: 0;
  margin-bottom: 10px;
  padding-left: 0;
}
.benefitsInnerContent {
  width: 60%;
}
.benefitsDetailContent {
  width: 80%;
}
.benefitsIcon svg{
  height: 32px;
  width: 32px;
  margin-top: -10px;
}
.quotationIcon svg{
  fill: #FAD89A;
  height: 40px;
  width: 40px;
}
.textUnderlineGE {
  color: var(--PrimaryTextColor);
  text-decoration: underline !important;
  cursor: pointer;
}
.headingUnderlineGE {
  color: var(--Heading__Color);
  text-decoration: underline;
}
.btnCloseBenefit svg{
  float: right;
  margin-right: 10px;
  margin-top: 8px;
  fill: #00F;
  cursor: pointer;
}
.btnCloseCustomer svg {
  float: right;
  margin-right: 10px;
  margin-top: 8px;
  fill: #FFF;
  cursor: pointer;
}
.benefitsHead {
  display: flex;
  text-align: center;
  margin: auto;
}
/* Media Queries for mobile devices */
@media only screen 
  and (min-width: 320px)
  and  (max-width: 480px) {
    .bodyBackgroundMobile {
      background: #FFFFFF;
    }
    .vendorLoginGE {
      min-height: 485px;
    }
    .loginFormGE {
      width: 100%;
      padding: 40px 15px 5px;
    }
    .loginBtnGE {
      width: 100% !important;
    }
    .loginContentBottomGE {
      top: 0;
      border-radius: 0;
      padding: 30px 0;
    }
    .businessProtectionIcon {
      padding-left: 20px;
      padding-right: 20px;
    }
    .dealerContainer {
      width: 100%;
    }
    .customerContainer {
      width: 100%;
    }
    .laborProtectionContainer {
      background: url('../assets/images/Vendor/GE/GE-homeowners.jpg') top left no-repeat;
      background-size: 100%;
      border-radius: 0;
      min-height: 250px;
    }
    .benefitsInnerContent {
      width: 100%;
    }
    .benefitsDetailContent {
      width: 100%;
    }
    .offeringRowGE {
      flex-direction: column-reverse;
    }
    .offeringColTextGE {
      text-align: center !important;
    }
    .vendorLoginGE:before {
      content: "";
      background: none;
      border-radius: 0px;
      position: relative;
      top: 0;
      right: 0;
      min-height: 0px;
      width: 0%;
      background-size: 0%;
      background-position-y: 0px;
    }
    .laborProtectionOuterContainer:before {
      content: "";
      background: none;
      border-radius: 0;  
      min-height: 0;
      width: 0;
      background-size: 0;
      position: absolute;
    }
    .laborProtectionText {
      background: #FFFFFF;
      min-height: 184px;
      margin-left: 16px;
      margin-right: 16px;
    }
    .laborProtectionOuterContainer {
      background: none;
      padding-top: 40px;
    }
    .getStartedGE {
      padding: 50px 10px;
    }
    .getStartedHeadTextGE {
      padding-left: 5px;
      padding-right: 5px;
    }
    .dealerBenefitMobile {
      width: 59%;
    }
    .customerBenefitMobile {
      width: 69%;
    }
}

@media only screen 
  and  (max-width: 767.9999px) {
    .bodyBackgroundMobile {
      background: #FFFFFF;
    }
    .vendorLoginGE {
      min-height: 485px;
    }
    .loginFormGE {
      width: 100%;
      padding: 40px 15px 5px;
    }
    .loginBtnGE {
      width: 100% !important;
    }
    .loginContentBottomGE {
      top: 0;
      border-radius: 0;
      padding: 30px 0;
    }
    .businessProtectionIcon {
      padding-left: 20px;
      padding-right: 20px;
    }
    .dealerContainer {
      width: 100%;
    }
    .customerContainer {
      width: 100%;
    }
    .laborProtectionContainer {
      background: url('../assets/images/Vendor/GE/GE-homeowners.jpg') top left no-repeat;
      background-size: 100%;
      border-radius: 0;
    }
    .benefitsInnerContent {
      width: 100%;
    }
    .benefitsDetailContent {
      width: 100%;
    }
    .offeringRowGE {
      flex-direction: column-reverse;
    }
    .offeringColTextGE {
      text-align: center !important;
    }
    .vendorLoginGE:before {
      content: "";
      background: none;
      border-radius: 0px;
      position: relative;
      top: 0;
      right: 0;
      min-height: 0px;
      width: 0%;
      background-size: 0%;
      background-position-y: 0px;
    }
    .laborProtectionOuterContainer:before {
      content: "";
      background: none;
      border-radius: 0;  
      min-height: 0;
      width: 0;
      background-size: 0;
      position: absolute;
    }
    .laborProtectionText {
      background: #FFFFFF;
      min-height: 184px;
      margin-left: 16px;
      margin-right: 16px;
    }
    .laborProtectionOuterContainer {
      padding-top: 40px;
    }
    .getStartedGE {
      padding: 50px 10px;
    }
    .getStartedHeadTextGE {
      padding-left: 5px;
      padding-right: 5px;
    }

    .vendorLoginICP {
      background: none;
      min-height: 538px;
    }

    .loginRowContainer {
      padding-left: 15px;
    }

    .loginRowSection {
      width: auto;
      border-bottom-right-radius: 0%;
    }

    .loginFormContainer {
      padding: 60px 16px;
      height: 538px;
    }

    .loginBtnICP {
      width: 100%;
    }
}

@media only screen 
  and (min-width: 768px) {
    .tabContainer {
      padding-left: 0;
      padding-right: 0;
      max-width: 768px;
    }    
    .loginContentBottomGE {
      padding: 70px 20px;
    }
    .businessProtectionIcon {
      padding-left: 20px;
      padding-right: 20px;
    }
    .getStarted {
      margin-left: 140px;
      margin-right: 140px;
    }
    .offeringRowGE {
      flex-direction: column-reverse;
    }
    .offeringColTextGE {
      text-align: center
    }
    .laborProtectionContainer {
      background: url('../assets/images/Vendor/GE/GE-homeowners.jpg') top left no-repeat;
      border-radius: 0px;
      min-height: 600px;
    }
    .loginContentTopGE {
      left: 50px;
    }
    .vendorLoginGE:before {
      background-size: 155%;
      border-radius: 100% 0% 48% 52% / 0% 100% 0% 100%;
      width: 45%;
      background-position-y: 3px;
      background-position-x: -54px;
    }
    .laborProtectionOuterContainer:before {
      content: "";
      background: none;
      border-radius: 0;  
      min-height: 0;
      width: 0;
      background-size: 0;
      position: absolute;
    }
    .loginParagraph {
      padding-left: 100px;
    }
  }

  @media (min-width: 992px) {
    .loginContentBottomGE {
      background-color: #FFFFFF;
      position: relative;
      top: -42px;
      border-radius: 12px;
      padding: 90px;
    }
    .tabContainer {
      max-width: 950px;
    }    
    .loginContentTopGE {
      left: 70px;
    }
    .businessProtectionIcon {
      padding-left: 40px;
      padding-right: 40px;
    }
    .getStarted {
      margin-left: 0;
      margin-right: 0;
    }
    .offeringRowGE {
      flex-direction: inherit;
    }
    .offeringColTextGE {
      text-align: left !important;
    }
    .laborProtectionContainer {
      background: none;
    }
    .vendorLoginGE:before {
      background-size: 100%;
      border-radius: 100% 0% 62% 38% / 0% 100% 0% 100%;
      top: 42px;
      right: 0;
      width: 60%;
      background-size: 100%;
      background-position-y: -104px;      
      background-position-x: 0;
    }
    .laborProtectionOuterContainer:before {
      content: "";
      background: url('../assets/images/Vendor/GE/GE-homeowners.jpg') top left no-repeat;
      border-radius: 0% 6% 45% 0% / 0% 100% 84% 10%;  
      min-height: 655px;
      width: 53%;
      background-size: 149%;
      position: absolute;
    }
  }

  @media (min-width: 1400px) {
    .loginContentBottomGE {
      background-color: #FFFFFF;
      position: relative;
      top: -42px;
      border-radius: 12px;
      padding: 90px;
    }
    .tabContainer {
      max-width: 100%;
    }    
    .vendorLoginGE:before {
      background-size: 100%;
      border-radius: 100% 0% 48% 52% / 0% 100% 0% 100%;
      width: 55%;
      background-position-y: -250px;
    }
    .businessProtectionIcon {
      padding-left: 40px;
      padding-right: 40px;
    }
    .loginContentTopGE {
      left: 293px;
    }
    .getStarted {
      margin-left: 0;
      margin-right: 0;
    }
    .offeringRowGE {
      flex-direction: inherit;
    }
    .offeringColTextGE {
      text-align: left !important;
    }
    .laborProtectionContainer {
      background: none;
    }
    .laborProtectionOuterContainer:before {
      content: "";
      background: url('../assets/images/Vendor/GE/GE-homeowners.jpg') top left no-repeat;
      border-radius: 0% 6% 45% 0% / 0% 100% 84% 10%;  
      min-height: 600px;
      width: 49%;
      background-size: 125%;
      position: absolute;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {

    .vendorLoginICP {
      min-height: 538px;
      background-image: none;
      background-color: var(--PrimaryBrandColor);
    }

    .loginRowSection {
      width: auto;
      border-bottom-right-radius: 0%;
    }

    .loginFormContainer {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 75px;
      height: 538px;
      margin-left: 50%;
    }

    .loginBtnICP {
      width: 126px;
    }
  }

  @media screen and (min-width: 820px) and (max-width: 1023px) {

    .vendorLoginICP {
      min-height: 538px;
      background-size: cover;
      background-position-x: 150px;
    }

    .loginFormContainer {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 75px;
      height: 538px;
      margin-left: 15%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1199px) {

    .vendorLoginICP {
      min-height: 538px;
      background-size: cover;
      background-position-x: 225px;
    }

    .loginFormContainer {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 75px;
      height: 538px;
      margin-left: 20%;
    }
  }

  /* GE, ICP related styles ends */
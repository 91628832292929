.inputWrap {
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #b9c6d4;
    border-radius: 2px;
}

    .inputWrap.disabledDateField {
        background: rgba(199, 219, 244, .24);
        border-color: #b9c6d4;
        color: #5d738d;
    }

@media (max-width: 767px) {
    .inputWrap {
        max-width: 100%;
    }
}

.dateInput {
    padding: 6px 6px;
    border: none;
    width: 100%;
}

.calendarIcon {
    height: 32px;
    fill: var(--IconColor);
    margin: 0 16px;
    cursor: pointer;
}

    .calendarIcon.iconDisabled {
        cursor: default;
        fill: var(--InputDisabled__Text);
    }

.calendar {
    margin-top: 26px;
    transform: translateX(-1px);
    border-radius: 0;
    top: 42px;
}

@import url('../css/VARIABLES.css');

/***AutoComplete DropDown*/
autoDrop__control, .css-1pahdxg-control {
    background-color: #fff !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: .5px;
    border-radius: 0px !important;
    min-height: 0 !important;
}

.autoDrop__option, .css-yt9ioa-option {
    border-top: 1px solid var(--Color__Neutral3);
    cursor: pointer !important;
}

.autoDrop__menu-list, .css-4ljt47-MenuList {
    padding-top: 0px !important;
}

.autoDrop__indicator-separator, .css-1okebmr-indicatorSeparator {
    width: 0px;
    margin: 0px !important;
    background-color: transparent !important;
}

autoDrop__control, .autoDrop__control--is-focused, .autoDrop__control--menu-is-open, .css-1pahdxg-control, .css-2b097c-container {
    border-radius: 0px !important;
    transition: border-color,border-width ease-in-out .2s;
}

.autoDrop__control, .css-yk16xz-control {
    border-radius: var(--Btn__BorderRadius) !important;
    border-color: var(--Input__Border) !important;
    min-height: 0 !important;
}
.form-error .autoDrop__control, .form-error .css-yk16xz-control, .form-error .autoDrop__placeholder {
    border-color: var(--ErrorColor)!important;
    background-color: white;
    color: var(--ErrorColor);
}

.autoDrop__single-value, .css-1uccc91-singleValue {
    color: hsl(0,0%,20%);
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    top: 35%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
    padding-right: 45px;
}

.autoDrop__value-container, .autoDrop__value-container--has-value {
    color: var(--Input__Placeholder);
    font-size: 14px;
    text-align: center;
    padding-right: 20px;
    cursor: pointer !important;
}

.autoDrop__dropdown-indicator {
    fill: none !important;
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/arrow-cheveron-down.svg');
    background-size: 16px 18px;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 5px;
    color: white !important;
}

    .autoDrop__dropdown-indicator:hover {
        color: white !important;
    }

.autoDrop__indicator.autoDrop__clear-indicator{
    right: 20px;
    position: absolute;
}
.autoDrop__indicator.autoDrop__clear-indicator svg {
    color: #848484;
}

.autoDrop__option{
    text-transform: lowercase!important;
}
.autoDrop__option:first-letter {
    text-transform: uppercase !important;
}
.autoDrop__option:hover {
    background: var(--DropOption__HoverBg) !important;
    color: white;
    text-decoration: none;
}

.asset .autoDrop__option {
    text-transform: inherit !important;
}

.autoDrop__option--is-focused, .autoDrop__option--is-selected {
    background: var(--DropOption__HoverBg) !important;
    color: white !important;
    text-decoration: none;
}

.css-1fhf3k1-control.autoDrop__control--is-disabled {
    background-color: var(--InputDisabled__Bg) !important;
    border: var(--InputDisabled__Border) !important;
    color: var(--InputDisabled__Text);
}

    .css-1fhf3k1-control.autoDrop__control--is-disabled .css-1wa3eu0-placeholder {
        color: var(--InputDisabled__Text);
    }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .autoDrop__indicator, .css-tlfecz-indicatorContainer {
    top: 8px;
  }
}
.addTransform .autoDrop__option{
    text-transform: none !important;
}
@import url('../../../../css/VARIABLES.css');

.highlighted {
    background-color: var(--AdvantageAccentColor);
    color: var(--Advantage__TextColor);
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 16px;
}

.smallHeading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--PrimaryBrandColor);
}

.advStatus {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 767px) {
    .advStatus {
        align-items: center;
    }
    .enrolled {
        text-align: center;
    }
}
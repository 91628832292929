@import url('../../../css/VARIABLES.css');

@media screen and (min-width:767.99px) and (max-width: 991px) {
    /* .radioCylinder {
        margin-bottom: 21px !important;
    } */
}

@media screen and (min-width: 991.99px) {
    /* .radioCylinder:nth-of-type(3),
    .radioCylinder:nth-of-type(6),
    .radioCylinder:nth-of-type(9) {
        margin-right: 0rem;
    } */
}

@media screen and (max-width: 767px) {
    /* .radioCylinder,
    .controlsLabel {
        margin-right: 0rem !important;
    }
    .radioCylinder {
        margin-bottom: 21px;
    } */
    .paymentAmt {
        margin-bottom: 0.8rem;
    }
}

@media screen and (min-width:521px) and (max-width: 767px) {
    /* .controlsLabel {
        margin-right: 1rem !important;
    } */
}

.paymentAmt {
    display: flex;
    width: fit-content;
    height: 26px;
    justify-content: space-between;
    border-radius: 22px;
    align-items: center;
    background-color: #E1EFFF;
    padding: 0px 10px;
    font-weight: 600;
    color: var(--PrimaryBrandColor);
}

.paymentAmt p {
    margin-bottom: 0rem;
    font-size: 18px !important;
}

.paymentAmt p:first-child {
    margin-right: 6px;
}

.radioCylinder input[type="radio"]:checked + div {
    background-color: var(--RadioWithBg__CheckedBg);
    border: 1px solid var(--RadioWithBg__CheckedBorder);
    color: var(--Heading__Color);
    box-shadow: 0 2px 4px 3px rgba(0, 4, 8, 0.02);
}

.controlsLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);
    cursor: pointer;
    border-radius: 4px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8;
    width: 246px;
    height: 130px;
    padding: 12px;
    position: relative;
    align-items: center;
    background-color: var(--Color__Neutral1);
    margin-bottom: 0rem;
    box-shadow: 0 2px 4px 3px rgba(0, 4, 8, 0.02);
}

.radioCylinder input[type=radio]:checked+.controlsLabel:after {
    background: var(--RadioButton__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 11px;
    position: absolute;
    top: 15px;
    transform: translate(50%, 50%);
    width: 9px;
}

.controlsLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 50%;
    border: 1px solid var(--Color__Neutral3);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 31px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 5px 5px 5px -8px;
    max-width: 18px;
    transition: background .3s ease;
    width: 24px;
    position: absolute;
    top: 10px;
    left: 19px;
}

.radioCylinder input[type=radio]:checked+.controlsLabel:before {
    border-color: var(--RadioButton__CheckedBorder);
}

.radioCylinder input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radioCylinder {
    position: relative;
    margin-bottom: 16px;
    margin-right: 16px;
}

.radioCylinder p {
    margin-bottom: 0px;
}

.controlsLabel img:first-child {
    height: 29px;
}


/* .controlsLabel img:last-child {
  height: 20px;
} */

.cardTypeVisa {
    position: absolute;
    top: 9%;
    left: 14%;
    height: 29px;
}

.cardTypeAmex {
    position: absolute;
    top: 8%;
    left: 14%;
    height: 29px;
}

.cardTypeMaster {
    position: absolute;
    top: 9%;
    left: 14%;
    height: 29px;
}

.cardTypeDiscover {
    position: absolute;
    top: 9%;
    left: 14%;
    height: 29px;
}

.deleteCardIcon {
    position: absolute;
    top: 10%;
    right: 5%;
    height: 20px;
    width: 14px;
    fill: var(--IconColor);
    background-repeat: no-repeat;
    border: none;
}

.cardExpiry {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 80px;
}

.cardExpiry p :first-child {
    color: var(--PrimaryTextColor) !important;
}

.expiredWarning {
    color: #F32321 !important;
}

.expDate {
    color: var(--PrimaryTextColor);
}

.updateCard {
    position: absolute;
    top: 9%;
    line-height: 24px;
    right: 15%;
    color: var(--LinkColor);
    background-color: transparent;
    border: none;
    padding: 0rem;
    font-weight: normal;
    font-size: 12px !important;
}

.accountType {
    position: absolute;
    top: 9%;
    left: 35%;
    font-size: 12px !important;
    margin-bottom: 0rem;
    line-height: 28px;
}

.addNewCardBtn {
    display: flex;
}
.addIconStyling{
    height:11px;
    width:11px;
}

.addNewCardBtn:hover svg g g {
    stroke: #fff;
}

.addNewCardBtn:focus {
    display: flex;
    margin-right: 4px;
}

.addNewCardBtn:focus svg g g {
    stroke: #001871;
}

.addNewCardBtn svg {
    display: flex;
    margin-right: 4px;
}


/* delete modal */

.deleteCardHeader h4 {
    margin-bottom: 0px;
}

.deleteCardHeader {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid var(--Color__Neutral2);
    justify-content: space-between;
}

.btnAlign {
    display: flex;
    justify-content: flex-end;
}

.advantageContainer {
  border: none !important;
  box-shadow: none !important;
}
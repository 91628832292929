@import url('../../../css/VARIABLES.css');

.tableInput {
    max-width: 100%;
    border: none !important;
    outline: none;
    background: transparent;
    text-align: right;
    color: var(--PrimaryTextColor);
}

.sectionHeaderRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--Table__RowBg);
    padding: 4px 8px;
    height: 36px;
}

    .sectionHeaderRow p {
        margin-bottom: 0;
        color: var(--PrimaryTextColor);
        font-weight: 600;
    }

.displayBlock {
    display: block;
}

.displayNone {
    display: none;
}

.topTable {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 1px solid var(--Color__Neutral2);
}

.inputHeight {
    height: 35px;
}

.alignment {
    justify-content: center;
}

.capitalize {
    text-transform: capitalize;
}

.toolTipAlignCenter {
    align-items: center;
    display: flex;
}

.toolTipAlignFlex {
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.iconAlign {
   padding-top: 7px;
   height:42px;
   width: 100%;
   cursor: pointer;
}

.trashIcon {
    height: 16px;
    width: 16px;
    fill: var(--IconColor);
    cursor: pointer;
}

.editReplacementDetailsIcon {
    height: 16px;
    width: 18px;
    fill: var(--IconColor);
    cursor: pointer;
    visibility: visible!important;
    opacity: 1!important;
    pointer-events: all!important;
}

.toolTipSection span[class^="InfoToolTip_onsiteInfoTooltiptext"] {
    width: 14rem;
}

    .toolTipSection span[class^="InfoToolTip_onsiteInfoTooltiptext"]::after {
        border-color: #D7DFE7;
    }

.dropdown-select {
    background-color: #f9f9f9;
    z-index: 1;
    font-size: 14px;
    width: 100%;
}

@import url('../../../css/VARIABLES.css');

.commSwitch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 18px;
}

.smsSwitch {
    margin-left: 32px;
}

.emailSwitch {
    margin-left: 66px;
}

.smsSwitch::before {
    top: -4%;
    content: "SMS";
    position: absolute;
    right: 115%;
}

.emailSwitch::before {
    top: -4%;
    content: "Email";
    position: absolute;
    right: 115%;
}

.commSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid var(--Color__Neutral3);
    -webkit-transition: .4s;
    transition: .4s;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 3px;
        bottom: 13%;
        background-color: white;
        border: 1px solid var(--Color__Neutral3);
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .slider {
    background: linear-gradient(0deg, #0057B8 0%, #017AD0 100%);
}

    input:checked + .slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
    }

.slider.round {
    border-radius: 16px;
}

    .slider.round:before {
        border-radius: 50%;
    }

.switchWrapper {
    display: flex;
    padding-top: 8px;
}

.deleteContact {
    position: absolute;
    right: 0px;
    top: 27px;
}

.bgSelected {
    border-radius: 4px;
    background-color: var(--ScriptBox__Bg);
    box-shadow: 0 2px 4px 3px rgba(0, 4, 8, 0.02);
    margin: 0rem;
    padding: 16px 9px 0px 9px;
    position: relative;
    border: 1px solid var(--ScriptBox__Border);
}

.unselectedContact {
    background-color: transparent;
    margin: 0rem;
    padding: 16px 9px 0px 9px;
    position: relative;
}

.contactSelection {
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

    .contactSelection input[type=radio] {
        left: -9999px;
        opacity: 0;
        position: absolute;
    }

.contactSelLabel {
    margin-left: 1rem;
}

.contactSelection .contactSelLabel {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryBrandColor);
    font-weight: 600;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    line-height: 1.5;
    padding-left: 7px;
    position: relative;
    text-align: left;
}

.contactSelection input[type=radio]:checked + .contactSelLabel:before {
    border-color: var(--SecondaryBrandColor);
}

.contactSelection .contactSelLabel:before {
    border-radius: 50%;
}

.contactSelLabel:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    border-radius: 0;
    border: 1px solid var(--Color__Neutral3);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 18px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 8px 0 -22px;
    max-width: 18px;
    transition: background .3s ease;
    width: 18px;
}

.contactSelection input[type=radio]:checked + .contactSelLabel:after {
    background: var(--RadioButton__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: -13%;
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 9px;
}

.trashIcon{
    height: 17px;
    width: 14px;
    cursor: pointer;
    transition: all .1s ease;
    margin: 0 4px 3px;
    fill: var(--IconColor);
}

.disabledDeleteIcon {
    pointer-events: none;
}

.disabledDeleteIcon svg g {
    fill: var(--Color__Neutral3) !important;  
}

.deleteIconMob svg g,
.deleteIconDesk svg g {
    fill: var(--SecondaryBrandColor);  
}

.deleteIconMob {
    margin-right: 12px;
    cursor: pointer;
}

.dropWrapper div {
    min-width: 100%
}

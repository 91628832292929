.Snackbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    width: fit-content;
    border-radius: 4px;
    position: fixed;
    top: 121px;
    right: 34px;
    z-index: 100;
    font-size: 16px;
    padding: 16px;
}

.viewNotification {
    text-decoration: underline;
    background: transparent;
    font-size: 16px;
    border: none;
    font-weight: normal;
}

.closeBanner {
    display: flex;
    align-items: center;
    cursor: pointer;
}
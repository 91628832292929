.table {
    font-size: 14px;
    color: #343741;
    margin-bottom: 1rem;
    border-left: 1px solid #E0E5EB;
    border-right: 1px solid #E0E5EB;
}

    .table thead th {
        border-bottom: none;
        font-weight: 600;
    }

    .table tr {
        border-top: 1px solid #E0E5EB;
    }

        .table tr:last-child {
            border-bottom: 1px solid #E0E5EB;
        }

        .tableStriped tr:nth-child(even),
        .table tr.active {
            background-color: #F2F6FB;
        }
.tableHover:hover {
    background-color: #E0E5EB !important;
}

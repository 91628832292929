@import url('../../../css/VARIABLES.css');


.uploadProgress {
    max-width: 420px;
    border: 1px solid var(--Color__Neutral3);
    padding: 12px 12px 12px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    margin: 0px  0px 20px;
}

.file {
    width: 30px;
    height: 34px;
    fill: var(--IconColor);
    margin: 0 8px 0 0;
}

.fileName {
    margin: auto 0;
    padding-right: 24px;
}

.progressWrapper {
    margin: 0px;
    width: 100%;
}

.progress {
    width: 100%;
    height: .45rem;
    background-color: #D8D8D8;
}

.progressBar, .progress-bar {
    background-color: var(--IconColor);
    width: 25%;
    border-radius: .25rem;
}

.progressBarWrapper {
    margin-left: .50rem;
    width: 75%;
}

.uploadProgressWrapper {
    display: flex;
    align-items: center;
}

.closeButton {
    background-color: transparent;
    border: none;
    background: url('../../../assets/images/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    margin: 0 0 0 8px;
    align-self: center;
}

.uploadPercentage {
    font-size: 12px;
    color: var(--ProgressColor);
}

.uploadPercentage span {
    color: var(--PrimaryBrandColor);
}

@media (max-width: 767px) {
    .uploadProgress {
        max-width: 100%;
    }
}

